import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'

import React, { useEffect, useState } from "react";

import { Layout, Row, Col, Divider, Modal, Spin, Form, Input, message, Button, Tooltip, Slider, Table } from 'antd';
import MyHeader from "components/header"
import * as utils from "utils/utils"
import ActiveBanner from "components/active-banner"
import MyFooter from "components/footer"
import temp_src_exhibits from 'assets/temp/exhibits.png'
import videoSpecial from 'assets/Powpow.mp4'
import "./detail.scss"
import { PlayCircleOutlined, PauseOutlined, TwitterOutlined , FullscreenOutlined} from "@ant-design/icons"
import MusicLogo from "assets/Music.png"
import DAPP from 'utils/dapp';
import bus from "utils/bus";
import Dragger from 'react-dragger-r'
import { defaultPrefixCls } from 'antd/lib/config-provider';
const { Header, Footer, Content } = Layout;

function DetailFixed(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)
    const location = useLocation();

    const [isModalVisibleFixedPrice, setModalVisibleFixedPrice] = useState(false)

    const [eth, setEth] = useState(0)
    const [imgFullScreen, setImgFullScreen] = useState(false)

    const [curItem, setCurItem] = useState(null)

    const [slival, setSlival] = useState(10)
    const [bg_screen, set_bg_screen] = useState(100)
    const [loading, setLoading] = useState(false)
    const [audioDetailPlay,setDetailAutioPlay] = useState(false)
    const [timerClock, setTimerClock] = useState(0)
    const [isModalVisible, setModalVisible] = useState(false)
    const [detail, setDetail] = useState(null)
    let params = location.pathname.split("/") //  utils.parseParams(location.search.split("?")[1])
    const [form] = Form.useForm();

    const handlePrice = async (values) => {
        setModalVisibleFixedPrice(false)
        setLoading(true);
        let res = await bus.sellTokenSerial(curItem.owner.address_key, detail.good.token, values.price, curItem.version)

        setLoading(false);

        if (res) {
            initInfo()
        }

    }

    const handleShare=(type)=>{
        let url = window.location.href
        if(type === 'twitter'){
            window.open(`https://twitter.com/share?url=${url}&text=${detail.good.title}`)
        }
    }


    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    const handleFullscreenWheel = (e)=>{
        set_bg_screen(bg_screen + e.nativeEvent.deltaY/50 )
    }

    const handleFullscreen = ()=>{
        let el =  document.getElementById("my-fullscreen")

        el.style.display="block"

        let rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
        if (rfs) { // typeof rfs != "undefined" && rfs
            rfs.call(el)
        }
    }

    const [audioPlay, setAutioPlay] = useState(false)

    const playAudio = (src, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src = src
        audioRef.play()
        setAutioPlay(true)
    }

    const pauseAudio = (e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlay(false)
    }

    const handleSlider = async (v) => {
        await setSlival(v)
    }

    const setPrice = (item) => {
        console.log("######")
        setModalVisibleFixedPrice(true)
        setCurItem(item)

    }


    const handleWheel = (e) => {
        // console.log(1,e.nativeEvent.deltaY )
        // document.getElementById("zoomDiv").style.width = document.getElementById("zoomDiv").style.width * e.nativeEvent.deltaY 

    }


    const bigScale = (img) => {
        setImgFullScreen(true)
        document.body.classList.add("hideScroll")
        document.documentElement.requestFullscreen()
    }

    //监听fullscreenchange事件
    const watchFullScreen = () => {

        ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'].forEach((item, index) => {
            window.addEventListener(item, () => {
                let isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
                console.log(9999, isFullScreen)
                if (isFullScreen) {

                } else {
                    document.body.classList.remove("hideScroll")
                    setImgFullScreen(false)
                }
            });
        })
    }



    const placeABid = () => {
        if (!bus.hasMetamask()) {
            return
        }

        if (!bus.ifConnect()) {
            return false;
        }

        // onFinish()
        // setModalVisible(true)
    }

    const onFinish = async () => {
        console.log("form", form.getFieldsValue())
        setModalVisible(false)
        let data = form.getFieldsValue()
        setLoading(true);
        try {
            await bus.freeTransferTokenSerial(data.address, detail.good.token, curItem.version)
        } catch {
            setLoading(false);
        }
        setLoading(false);

        initInfo()

    }

    const playDetailAudio = (src,e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioDetailRef")
        audioRef.src=src
        audioRef.play()
        setDetailAutioPlay(true)
    }

    const pauseDetailAudio=(e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioDetailRef")
        audioRef.pause()
        setDetailAutioPlay(false)
    }

    const signAirdrop = async (item) => {
        const airdrop_signature = await DAPP.createAirdrop(detail.good.token, item.version);
        console.log([item, airdrop_signature]);
    }

    const buyIt = async (item) => {

        // good/versions/18
        setLoading(true);

        if(item.tx){
            try {
                await bus.transferTokenSerial(userInfo.address_key, item.owner.address_key, detail.good.token, item.version, bus.fromWei(item.price))
            } catch {
                setLoading(false);
            }
        }else{
            try {
                await bus.Mint(item.owner.address_key, detail.good.token, 1 , bus.fromWei(item.price),  item.dividend*1)
            } catch {
                setLoading(false);
            }
        }
        
        setLoading(false);

        initInfo()

    }

    const transfer = async (item) => {
        setModalVisible(true)
        setCurItem(item)
    }

    const CancelSell = async (item) => {
        setLoading(true);
        try {
            console.log(detail.good.token, item.version)
            await bus.cancelTokenSerialSale(detail.good.token, item.version)
        } catch {
            setLoading(false);
        }
        setLoading(false);
        initInfo()
    }

    const columns = [
        {
            title: 'Version',
            dataIndex: 'version',
            key: `version`,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: `price`,
            render: (text, item, index) => {
                return <>
                    <div className="flex flex-wrap">
                        <span style={{ whiteSpace: 'nowrap' }}>{bus.fromWei(item.price)} {bus.getNetUnit(detail.good.contract_address)} </span>
                        <span>(${(eth * bus.fromWei(item.price)).toFixed(2)})</span>
                    </div>
                </>
            }
        },

        {
            title: 'Owner',
            render: (text, item, index) => {
                if (!item.owner) return null;
                return <>
                    <div className="flex items-center">
                        <img className="w-6 h-6 rounded-full mr-2" src={item.owner.avatar || temp_src_exhibits} />
                        <span>{item.owner.uri_name || item.owner.nicename}</span>
                    </div>
                </>
            }
        },

        {
            title: 'Action',
            render: (text, item, index) => {
                if (!item.owner) {
                    console.log('没有 owner??');
                    return null;
                };
                return <>
                    <div className="flex flex-wrap" >
                        {
                            userInfo && item.owner.address_key != userInfo.address_key && item.status == 1 && detail.allow && <div className="mb-1 mr-2 w-24 h-6 bg-gray-900 text-white rounded-full text-center cursor-pointer mr-2" onClick={() => buyIt(item)} >Buy</div>
                        }

                        {
                            userInfo && item.owner.address_key == userInfo.address_key && detail.allow && item.tx && <div className="mb-1 w-24 h-6 bg-gray-900 text-white rounded-full text-center cursor-pointer mr-2" onClick={() => transfer(item)}>Transfer</div>
                        }

                        {
                            userInfo && item.owner.address_key == userInfo.address_key && detail.allow && item.tx && <div className="mb-1 w-24 h-6 bg-gray-900 text-white rounded-full text-center cursor-pointer mr-2" onClick={() => signAirdrop(item)}>Airdrop</div>
                        }

                        {
                            userInfo && item.owner.address_key == userInfo.address_key && detail.allow && item.tx && item.status == 1 && <div className="mb-1 w-24 h-6 bg-gray-900 text-white rounded-full text-center cursor-pointer mr-2" onClick={() => CancelSell(item)}>Cancel Sell</div>
                        }

                        {
                            userInfo && item.owner.address_key == userInfo.address_key && detail.allow  && item.tx&& item.status == 2 && <div className="mb-1 w-24 h-6 bg-gray-900 text-white rounded-full text-center cursor-pointer mr-2" onClick={() => setPrice(item)}>Sell</div>
                        }
                        {
                            userInfo && item.owner.address_key != userInfo.address_key && item.status == 2 && item.tx && <div className="w-24 h-6 bg-gray-300 text-white rounded-full text-center cursor-pointer  mr-2" >Reserved</div>
                        }

                    </div>
                </>
            }
        },
    ];

    const initInfo = async () => {
        let res = await bus.getDetailFixedItem(params[2])

        if (res.code == 0) {

            let eth_temp = await bus.eth(res.data.good.contract_address)
            setEth(eth_temp)

            let ERC1155ContractAddress = await bus.getERC1155ContractAddress()
            let temp = res.data
            let allow = ERC1155ContractAddress && (res.data.good.contract_address.toLocaleLowerCase() == ERC1155ContractAddress.toLocaleLowerCase())

            // console.log(111,res.data.good.contract_address)
            // console.log(222,ERC1155ContractAddress)
            temp && (temp.allow = (allow || false))

            // console.log(333,temp)

            setDetail(temp);
        }
    }

    useEffect(async () => {
        initInfo()
        watchFullScreen()


        // try {
        //     let res = await bus.analysisIPFS(detail.good.ipfs_link)
        //     detail.good.ipfsLink = res.properties.image || res.properties.audio || res.properties.video
        // } catch (e) {

        // }
    }, [])
    return (
        <>
            <audio id="audioRef" />
            <audio id="audioDetailRef" />
            <ActiveBanner></ActiveBanner>
            {
                detail && <div className="my-layout">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content relative">
                            <div className="bg-gray-300 w-full flex justify-center items-center border-red-300 " style={{ height: '600px', overflow: 'hidden' }}>
                                {
                                    detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="relative  flex items-center justify-center" style={{ height: '460px', minWidth: '460px' }}>
                                        {/* <div onClick={() => bigScale(detail.good.image)} className="flex items-center my-good_image custom-scale" style={{ width: 20 * slival, height: 20 * slival, background: `url(${detail.good && detail.good.image}) no-repeat`, backgroundPosition: ` center center` }}></div> */}
                                        <Dragger>
                                            <div onWheel={(e) => handleWheel(e)} id="zoomDiv" className="flex items-center my-good_image custom-scale" style={{ width: 50 * slival, height: 50 * slival, background: `url(${detail.good && detail.good.image}) no-repeat`, backgroundPosition: ` center center` }}></div>
                                        </Dragger>
                                    </div>
                                }
                                {
                                    detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="h-80  w-80 flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                        {
                                            audioPlay && <PauseOutlined onClick={(e) => pauseAudio(e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                        {
                                            !audioPlay && <PlayCircleOutlined onClick={(e) => playAudio(detail.good.image, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                    </div>
                                }
                                {
                                    detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="h-80  w-80  justify-center  relative flex items-center detail-video">
                                        <video controls muted loop autoplay="autoplay" src={detail.good.image} />
                                        {/* <span className="text-white text-6xl inline-block  absolute text-opacity-50">
                                        ZACK POWELL
                                    </span> */}
                                    </div>
                                }
                                {
                                    detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="absolute top-1 right-2 my-fullscreen-icon">
                                        <FullscreenOutlined onClick={()=>handleFullscreen()} className="text-3xl cursor-pointer" />
                                    </div>
                                }
                            </div>
                            {
                                detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="" style={{ width: '300px', marginLeft: '50%', transform: 'translate(-150px, 0) ' }}>
                                    <Slider min={10} defaultValue={slival} onChange={(v) => setSlival(v)} />
                                </div>
                            }
                            <br /><br />
                            <Row>
                                <Col span={2} className="my-col-left"></Col>
                                <Col span={22} className="my-detail-content">
                                    <div className="py-12  relative flex justify-between my-mini-detail">
                                        <div className="w-1/2 my-detail-info">
                                            {/* <h1 className="font-bold text-3xl">
                                                <a className="text-black" href={detail.good.external_link}>{detail.good.title}</a>
                                            </h1> */}

                                            <h1 className="font-bold text-3xl flex items-center  ">
                                                <a className="text-black " href={detail.good.external_link}>{detail.good.title}</a>
                                                
                                            </h1>

                                            <div>
                                                <h3 className="font-bold">
                                                    Description
                                                </h3>
                                                <div className="break-words text-xl leading-6 w-full view-description ">
                                                    {detail.good.desc}
                                                </div>
                                                {/* {
                                                    detail.good.video && <div className="mt-4" >
                                                        <video src={detail.good.video} controls muted loop autoplay="autoplay"></video>
                                                    </div>
                                                } */}
                                                {
                                                    detail.good.video  && bus.getFileType(detail.good.video) === "video"  && <div className="mt-4" >
                                                        <video src={detail.good.video}  controls muted loop autoplay="autoplay"></video>
                                                    </div>
                                                }

                                                {
                                                     detail.good.video && bus.getFileType(detail.good.video) == 'audio' && <div className="h-36  w-36 flex items-center justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                                        {
                                                            audioDetailPlay && <PauseOutlined onClick={(e)=>pauseDetailAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                                                        }
                                                        {
                                                            !audioDetailPlay && <PlayCircleOutlined onClick={(e)=>playDetailAudio(detail.good.video,e)} style={{fontSize:'68px',opacity:0.4}}/>
                                                        }
                                                    </div>
                                                }

                                                {
                                                    detail.good.video  && bus.getFileType(detail.good.video) === "img"  && <div className="mt-4 w-1/2" >
                                                        <img src={detail.good.video} />
                                                    </div>
                                                }

                                            </div>
                                            <div className="py-6">
                                                <h3 className="font-bold">
                                                    Edition of
                                                </h3>
                                                <div className="break-words leading-3">
                                                    {detail.versions[0].total - detail.versions.filter(v => v.owner?.address_key != v.creator.address_key).length} / {detail.versions[0].total}
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap View-btns">
                                                {
                                                    detail.good.etherscan.split("tx")[detail.good.etherscan.split("tx").length-1].length > 5 && <a href={`${detail.good.etherscan}`} target="_blank">
                                                    <div className="w-56 mt-4 bg-black mr-1 text-white rounded-full px-4 py-2 flex items-center">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.57226 10.471C4.57224 10.3482 4.59649 10.2266 4.6436 10.1132C4.69072 9.99974 4.75978 9.89674 4.84682 9.81009C4.93386 9.72344 5.03716 9.65484 5.15079 9.60824C5.26442 9.56163 5.38614 9.53793 5.50896 9.5385L7.06191 9.54359C7.30952 9.54359 7.547 9.64196 7.72208 9.81705C7.89717 9.99214 7.99554 10.2296 7.99554 10.4772V16.3495C8.17048 16.2977 8.39484 16.2424 8.64062 16.1847C8.81128 16.1446 8.96337 16.0479 9.07223 15.9105C9.1811 15.7731 9.24036 15.6029 9.2404 15.4276V8.14356C9.24039 8.02094 9.26453 7.89951 9.31145 7.78622C9.35837 7.67292 9.42714 7.56998 9.51384 7.48326C9.60055 7.39654 9.70348 7.32775 9.81677 7.28082C9.93005 7.23388 10.0515 7.20972 10.1741 7.20971H11.7301C11.9777 7.20975 12.2152 7.30813 12.3903 7.48321C12.5653 7.65829 12.6637 7.89574 12.6638 8.14334V14.9041C12.6638 14.9041 13.0532 14.7464 13.4328 14.5862C13.5737 14.5266 13.694 14.4268 13.7786 14.2993C13.8632 14.1717 13.9084 14.0221 13.9085 13.869V5.80908C13.9085 5.68648 13.9327 5.56507 13.9796 5.4518C14.0265 5.33853 14.0953 5.23561 14.1819 5.14891C14.2686 5.06221 14.3716 4.99344 14.4848 4.94652C14.5981 4.8996 14.7195 4.87545 14.8421 4.87545H16.3981C16.6457 4.87545 16.8832 4.97381 17.0583 5.1489C17.2334 5.32399 17.3318 5.56146 17.3318 5.80908V12.446C18.6808 11.4684 20.0481 10.2924 21.1331 8.87844C21.2905 8.67319 21.3946 8.43217 21.4363 8.17688C21.4779 7.92159 21.4557 7.65997 21.3716 7.41535C20.6375 5.27589 19.2604 3.41541 17.4288 2.08828C15.5971 0.761149 13.4003 0.0321631 11.1386 0.000996897C5.03979 -0.0809305 -0.000546332 4.89754 5.27718e-05 10.9971C-0.0059355 12.9272 0.497815 14.8247 1.46037 16.4976C1.59311 16.7264 1.7884 16.9126 2.02329 17.0343C2.25818 17.156 2.52292 17.2081 2.78641 17.1846C3.08079 17.1586 3.44729 17.122 3.88299 17.0709C4.07263 17.0493 4.24771 16.9587 4.37489 16.8164C4.50206 16.6741 4.57244 16.49 4.57263 16.2991V10.471" fill="white" />
                                                            <path d="M4.53718 19.8887C6.17738 21.0819 8.11567 21.7981 10.1376 21.9581C12.1596 22.1181 14.1864 21.7156 15.9938 20.7952C17.8013 19.8748 19.3188 18.4723 20.3787 16.7429C21.4385 15.0136 21.9993 13.0247 21.999 10.9964C21.999 10.7432 21.9872 10.4929 21.9703 10.2439C17.9531 16.2349 10.5359 19.0357 4.53711 19.8889" fill="white" />
                                                        </svg>
                                                        <span className="ml-2">View on Etherscan</span>
                                                    </div>
                                                </a>
                                                }
                                                {
                                                    detail.good.etherscan.split("tx")[detail.good.etherscan.split("tx").length-1].length<=1 && <div className="w-56 mr-1 mt-4 bg-gray-600 text-white rounded-full px-4 py-2 flex items-center">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.57226 10.471C4.57224 10.3482 4.59649 10.2266 4.6436 10.1132C4.69072 9.99974 4.75978 9.89674 4.84682 9.81009C4.93386 9.72344 5.03716 9.65484 5.15079 9.60824C5.26442 9.56163 5.38614 9.53793 5.50896 9.5385L7.06191 9.54359C7.30952 9.54359 7.547 9.64196 7.72208 9.81705C7.89717 9.99214 7.99554 10.2296 7.99554 10.4772V16.3495C8.17048 16.2977 8.39484 16.2424 8.64062 16.1847C8.81128 16.1446 8.96337 16.0479 9.07223 15.9105C9.1811 15.7731 9.24036 15.6029 9.2404 15.4276V8.14356C9.24039 8.02094 9.26453 7.89951 9.31145 7.78622C9.35837 7.67292 9.42714 7.56998 9.51384 7.48326C9.60055 7.39654 9.70348 7.32775 9.81677 7.28082C9.93005 7.23388 10.0515 7.20972 10.1741 7.20971H11.7301C11.9777 7.20975 12.2152 7.30813 12.3903 7.48321C12.5653 7.65829 12.6637 7.89574 12.6638 8.14334V14.9041C12.6638 14.9041 13.0532 14.7464 13.4328 14.5862C13.5737 14.5266 13.694 14.4268 13.7786 14.2993C13.8632 14.1717 13.9084 14.0221 13.9085 13.869V5.80908C13.9085 5.68648 13.9327 5.56507 13.9796 5.4518C14.0265 5.33853 14.0953 5.23561 14.1819 5.14891C14.2686 5.06221 14.3716 4.99344 14.4848 4.94652C14.5981 4.8996 14.7195 4.87545 14.8421 4.87545H16.3981C16.6457 4.87545 16.8832 4.97381 17.0583 5.1489C17.2334 5.32399 17.3318 5.56146 17.3318 5.80908V12.446C18.6808 11.4684 20.0481 10.2924 21.1331 8.87844C21.2905 8.67319 21.3946 8.43217 21.4363 8.17688C21.4779 7.92159 21.4557 7.65997 21.3716 7.41535C20.6375 5.27589 19.2604 3.41541 17.4288 2.08828C15.5971 0.761149 13.4003 0.0321631 11.1386 0.000996897C5.03979 -0.0809305 -0.000546332 4.89754 5.27718e-05 10.9971C-0.0059355 12.9272 0.497815 14.8247 1.46037 16.4976C1.59311 16.7264 1.7884 16.9126 2.02329 17.0343C2.25818 17.156 2.52292 17.2081 2.78641 17.1846C3.08079 17.1586 3.44729 17.122 3.88299 17.0709C4.07263 17.0493 4.24771 16.9587 4.37489 16.8164C4.50206 16.6741 4.57244 16.49 4.57263 16.2991V10.471" fill="white" />
                                                        <path d="M4.53718 19.8887C6.17738 21.0819 8.11567 21.7981 10.1376 21.9581C12.1596 22.1181 14.1864 21.7156 15.9938 20.7952C17.8013 19.8748 19.3188 18.4723 20.3787 16.7429C21.4385 15.0136 21.9993 13.0247 21.999 10.9964C21.999 10.7432 21.9872 10.4929 21.9703 10.2439C17.9531 16.2349 10.5359 19.0357 4.53711 19.8889" fill="white" />
                                                    </svg>
                                                    <span className="ml-2">View on Etherscan</span>
                                                </div>
                                                }
                                                

                                                <a href={`${detail.good.ipfs_image}`} target="_blank">
                                                    {/* <Tooltip placement="topLeft" title="coming soon" color="#1890ff"> */}
                                                    <div className="w-56 mt-4 bg-black text-white mr-1 rounded-full px-4 py-2 flex items-center" >
                                                        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0276 0.0791016L0 5.86862V17.4477L10.0276 23.2372L20.0553 17.4477V5.86862L10.0276 0.0791016ZM8.99234 1.47392C9.6054 1.92627 10.4418 1.92627 11.0549 1.47392L18.3252 5.67164C18.3107 5.80074 18.3107 5.93097 18.3252 6.06008L11.0549 10.2578C10.4418 9.80544 9.60553 9.80544 8.99243 10.2578L1.72206 6.05994C1.7366 5.93084 1.7366 5.80052 1.72206 5.67142L8.99243 1.47393L8.99234 1.47392ZM19.024 7.21957C19.1286 7.2967 19.2415 7.36186 19.3605 7.41383V15.8091C18.6623 16.1138 18.2441 16.8381 18.3293 17.5952L11.059 21.7929C10.9544 21.7157 10.8415 21.6507 10.7225 21.5987L10.7143 13.25C11.4126 12.9453 11.8308 12.2211 11.7455 11.464L19.0241 7.21943L19.024 7.21957ZM1.03085 7.2663L8.30122 11.4639C8.216 12.221 8.63423 12.9452 9.33252 13.25V21.6452C9.21345 21.6972 9.10057 21.7624 8.99603 21.8395L1.72612 17.6421C1.81133 16.885 1.39319 16.1608 0.694905 15.856V7.46079C0.813974 7.40882 0.926766 7.34366 1.03131 7.26653L1.03085 7.2663Z" fill="url(#paint0_linear)" />
                                                            <path d="M20.055 5.86865L10.0273 11.6582V23.2372L20.055 17.4477V5.86865ZM19.0237 7.21946C19.1282 7.2966 19.2411 7.36176 19.3602 7.41373V15.809C18.6619 16.1137 18.2437 16.838 18.3289 17.5951L11.0586 21.7928C10.954 21.7156 10.8412 21.6506 10.7221 21.5986L10.714 13.2499C11.4122 12.9452 11.8304 12.221 11.7452 11.4639L19.0237 7.21932L19.0237 7.21946Z" fill="black" fill-opacity="0.25098" />
                                                            <path d="M0 5.86865V17.4477L10.0276 23.2372V11.6582L0 5.86865ZM1.03121 7.26619L8.30158 11.4638C8.21637 12.2209 8.63459 12.9451 9.33288 13.2499V21.6451C9.21381 21.6971 9.10093 21.7623 8.99639 21.8394L1.72603 17.642C1.81124 16.8849 1.3931 16.1607 0.694814 15.8559V7.46067C0.813884 7.40871 0.926676 7.34355 1.03122 7.26641L1.03121 7.26619Z" fill="black" fill-opacity="0.039216" />
                                                            <path d="M10.0286 0.0791016L0.000976562 5.86862L10.0286 11.6581L20.0562 5.86862L10.0286 0.0791016ZM8.99332 1.47392C9.60637 1.92627 10.4427 1.92627 11.0558 1.47392L18.3262 5.67164C18.3116 5.80074 18.3117 5.93097 18.3262 6.06008L11.0558 10.2578C10.4428 9.80544 9.60651 9.80544 8.99341 10.2578L1.72304 6.05994C1.73757 5.93084 1.73757 5.80052 1.72304 5.67142L8.99341 1.47393L8.99332 1.47392Z" fill="black" fill-opacity="0.13018" />
                                                            <defs>
                                                                <linearGradient id="paint0_linear" x1="-9.04612e-05" y1="11.6581" x2="20.0554" y2="11.6581" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="white" />
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                        <span className="ml-2">View on IPFS</span>
                                                    </div>
                                                    {/* </Tooltip> */}
                                                </a>


                                                <a href={`${detail.good.ipfs_link}`} target="_blank">
                                                    {/* <Tooltip placement="topLeft" title="coming soon" color="#1890ff"> */}
                                                    <div className="w-56 mt-4 bg-black text-white mr-1 rounded-full px-4 py-2 flex items-center">
                                                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span className="ml-2">View on IPFS Metadata</span>
                                                    </div>
                                                    {/* </Tooltip> */}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="w-1/2 ml-36 my-mini-place-info">
                                            <Table dataSource={detail.versions} columns={columns} pagination={false}
                                                scroll={{ x: '100%' }}
                                            />
                                        </div>
                                        <div className="rounded-full bg-black text-white inline-block py-1 px-4 w-36 absolute -top-4 my-detail-address">{bus.formatUid(detail.creator.address_key)}</div>
                                        <div className="px-4  flex items-center mt-2 absolute -top-5 left-36 my-detail-twitter">
                                            <TwitterOutlined className="text-2xl cursor-pointer" onClick={()=>handleShare('twitter')} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>
                </div>
            }
            <div className="fullscreen-content bg-gray-300 " id="my-fullscreen">
                {
                    detail && detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="relative  flex items-center justify-center" >
                        <Dragger>
                            <div onWheel={(e) => handleFullscreenWheel(e)} id="zoomDiv" className="flex items-center my-good_image custom-scale " style={{ width: bg_screen+ "vw", height: bg_screen+ "vh", background: `url(${detail.good && detail.good.image}) no-repeat`, backgroundPosition: ` center center` }}></div>
                        </Dragger>
                    </div>
                } 
                {
                    detail && detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="h-80  w-80 flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                    {
                        audioPlay && <PauseOutlined onClick={(e)=>pauseAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                    }
                    {
                       !audioPlay && <PlayCircleOutlined onClick={(e)=>playAudio(detail.good.image,e)} style={{fontSize:'68px',opacity:0.4}}/>
                    }
                    </div>
                }
                {
                    detail && detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="h-80  w-80  justify-center  relative flex items-center detail-video">
                        <video  controls muted loop autoplay="autoplay" src={detail.good.image} /> 
                    </div>
                }
            </div>
            <Modal title={'The current item was created in ' + bus.getNameOfNetwork(detail && detail.good.network)} visible={isModalVisible} footer={null} onCancel={() => setModalVisible(false)}>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Address"
                        name="address"
                        form={form}
                        rules={[{ required: true, message: 'Please input address!' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <Input  title="Enter a valid value. The two closest valid values are 0 and 1"/>
                            {/* <span className="font-bold flex-shrink-0 h-6">&emsp; {bus.getNetUnit()} </span> */}
                        </div>
                    </Form.Item>

                    <Form.Item >
                        <div className="flex ">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                            <div>
                                <Button type="" onClick={() => setModalVisible(false)}>
                                    Quit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>

                </Form>
            </Modal>

            <Modal title="Set Price" footer={null} visible={isModalVisibleFixedPrice} onCancel={() => setModalVisibleFixedPrice(false)}>
                <Form
                    name="Set Price"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={handlePrice}
                >
                    <Form.Item
                        label="Price"
                        name="price"
                        rules={[{ required: true, message: 'Please input price!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <div className="flex justify-end">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            {
                detail && imgFullScreen && <div className="screenContent">
                    <div className="bigScreen" style={{ background: `url(${detail.good.image})` }}>
                    </div>
                </div>
            }


            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>
        </>
    )
}

export default DetailFixed
