import React, { useState, useEffect } from "react";
import "./admin.scss"
import { Layout, Row, Col, Menu, Divider } from 'antd';
import AdminHeader from "components/admin-header"
import MyBiding from "components/my-biding"
import MyItems from "components/my-items"
import CreateItem from "components/create-item"
import Users from "components/users"
import Rate from "components/rate"
import Manually from "components/manually"
import UserInfo from "components/user-info"
import MyFooter from "components/footer"
import SellItems from "components/sell-items"
import MyCollection from "components/my-collection"
import BeCreatorPage from "components/becreator"
import CreatorsList  from "components/creators"

import temp_src_exhibits from 'assets/temp/exhibits.png'
import { Link, useLocation } from 'react-router-dom'

import * as utils from "utils/utils"
import bus from "utils/bus"
const { Header, Footer, Content } = Layout;
function AdminPage() {
    const [selectedKeys, setSelectedKeys] = useState(['My biding'])
    const [record, setRecord] = useState(null)
    const [sellInfo, setSellInfo] = useState(null)
    const [userInfo, setUserInfo] = useState(null);

    const location = useLocation();

    const setAdminKey = async (key) => {
        console.log(111, key)

        if (key != "Create item") {
            await setRecord(null)
        }
        console.log(88888, key)
        setSelectedKeys(key)
    }

    const setInfo = async (info) => {
        setSellInfo(info)
    }

    const EditItem = (record) => {
        setRecord(record)
        setSelectedKeys("Create item")
    }

    const handleUserInfo = (userInfo) => {
        console.log("**********", userInfo)
        setUserInfo(userInfo)
    }

    useEffect(async () => {

        let userInfo = bus.getLocalStore("userInfo")
        if (userInfo) {
            setUserInfo(userInfo)
        }


        let params = utils.parseParams(location.search.split("?")[1])

        if (params && params.key) {
            setSelectedKeys(decodeURI(params.key))
        }
    }, [])

    return (
        <>
            {
                userInfo && <div className="my-layout  h-full" >
                    <Layout className="h-full">
                        <Header className="shadow-lg">
                            <AdminHeader setAdminKey={setAdminKey} userInfo={userInfo} ></AdminHeader>
                        </Header>
                        <Content className="my-content h-full my-admin-layout" >
                            <Row className="h-full">
                                <Col span={4} className="my-col-left shadow-2xl">
                                    <div className="h-full bg-gray-900 my-admin-left">
                                        <div className="h-48 bg-white flex justify-end items-end px-4 py-2">
                                            <div className="flex ">
                                                <img alt="" src={userInfo.avatar || temp_src_exhibits} className="w-8 rounded-full flex-shrink-0 h-8 mr-3" />
                                                <div>
                                                    <span>Hi,</span>
                                                    <h1 className="font-bold">{userInfo.nicename || userInfo.nanoUid}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="h-6/12 my-admin-height">
                                            <Menu
                                                defaultSelectedKeys={['1']}
                                                mode="inline"
                                                theme="dark"
                                            >
                                                <Menu.Item key="My biding" onClick={() => setAdminKey('My biding')}>
                                                    My Biding
                                                </Menu.Item>
                                                <Menu.Item key="My items" onClick={() => setAdminKey('My items')}>
                                                    My Items
                                                </Menu.Item>
                                                <Menu.Item key="My collection" onClick={() => setAdminKey('My collection')}>
                                                    My Collection
                                                </Menu.Item>
                                                {
                                                    userInfo.role == 'admin' && <Menu.Item key="Users" onClick={() => setAdminKey('Users')}>
                                                        Users
                                                    </Menu.Item>
                                                }
                                                {
                                                    userInfo.role == 'admin' &&  <Menu.Item key="Manually" onClick={() => setAdminKey('Manually')}>
                                                        Manually Open An Auction
                                                    </Menu.Item>
                                                }
                                                {
                                                    userInfo.role == 'admin' &&  <Menu.Item key="Rate" address_key={userInfo.address_key} onClick={() => setAdminKey('Rate')}>
                                                        Rate
                                                    </Menu.Item>
                                                }
                                                {
                                                    userInfo.role == 'admin' &&  <Menu.Item key="Creators List" address_key={userInfo.address_key} onClick={() => setAdminKey('Creators')}>
                                                        Creators List
                                                    </Menu.Item>
                                                }
                                                

                                                

                                            </Menu>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={20} className="my-col-content">
                                    <div className="p-10 h-full" >
                                        {
                                            selectedKeys == "My biding" && <MyBiding></MyBiding>
                                        }

                                        {
                                            selectedKeys == "My items" && <MyItems setAdminKey={setAdminKey} EditItem={EditItem} setInfo={setInfo}></MyItems>
                                        }

                                        {
                                            selectedKeys == "Users" && <Users></Users>
                                        }

                                        {
                                            selectedKeys == "Create item" && <CreateItem setAdminKey={setAdminKey} record={record}></CreateItem>
                                        }

                                        {
                                            selectedKeys == "User info" && <UserInfo handleUserInfo={handleUserInfo}></UserInfo>
                                        }
                                        {
                                            selectedKeys == "Manually" && <Manually handleUserInfo={handleUserInfo}></Manually>
                                        }
                                        {
                                            selectedKeys == "Rate" && <Rate setAdminKey={setAdminKey} ></Rate>
                                        }
                                        {
                                            selectedKeys == "Sell item" && <SellItems setAdminKey={setAdminKey} sellInfo={sellInfo}></SellItems>
                                        }
                                        {
                                            selectedKeys == "My collection" && <MyCollection setAdminKey={setAdminKey} sellInfo={sellInfo}></MyCollection>
                                        }
                                        {
                                            selectedKeys == "Be creator" && <BeCreatorPage setAdminKey={setAdminKey} sellInfo={sellInfo}></BeCreatorPage>
                                        }
                                        {
                                            selectedKeys == "Creators" && <CreatorsList setAdminKey={setAdminKey} sellInfo={sellInfo}></CreatorsList>
                                        }


                                    </div>

                                </Col>
                                <Col span={24}>
                                    <MyFooter></MyFooter>
                                </Col>

                            </Row>

                        </Content>
                        <Footer>
                        </Footer>
                    </Layout>

                </div>
            }
        </>

    )
}

export default AdminPage