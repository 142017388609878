/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-10-06 00:49:26
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-10-08 20:51:52
 */
import { Layout, Row, Col, Divider, Pagination, Input, Spin, Form, message } from 'antd';
import MyHeader from "components/header"
import ActiveBanner from "components/active-banner"
import React, { useEffect, useState } from "react";
import MyFooter from "components/footer"

const { Header, Footer, Content } = Layout
function EmailComfirm(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)

    useEffect(async () => {
    }, [])

    return (
        <>
            <div id='activity1'>
                <ActiveBanner></ActiveBanner>
                <div className="my-layout my-help">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content my-creator-content">
                            <Row>
                                <Col span={2} className="my-col-left"></Col>
                                <Col span={20} className="my-col-content">
                                    <div className="content">
                                        <div className="text-green-500 text-2xl font-bold my-80 flex flex-col">
                                            <span>Congratulations. Dear Creator: </span>
                                            <br/>
                                            <span>We are pleased to inform you that your email has been confirmed, we will send you important alerts and notices to this email. </span>
                                        </div>
                                    </div>

                                </Col>
                                <Col span={2} className="my-col-right"></Col>
                            </Row>
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>
                </div>
            </div>
        </>
    )
}

export default EmailComfirm