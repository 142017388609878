/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-06-13 20:52:33
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-11-17 18:19:16
 */

import { Router, Route, Redirect } from 'react-router';
import Home from "./views/Home";
import Artwork from "./views/artwork"
import Creators from "./views/creators"
import CreatorDetail from "./views/creator-detail"
import Detail from "./views/detail"
import DetailFixed from "./views/detail-fixed"
import { createHashHistory, createBrowserHistory } from 'history';
import AdminPage from "./views/admin"
import Privacy from "./views/privacy"
import Team from "./views/team"
import ActivePage from "./views/active"
import SearchPage from "./views/search"
import HelpPage from "./views/help"
import ContactUs from "./views/contact"
import FollowPage from "./views/follow"
import CollectionsPage from "./views/collections"
import CollectionPage from "./views/collection"
import EmailComfirm from "./views/email-comfirm"



import React, { useEffect, useState } from 'react';
const hashHistory =   createBrowserHistory();  // createBrowserHistory 
function MyRouter(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [itemInfo, setItemInfo] = useState(null)
    useEffect(() => {

    }, [userInfo])
    return (
        <>
            {
                <Router history={hashHistory}>
                    {/* <Redirect path="/" render={() => { return <Home userInfo={userInfo}></Home> }} /> */}

                    <Route exact path="/" render={() => { return <Home setUserInfo={setUserInfo} setItemInfo={setItemInfo}  userInfo={userInfo}></Home> }} />
                    <Route path="/home" render={() => { return <Home setUserInfo={setUserInfo}  setItemInfo={setItemInfo} userInfo={userInfo}></Home> }} />
                    {/* <Route path="/artwork/:name" render={() => { return <Artwork  setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></Artwork> }} /> */}
                    <Route path="/artwork" render={() => { return <Artwork setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></Artwork> }} />
                    <Route path="/creators" render={() => { return <Creators setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></Creators> }} />
                    <Route path="/detail" render={() => { return <Detail setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></Detail> }} />
                    <Route path="/fixed" render={() => { return <DetailFixed setUserInfo={setUserInfo}  itemInfo={itemInfo} setItemInfo={setItemInfo}  userInfo={userInfo}></DetailFixed> }} />
                    <Route path="/creator" render={() => { return <CreatorDetail setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></CreatorDetail> }} />
                    <Route path="/admin" render={() => { return <AdminPage  setUserInfo={setUserInfo} setItemInfo={setItemInfo}  userInfo={userInfo}></AdminPage> }} />
                    <Route path="/privacy" render={() => { return <Privacy setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></Privacy> }} />
                    <Route path="/team" render={() => { return <Team setUserInfo={setUserInfo}  userInfo={userInfo}></Team> }} />
                    <Route path="/active" render={() => { return <ActivePage setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></ActivePage> }} />
                    <Route path="/search" render={() => { return <SearchPage setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></SearchPage> }} />
                    <Route path="/help" render={() => { return <HelpPage  setUserInfo={setUserInfo} setItemInfo={setItemInfo}  userInfo={userInfo}></HelpPage> }} />
                    <Route path="/contact" render={() => { return <ContactUs setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></ContactUs> }} />
                    <Route path="/follow" render={() => { return <FollowPage setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></FollowPage> }} />
                    <Route path="/collections" render={() => { return <CollectionsPage setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></CollectionsPage> }} />
                    <Route path="/confirm" render={() => { return <EmailComfirm setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></EmailComfirm> }} />
                    <Route path="/collection" render={() => { return <CollectionPage setUserInfo={setUserInfo}  setItemInfo={setItemInfo}  userInfo={userInfo}></CollectionPage> }} />
                </Router>
            }
        </>

    )
}

export default MyRouter