
import { getAction, postAction, getAction_other } from "./http"
import DAPP from "./dapp"
import { Modal, message } from "antd"
import { getEthPriceNow } from './onTimePrice' //'get-eth-price';
import CreatorsList from "components/creators"
let bus = {
    async getUserInfo(headers) {
        let res = await getAction(
            `user/center`,
            {},
            headers
        );
        if (res.status == 200) {
            return res.data.data
        }
    },

    getNameOfNetwork(netId) {
        switch (netId) {
            case 1:
                return "Ethereum Mainnet"
            case 4:
                return "Rankeby"
            case 56:
                return "Bsc Mainnet"
            case 97:
                return "Test Network"
        }
    },

    analysisIPFS: async (url) => {
        let res = await getAction_other(
            url,
            {}
        );
        return res
    },

    initActiveList: async function () {
        let headers = await this.getAuthorization();
        let res = await getAction(
            `auction/collection/genesis_sports`,
            {},
            headers
        );
        return res.data
    },

    // 创建集合
    createCollection :async function (data){
        let headers = await this.getAuthorization();
        let res = await postAction(
            `collection/create`,
            {
                ...data,
                address:"" // 这个值不知道为啥要加，反正要求我加上，留空也行
            },
            headers
        );
        return res.data
    },

    Mint:async function(accountAddress, tokenId, total, price, fate){
        console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbb")
        const contract = await DAPP.erc1155Factory(); 
        console.log(33333,contract.Mint)
        let res =  await contract.Mint(accountAddress, tokenId, total, price, fate)
        console.log("aaaaaaaaaaaaaa",res)
    },

    getFileType: function (filePath) {
        // console.log("**************",filePath)
        if (!filePath) return ""
        var startIndex = filePath.lastIndexOf(".");
        let temp = ''
        if (startIndex != -1) {
            temp = filePath.substring(startIndex + 1, filePath.length).toLowerCase();

            if (temp == "mp4" || temp == "rmvb" || temp == "avi" || temp == "mov") {
                return "video"
            }

            if (temp == "bmp" || temp == "png" || temp == "gif" || temp == "jpg" || temp == "jpeg") {
                return "img"
            }

            if (temp == "wav" || temp == "mp3" || temp == "wma" || temp == "mid") {
                return "audio"
            }
        }


    },

    async loadAllCollections(currentPage = 1, pageSize = 8) {
        let headers = await this.getAuthorization();
        let res = await getAction(
            `collection/index?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`,
            {},
            headers
        );
        return res.data
    },

    async initCollections(currentPage = 1, pageSize = 8) {
        let headers = await this.getAuthorization();
        let res = await getAction(
            `collection/user/created?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`,
            {},
            headers
        );
        return res.data
    },

    // creator 详情中的cellection集合
    async collectionOfCreator(address){
        // 0x130B57a6cADcd6ce3f8065E95163c640922d0578
        let headers = await this.getAuthorization();
        let res = await getAction(
            `collection/user/index/${address}`,
            {},
            headers
        );
        return res.data
    },


    async initItems(currentPage = 1, pageSize = 8) {
        let headers = await this.getAuthorization();
        let res = await getAction(
            `auction/user/biddings?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`,
            {},
            headers
        );
        return res.data
    },

    async getFixedPriceItems(currentPage = 1, pageSize = 16) {
        // let headers = await this.getAuthorization();
        let res = await getAction(
            `good/browse?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`,
            {},
            // headers
        );
        return res.data
    },

    async getFixedPriceDetail(id) {
        let headers = await this.getAuthorization();
        let res = await getAction(
            `good/versions/${id}`,
            {},
            headers
        );
        return res.data
    },

    // async searchAuctions(keyword="",currentPage = 1, pageSize = 8){
    //     let headers = await this.getAuthorization();
    //     let res = await getAction(
    //         `auction/search?keyword=${keyword}&offset=${(currentPage - 1) * pageSize
    //         }&limit=${pageSize}`,
    //         {},
    //         headers
    //     );
    //     return res.data
    // },
    async searchAuctions(keyword = "") {
        let headers = await this.getAuthorization();
        let res = await getAction(
            `auction/search?keyword=${keyword}`,
            {},
            headers
        );
        return res.data
    },

    // collection  详情数据
    async collectionDetail(cid,currentPage=1,pageSize=10){
        let res = await getAction(`collection/detail/${cid}?offset=${(currentPage - 1) * pageSize}&limit=${pageSize} `)
        if(res.data.code == 0){
            return res.data.data
        }
    },

    getNetUnit(contract_address) {
        return DAPP.getUnit(contract_address)
    },

    async getERC1155ContractAddress(){
        const {version} = DAPP.currentContext()
        return DAPP.getERC1155ContractAddressOfNetwork(version)
    },
    hasMetamask() {
        // console.log(9999, DAPP.metamaskInstalled())
        if (!DAPP.metamaskInstalled()) {
            const modal = Modal.info();
            modal.update({
                content: <div>
                    <p>Please install the MetaMask extension in order to use Uniquehash</p>
                    <a href="https://metamask.io/" style={{ padding: '10px 20px', background: 'black', color: 'white', borderRadius: '5px', display: 'inline-block', marginTop: '20px' }}>Go to MetaMask's website</a>
                </div>
            });
            return false;
        }
        return true
    },

    ifAllow() {
        return DAPP.isAllowedNetwork()
    },

    async manuallyAuction(form) {
        //  /admin/auction/start
        let headers = await this.getAuthorization();
        let res = await postAction("/admin/auction/start", form, headers)
        return res.data
    },

    async eth(contract_address) {
        let res = await getEthPriceNow(DAPP.getUnit(contract_address))
        console.log("getEthPriceNow res == > ", res, Object.values(res))
        return Object.values(res)[0].ETH.USD
    },



    async updateUserInfo(form) {

        let headers = await this.getAuthorization();

        if (form.avatar && form.avatar.slice(0, 4) == "http") {
            delete form.avatar;
        }

        if (
            form.teaser_poster &&
            form.teaser_poster.slice(0, 4) == "http"
        ) {
            delete form.teaser_poster;
        }

        let res = await postAction(
            "user/profile/update",
            form,
            headers
        )

        return res.data
    },

    async initCreators(keyWord, currentPage, pageSize) {
        let res = await getAction(`user/index?s=${keyWord}&offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`, {}, null);
        return res.data
    },

    async updateRateInfo(formData) {
        const contract = await DAPP.contract();
        await contract.setFeeReceiver(formData.address)
        await contract.setFeeRate(formData.rate)
    },

    async updateFeeRate(rate) {
        const contract = await DAPP.contract();
        await contract.setFeeRate(rate)
    },

    async updateFeeReceiver(address) {
        const contract = await DAPP.contract();
        await contract.setFeeReceiver(address)
    },

    async handleRefund(record) {
        let headers = await this.getAuthorization();

        if (! await DAPP.isAllowedNetwork(record.good.network)) {
            message.warning('The network your wallet is connected to is not supported right now.');
            return null
        }


        const contract = await DAPP.contract();
        const tokenId = record.auction.token_id;
        let rs = await contract.withdraw(tokenId);

        if (rs.transactionHash) {
            let res = await postAction(
                `auction/refund/${record.auction.aid}`,
                {
                    tx: rs.transactionHash,
                },
                headers
            );
            return res.data
        }
    },

    async getUsers(keyword = "", currentPage = 1, pageSize = 8) {
        let headers = await this.getAuthorization();
        let res = await getAction(
            `admin/users?keyword=${keyword}&offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`,
            {},
            headers
        );

        return res.data
    },

    async heandleTransfer(record) {
        let headers = await this.getAuthorization();

        if (! await DAPP.isAllowedNetwork(record.good.network)) {
            message.warning('The network your wallet is connected to is not supported right now');
            return null
        }


        const contract = await DAPP.contract();
        const tokenId = record.auction.token_id;
        let rs = await contract.endAuction(tokenId);

        if (rs.transactionHash) {
            let res = await postAction(
                `auction/end/${record.auction.aid}`,
                {
                    tx: rs.transactionHash,
                },
                headers
            );
            return res.data
        }
    },

    // 是否已经关注过了
    async hasFollowed(address) {

        let friends = await this.getFriends(address)

        let one = friends.filter(v => {
            return v.address_key == address
        })[0]

        console.log("66666", !!one)


        if (!!one) {
            return true
        } else {
            return false
        }
    },

    async initUserInfo(address) {
        let res = await getAction(`/user/detail/${address}`);
        return res.data
    },

    async getLiveAuctions(currentPage = 1, pageSize = 16) {
        let res = await getAction(
            `auction/browse/live?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`,
            {}
        );
        return res.data
    },

    async getFeaturedArtworks(currentPage = 1, pageSize = 8) {
        let res = await getAction(
            `auction/browse/not-reserve?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`,
            {}
        );
        return res.data

        // let res = await getAction(
        //     `auction/collection/genesis_sports?offset=${(currentPage - 1) * pageSize
        //     }&limit=${pageSize}`,
        //     {}
        // );
        // return res.data
    },

    async getCreators() {
        let res = await getAction(`user/index`, {}, null);
        return res.data
    },

    async initBoot() {
        let res = await getAction(`boot`);
        // console.log(res);
        return res.data
        // if (res.data.code == 0) {
        //   this.detail = res.data.feature_auction;
        //   if (this.detail) {
        //     this.timerClock = Math.floor(
        //       res.data.feature_auction.second_duration
        //     );
        //     this.clockTimer();
        //   }
        // }
    },


    redirect(path = "index", params = {}) {
        // console.log(111111111, path, params)
        this.activePath = path
        this.$router.push({
            path: path,
            params: params,
            query: params
        })
    },

    // 结束的拍卖列表
    async solidGoods(currentPage = 1, pageSize = 8) {
        let res = await getAction(
            `auction/browse/sold?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`
        );

        return res.data
    },

    async auctionDetail(aid) {
        let that = this;
        // let headers = await this.getAuthorization();
        let res = await getAction(`auction/details/${aid}`, {});
        // 拍卖详情接口
        let record = res.data.data;
        // console.log(":::::::::::::::::::::::::", record);
        if (record) {
            record.created = this.formatDate(record.created);
            record.histories = record.histories.map((v) => {
                let created = that.formatDate(v.created);
                return {
                    ...v,
                    created,
                };
            });
        }

        return record
    },

    // 进行中的拍卖
    async onLiveGoods(currentPage = 1, pageSize = 8) {
        let res = await getAction(
            `auction/browse/live?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`
        );

        return res.data

        // if (res.data.code == 0) {
        //   this.dataSource.datas = res.data.data.datas;
        //   this.dataSource.total = res.data.data.total;
        // }
    },

    // 还未有竞拍的拍卖列表
    async notReserveGoods(currentPage = 1, pageSize = 8) {
        let res = await getAction(
            `auction/browse/not-reserve?offset=${(currentPage - 1) * pageSize
            }&limit=${pageSize}`
        );
        return res.data
    },

    redirectTo(external_link, e) {
        e.stopPropagation();
        e.preventDefault();

        this.$router.push({
            path: external_link
        })
    },

    ifConnect() {
        if (!DAPP.connected()) {
            message.warning(' You have not logged in, please connect your wallet first');
            return false;
        }
        return true
    },

    // 关注
    async follow(address, e) {

        if (!this.ifConnect()) {
            return
        }

        e.stopPropagation();
        e.preventDefault()
        let headers = await this.getAuthorization();
        let res = await postAction(`user/follow`, { address: address }, headers)


        if (res.data.code == 0) {
            // message.success(res.data.message);
            // await this.getFriends(address)
        } else {
            message.warning(res.data.message);
        }
    },

    // 取消关注
    async unfollow(address, e) {

        if (!this.ifConnect()) {
            return
        }

        e.stopPropagation();
        e.preventDefault()
        let headers = await this.getAuthorization();
        let res = await postAction(`user/unfollow`, { address: address }, headers)

        // return res.data


        if (res.data.code == 0) {
            // message.success(res.data.message);
            // await this.getFriends(address)
        } else {
            message.warning(res.data.message);
        }
    },

    // 获取我的关注列表
    async getFriends(address) {

        // if (!this.ifConnect()) {
        //     return 
        // }

        // let headers = await this.getAuthorization();

        let res = await getAction(`user/friends/${address}`, {})

        return res.data
        // if (res.data.code == 0) {
        //     return res.data.data.datas
        // }
        // return []
    },

    // 获取关注我的粉丝列表
    async getFollowees(address) {
        // if (!this.ifConnect()) {
        //     return 
        // }

        // let headers = await this.getAuthorization();

        let res = await getAction(`user/followees/${address}`, {})

        return res.data
    },

    formatUid(address) {
        if (address) {
            return `${address.slice(0, 6)}...${address.slice(address.length - 4)}`;
        } else {
            return ''
        }
    },

    async payThis(detail, price) {

        if (!this.ifConnect()) {
            return
        }

        if (! await DAPP.isAllowedNetwork(detail.good.network)) {
            message.warning('The network your wallet is connected to is not supported right now.');
            return null
        }

        let contract = await DAPP.contract();
        console.log(11)

        let rs = await contract.bid(detail.token_id, price);
        console.log(12)
        // 暂时忽略竞拍失败的情况
        if (rs.blockHash) {
            let headers = await this.getAuthorization();
            let res = await postAction(
                `auction/bid/${detail.aid}?price=${price}`,
                {
                    price: price,
                    aid: detail.aid,
                    tx: rs.transactionHash,
                },
                headers
            );

            return res.data
        }

    },

    async viewFeeReceiverAndFate() {
        let contract = await DAPP.contract();
        console.log("111")
        let res = await contract.viewFeeReceiverAndFate()
        console.log("222", res)
        return res
    },

    loginOut() {
        // 置空 userInfo
        // console.log("********************")
        this.removeLocalStore('userInfo');
        this.removeLocalStore('userToken');
        this.removeLocalStore('expired');
        this.userInfo = null
    },

    async getAuthorization() {
        if (this.getLocalStore("userToken")) {
            let expired = this.getLocalStore("expired")
            let expired_time = Date.parse(new Date(expired))
            let current = Date.parse(new Date())

            if (current >= expired_time) {
                // message.warning("Token expired")
                this.removeLocalStore('userInfo');
                this.removeLocalStore('userToken');
                this.removeLocalStore('expired');
                await this.connectWallet()
                // return null
            } else {
            }
            return { Authorization: `Bearer ${this.getLocalStore("userToken")}` }

        } else {

            let address_pubkey = await DAPP.getSelectedAddress()
            // let address_pubkey = selectedAddress

            console.log("address_pubkey  == ", address_pubkey)
            let res = await postAction(`login/wallet`, {
                address: address_pubkey,
            });

            if (res.data.code == 0) {
                return { Authorization: `Bearer ${res.data.data.token}`, }
            }
        }
    },

    clockTimer() {
        let timer = setInterval(() => {
            if (this.timerClock <= 0) {
                clearInterval(timer);
                return
            }
            this.timerClock--;

        }, 1000);
    },

    setUserInfoFn(userInfo) {
        let that = this;
        this.setLocalStore("userInfo", {
            ...userInfo,
            nanoUid: that.formatUid(that.uid),
        })
    },

    // 秒数数转时分秒
    sToHMS(seconds) {
        let hNum = Math.floor(seconds / 3600)
        hNum = hNum >= 10 ? hNum : `0${hNum}`
        let mNum = Math.floor((seconds % 3600) / 60)
        mNum = mNum >= 10 ? mNum : `0${mNum}`
        let sNum = seconds - hNum * 3600 - mNum * 60
        sNum = sNum >= 10 ? sNum : `0${sNum}`
        return [hNum, mNum, sNum]
    },
    async getUserInfoFn(token) {

        let that = this
        let res = await getAction(
            `user/center`,
            {},
            {
                Authorization: `Bearer ${token}`,
            }
        );
        // this.$nextTick(() => {
        //     that.userInfo = {
        //         ...res.data.data,
        //         nanoUid: that.formatUid(that.uid),
        //     };
        // })

        if (res.data.code == 0) {
            this.setLocalStore("userInfo", {
                ...res.data.data,
                nanoUid: that.formatUid(that.uid),
            })
        }
    },
    async login(uid) {

        console.log(3,uid)
        this.uid = uid;

        let res = await postAction(`login/wallet`, {
            address: uid,
        });

        if (res.data.code == 0) {
            if (res.data && res.data.data) {
                this.setLocalStore("userToken", res.data.data.token)
                this.setLocalStore("expired", res.data.data.expired_in)
                await this.getUserInfoFn(res.data.data.token);
            }
        }
    },
    async connectWallet() {
        console.log('检查是否链接', DAPP.connected())
        if (!DAPP.connected()) {
            return false;
        }

        const { account } = DAPP.currentContext();

        if (DAPP.connected()) {
            // let userInfo = this.getLocalStore("userInfo");
            // if (userInfo) {
            // } else {
            await this.login(account);
            // }
        }
        return true;
    },
    formatDate(date) {
        var reg = /^16\d{1,}$/
        if (reg.test(date)) {
            // 如果是时间戳
            let d = new Date(date)
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            let day = d.getDate()
            let hour = d.getHours()
            let minutes = d.getMinutes()
            let seconds = d.getSeconds()

            return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
        } else {
            return date
        }

        // return date.replace("T"," ").replace("Z","")

    },
    async disconnectWallet() {
        // console.log('断开钱包');
        this.loginOut()
        await DAPP.disconnect();
        // console.log("disconnect ", res)

    },
    async allowUser(address_key, status) {
        let headers = await this.getAuthorization();
        let res = await postAction(
            `admin/approve`,
            {
                address: address_key,
                status,
            },
            headers
        );
        return res.data
    },
    async handleAuctionEnd(record) {
        console.log(88888, record)
        let headers = await this.getAuthorization();
        console.log("a")
        const contract = await DAPP.contract();
        console.log("b", contract)
        let rs = await contract.endAuction(record.auction.token_id);
        console.log("c", rs)
        if (rs.blockHash) {
            let res = await postAction(
                `auction/end/${record.auction.aid}`,
                { tx: rs.transactionHash },
                headers
            );
            return res.data
        }
    },

    async startAuction(start_price, tokenId) {
        let headers = await this.getAuthorization();

        if (! await DAPP.isAllowedNetwork()) {
            message.warning('The network your wallet is connected to is not supported right now.');
            return null
        }

        const contract = await DAPP.contract();
        // const tokenId = this.auctionForm.gid;
        // const start_price = this.auctionForm.start_price;
        // 开始拍卖
        console.log(88)
        // 目前代码卡在这里 。。。
        let rs = await contract.startAuction(tokenId);

        console.log(33, rs)
        if (rs.blockHash) {
            console.log([
                "交易完成 通知数据库",
                `auction/start/${tokenId}`,
            ]);
            try {
                let res = await postAction(
                    `auction/start/${tokenId}`,
                    {
                        second_duration: 24 * 3600,
                        token: tokenId,
                        start_price: start_price,
                        tx: rs.transactionHash,
                        contract_address: contract.address,
                    },
                    headers
                );
                return res.data
            } catch (e) {
                console.log(["post auction start error ", e]);
            }
        } else {
            // 处理未知错误
            alert("Auction occurred unknown error, please contact administrator");
        }
    },
    walletAddress() {
        const { account } = DAPP.currentContext();
        return account;
    },
    loginWallet() {
        let that = this;

        const res = DAPP.isAllowedNetwork()

        if (!res) {
            return null
        }

        console.log(555555)
        
        return DAPP.enableWeb3().then(async (accounts) => {
            console.log("先链接", accounts, accounts[0]);
            await that.login(accounts[0]);
        });
    },
    // 全屏幕加载中
    loadingFullScreen() {
        const loading = this.$loading({
            lock: true,
            text: 'Contract code is executing, do not close the browser',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        return loading
    },
    setLocalStore(key, value) {
        try {
            window.localStorage.setItem(key, JSON.stringify(value))
        } catch {
            window.localStorage.setItem(key, value)
        }
    },
    getLocalStore(key) {
        if (window.localStorage.getItem(key)) {
            try {
                return JSON.parse(window.localStorage.getItem(key))
            } catch {
                return window.localStorage.getItem(key)
            }
        }
    },
    removeLocalStore(key) {
        try {
            window.localStorage.removeItem(key)
        } catch {

        }
    },


    async transferTokenSerial(address_key, fromAddress, tokenId, serialNo, price) {
        const contract = await DAPP.erc1155Contract();
        let headers = await this.getAuthorization();
        let rs = await contract.transferTokenSerial(fromAddress, tokenId, serialNo, price)
        if (rs.transactionHash) {
            // let res = await postAction(
            //     `good/mint/${tokenId}/version/${ serialNo}/transfer`,
            //     {
            //         tx: rs.transactionHash,
            //         to: address_key
            //     },
            //     headers
            // );
            // return res.data
            return true
        }
        return false
    },

    fromWei(price) {
        // const contract = await DAPP.erc1155Contract();
        if (price * 1 <= 1) return 0
        return DAPP.fromWei(price)
    },

    async cancelTokenSerialSale(token, serialNo) {
        const contract = await DAPP.erc1155Contract();
        let rs = await contract.cancelTokenSerialSale(token, serialNo)
        if (rs.transactionHash) {
            return true
        }
        return false
    },

    // 二次出价
    async sellTokenSerial(fromAddress, token, price, serialNo) {
        const contract = await DAPP.erc1155Contract();

        let rs = await contract.sellTokenSerial(fromAddress, token, price, serialNo)
        if (rs.transactionHash) {
            // let res = await postAction(
            //     `good/token-version/sell`,
            //     {
            //         tx: rs.transactionHash,
            //         price: price * 1,
            //         token: token,
            //         version: serialNo
            //     },
            //     headers
            // );
            // return res.data
            return true
        }
        return false
    },

    async freeTransferTokenSerial(toAddress, tokenId, serialNo) {
        const contract = await DAPP.erc1155Contract();
        let headers = await this.getAuthorization();
        let rs = await contract.freeTransferTokenSerial(toAddress, tokenId, serialNo)
        if (rs.transactionHash) {
            // let res = await postAction(
            //     `good/mint/${tokenId}/version/${serialNo}/transfer`,
            //     {
            //         tx: rs.transactionHash,
            //         to: toAddress
            //     },
            //     headers
            // );
            // return res.data
            return true
        }
        return false
    },

    async getDetailFixedItem(id) {
        // let headers = await this.getAuthorization();
        let res = await getAction(
            `good/versions/${id}`,
            {},
            // headers
        );

        return res.data
    },

    async startFixPrice(data,typeChoose) {
        const contract = await DAPP.erc1155Contract();
        let headers = await this.getAuthorization();

        if(typeChoose == "multi"){
            
            let rs = await contract.mint(data.accountAddress, data.tokenId, data.total * 1, data.price * 1, data.dividend * 1);
    
            console.log(888, contract.address)
            if (rs.transactionHash) {
                return true
            }
            return false
        }else{
            let res = await postAction(
                `good/mint/18/nft`,
                {
                    tx: "",
                    contract_address: contract.address,
                    token: data.tokenId * 1,
                    total:1,
                    price: DAPP.toWei(data.price+"") ,
                    dividend: data.dividend * 1
                },
                headers
            );
            return res.data
        }
        
    },

    async applyToBeCreator(data){
        // 
        let headers = await this.getAuthorization();
        let res = await postAction(
            `user/creator/apply`,
            data,
            headers
        );
        return res.data
    },

    async CreatorsList(){
        let headers = await this.getAuthorization();
        let res = await getAction(
            `admin/applies`,
            {},
            headers
        );
        return res.data 
    },

    async Approved(id){
        let headers = await this.getAuthorization();
        let res = await postAction(
            `admin/apply/${id}/approved`,
            {},
            headers
        );
        return res.data
    }


}

export default bus