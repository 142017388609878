import { Form, Input, Button, Checkbox, Modal,  Upload, Progress, message, Radio, Select } from 'antd';
import { LoadingOutlined, PlusOutlined, PlayCircleOutlined, PauseOutlined } from '@ant-design/icons';

import MusicLogo from "assets/Music.png"
import React, { useState, useEffect } from 'react';
import { getAction, postAction } from "utils/http"
import { create } from 'ipfs-http-client'
import CreateCollection from "../my-collection"
import bus from "utils/bus"

function CreateItem(props) {
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };

    const versions = Array.from({ length: 100 }, (v, i) => i + 1)

    const [type, setType] = useState(null);
    const [detailType, setDetailType] = useState(null);
    
    const [detailVideo, setDetailVideo] = useState("");
    const [detailVideoUp, setDetailVideoUp] = useState("");

    const [detailMusic, setDetailMusicUp] = useState("");
    const [detailMusicUp, setDetailMusicUpUp] = useState("");

    const [detailImage, setDetailImageUrl] = useState("");
    const [detailImageUp, setDetailImageUrlUp] = useState("");


    const upPro = {
        strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
        },
        strokeWidth: 3,
        format: percent => `${parseFloat(percent.toFixed(2))}%`,
    }


    const [videoCapture, setVideoCapture] = useState("");
    const [collections,setCollections] = useState([])
    const [videoCaptureUp, setVideoCaptureUp] = useState("");

    const [musicUp, setMusicUp] = useState("")
    const [musicUpUp, setMusicUpUp] = useState("")

    const [itemProgress, setItemProgress] = useState(0)
    const [itemVideoProgress, setItemVideoProgress] = useState(0)

    const [imageUrl, setImageUrl] = useState("")
    const [imageUrlUp, setImageUrlUp] = useState("")
    const [isModalVisible, setModalVisible] = useState(false)

    const [gid, setGid] = useState(null)
    const [headers, setHeaders] = useState({})
    const [audioPlay, setAutioPlay] = useState(false)
    const [audioDetailPlay, setDetailAutioPlay] = useState(false)
    const [loading, setLoading] = useState(false)

    const { Option } = Select

    const tailLayout = {
        wrapperCol: { offset: 0, span: 8 },
    };

    const closeDialog = ()=>{
        setModalVisible(false)
        loadCollections()
        
    }

    const beforeUploadItem = (file, fileList) => {
        const allowPicType = [
            'image/jpeg',
            'image/bmp',
            'image/gif',
            'image/png',
            'video/x-flv',
            'video/mp4',
            'video/x-ms-wmv',
            'video/mpeg',
            'video/x-msvideo',
            'video/quicktime',
            'audio/x-wav',
            'audio/x-mpegurl',
            'audio/mpeg',

        ]

        if (allowPicType.indexOf(file.type) != -1) {
            return true
        }


        message.warning(`Unsupported file type`);
        return false
    }

    const onVersionChange = (v) => {
        console.log(v)

    }

    const beforeUploadVideo = (file) => {
        const allowPicType = [
            'video/x-flv',
            'video/mp4',
            'video/quicktime',
            'video/x-ms-wmv',
            'video/x-msvideo',
        ]

        if (allowPicType.indexOf(file.type) != -1) {
            return true
        }


        message.warning(`Unsupported file type`);
        return false
    }

    const crreateCollection = ()=>{
        setModalVisible(true)
    }

    const onCollectionChange = (value)=>{
        console.log(8888,value)
        form.setFieldsValue({
            collection:value
        })
    }

    const onFinish = async (values) => {
        let data = form.getFieldsValue()

        console.log("######## data", data)
        let temp = null
        let temp_detail = null

        if (type == "img") {
            temp = imageUrlUp
        }
        if (type == "audio") {
            temp = musicUpUp
        }
        if (type == "video") {
            temp = videoCaptureUp
        }

        if (detailType == "img") {
            temp_detail = detailImageUp
        }
        if (detailType == "audio") {
            temp_detail = detailMusicUp
        }
        if (detailType == "video") {
            temp_detail = detailVideoUp
        }

        let formData = {
            ...data,
            image: temp,
            video: temp_detail
        }

        if (!formData.image) {
            delete formData.image
        }

        if (!formData.video) {
            delete formData.video
        }

        let headers = await bus.getAuthorization();
        console.log('Success form:', formData);
        let res = null
        if (gid) {
            res = await postAction(
                `good/edit/${gid}`,
                { ...formData, contract_address: "" },
                headers
            );
        } else {
            res = await postAction(
                "good/create",
                { ...formData, contract_address: "" },
                headers
            );
        }

        if (res.data.code == 0) {
            message.success(res.data.message);
            setImageUrl("")
            setImageUrlUp("")
            toMyItems();
        } else {
            message.warning(res.data.message);
        }

        // this.goodDialog = false;


    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const toMyItems = () => {
        props.setAdminKey("My items")
    }

    const handleVideoChange = info => {

        const event = info.event

        let type = bus.getFileType(info.file.name)
        setDetailType(type)

        if (event) { // 一定要加判断，不然会报错
            let percent = Math.floor((event.loaded / event.total) * 100)
            // this.setState({ percent: percent })
            //  console.log(percent) // percent就是进度条的数值
            setItemVideoProgress(percent)
        }
        if (!info.file.response) {
            return null;
        }

        if (type == "img") {
            if (info.file.response && info.file.response.code == 0) {
                setDetailImageUrl(info.file.response.data.url)
                setDetailImageUrlUp(info.file.response.data.uri)
            }
        }
        if (type == "audio") {
            if (info.file.response && info.file.response.code == 0) {
                setDetailMusicUp(info.file.response.data.url)
                setDetailMusicUpUp(info.file.response.data.uri)
            }
        }
        if (type == "video") {
            if (info.file.response && info.file.response.code == 0) {
                setDetailVideo(info.file.response.data.url)
                setDetailVideoUp(info.file.response.data.uri)
            }
        }
        
        
    }

    const handleChange = async info => {

        const event = info.event
        if (event) { // 一定要加判断，不然会报错
            let percent = Math.floor((event.loaded / event.total) * 100)
            // this.setState({ percent: percent })
            console.log(percent) // percent就是进度条的数值
            setItemProgress(percent)
        }



        if (!info || ! info.file || !info.file.response) {
            return null;
        }



        // let test = await client.cat(info.file.response.data.url)
        // console.log(888, test)

        // let type = bus.getFileType(info.file.response.data.url)

        // let test = await client.cat(info.file.response.data.url)

        let type = bus.getFileType(info.file.name)

        setType(type);

        if (type == "img") {
            if (info.file.response && info.file.response.code == 0) {
                setImageUrl(info.file.response.data.url)
                setImageUrlUp(info.file.response.data.uri)
            }
        }
        if (type == "audio") {
            if (info.file.response && info.file.response.code == 0) {
                setMusicUp(info.file.response.data.url)
                setMusicUpUp(info.file.response.data.uri)
            }
        }
        if (type == "video") {
            if (info.file.response && info.file.response.code == 0) {
                setVideoCapture(info.file.response.data.url)
                setVideoCaptureUp(info.file.response.data.uri)
            }
        }
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const playAudio = (src, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src = src
        audioRef.play()
        setAutioPlay(true)
    }

    const pauseAudio = (e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlay(false)
    }

    const playDetailAudio = (src, e) => {
        console.log("333333333333333 ",  src)
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioDetailRef")
        audioRef.src = src
        audioRef.play()
        setDetailAutioPlay(true)
    }

    const pauseDetailAudio = (e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioDetailRef")
        audioRef.pause()
        setDetailAutioPlay(false)
    }

    const [form] = Form.useForm();

    const getGoodDetail = async (id) => {
        // let headers = await bus.getAuthorization();
        let res = await getAction(`good/details/${id}`, {}, headers);
        if (res.data.code == 0) {
            let resData = res.data.data;
            let detail = { ...resData, link: resData.external_link };
            // this.form = detail;
            // this.imageUrl = detail.image;
            // this.goodDialog = true;
            form.setFieldsValue(detail)

            setType(bus.getFileType(detail.image))

            if (bus.getFileType(detail.image) == "img") {
                setImageUrl(detail.image)
            }

            if (bus.getFileType(detail.image) == "audio") {
                setMusicUp(detail.image)
            }

            if (bus.getFileType(detail.image) == "video") {
                setVideoCapture(detail.image)
            }

            setDetailVideo(detail.video)

        }
    }

    const loadCollections = async () => {
        let resItems = await bus.initCollections()
        if (resItems.code == 0) {
            setCollections( resItems.data )
        }
    }

    useEffect(async () => {
        console.log("#######################")
        let headers = await bus.getAuthorization();
        setHeaders(headers)
        props.record && await setGid(props.record.gid)
        props.record && getGoodDetail(props.record.gid)

        loadCollections()
    }, [])

    return (
        <>
            <audio id="audioRef" />
            <audio id="audioDetailRef" />
            <div>
                <div className="flex justify-between pb-6">
                    <span className="font-bold">Item information</span>
                    <div className="font-bold bg-black text-white rounded-full px-3 py-1 cursor-pointer" onClick={() => toMyItems()}>Back</div>
                </div>

                <div className="border py-6 px-6">
                    <Form
                        form={form}
                        {...layout}
                        layout="vertical"
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item label="Item">
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="api/upload"
                                beforeUpload={beforeUploadItem}
                                headers={headers}
                                progress={upPro}
                                onChange={handleChange}
                            >
                                <div className="h-24 flex flex-col items-center justify-center">
                                    {type == "img" && imageUrl && <img src={imageUrl} style={{ height: '100%' }} />}

                                    {type == "audio" && musicUp && <div className="w-24 h-24 border flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                        {
                                            audioPlay && <PauseOutlined onClick={(e) => pauseAudio(e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                        {
                                            !audioPlay && <PlayCircleOutlined onClick={(e) => playAudio(musicUp, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                    </div>}

                                    {type == "video" && videoCapture && <video muted autoplay="autoplay" src={videoCapture} style={{ height: '100%' }} />}

                                    {
                                        !type && uploadButton
                                    }
                                </div>
                            </Upload>
                            {
                                !!itemProgress && itemProgress != 100 && <Progress percent={itemProgress} size="small" />
                            }
                        </Form.Item>

                        <Form.Item label="Item Detail">
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                // beforeUpload={beforeUploadVideo}
                                showUploadList={false}
                                action="api/upload"
                                headers={headers}
                                progress={upPro}
                                onChange={handleVideoChange}
                            >
                                <div className="h-24 flex flex-col items-center justify-center">
                                    {detailType == "img" && detailImage && <img src={detailImage} style={{ height: '100%' }} />}

                                    {detailType == "audio" && detailMusic && <div className="w-24 h-24 border flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                        {
                                            audioDetailPlay && <PauseOutlined onClick={(e) => pauseDetailAudio(e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                        {
                                            !audioDetailPlay && <PlayCircleOutlined onClick={(e) => playDetailAudio(detailMusic, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                    </div>}

                                    {detailType == "video" && detailVideo && <video muted autoplay="autoplay" src={detailVideo} style={{ height: '100%' }} />}

                                    {/* {
                                        detailVideo && <video muted autoplay="autoplay" src={detailVideo} style={{ height: '100%' }} />
                                    } */}
                                    {
                                        !detailType && uploadButton
                                    }

                                </div>
                            </Upload>
                            {
                                !!itemVideoProgress && itemVideoProgress != 100 && <Progress percent={itemVideoProgress} size="small" />
                            }
                        </Form.Item>

                        <Form.Item
                            label="Item Name"
                            name="title"
                            rules={[{ required: true, message: 'Please input Item Name!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Item Description"
                            name="desc"
                        >
                            <Input.TextArea />
                        </Form.Item>


                        <Form.Item
                            label="Item Link"
                            name="link"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Item Collection"
                            name="collection"
                        >
                            <div className="flex">
                                <Select style={{ width: 200 }}  onChange={onCollectionChange}>
                                    {
                                        collections.map(v=>{
                                            return <Select.Option value={v.cid}>{v.title}</Select.Option>
                                        })
                                    }
                                </Select>
                                &emsp;&emsp;
                                <Button type="primary" onClick={crreateCollection}> Create Collection</Button>
                            </div>
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <div className="mr-8 inline-block">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                            {/* <div className=" inline-block">
                            <Button type="" onClick={() => { toMyItems() }}>
                                Quit
                            </Button>
                        </div> */}
                        </Form.Item>
                    </Form>
                </div>
                <Modal  width="80%" height="80%" visible={isModalVisible} footer={null} onCancel={closeDialog}>
                    <br/>
                    <CreateCollection></CreateCollection>
                </Modal>
                
            </div >
        </>
    )
}

export default CreateItem