import React, { useEffect, useState } from "react";
import "./creators.scss"
import { Layout, Row, Col, Divider, Pagination, Input, Spin, Form, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import MyHeader from "components/header"
import ActiveBanner from "components/active-banner"
import bus from "utils/bus";
import * as utils from "utils/utils"
import {Helmet} from "react-helmet";
import { Link, useLocation } from 'react-router-dom'
// import Exhibits from "components/exhibits";
// import NftInfo  from "components/nft-info";
import Creator from "components/creator"
// import Creator from "components/creator"
import MyFooter from "components/footer"
const { Header, Footer, Content } = Layout

function Creators(props) {
    const location = useLocation();
    const [keyword,setKeyword]  = useState("")
    const [userInfo, setUserInfo] = useState(props.userInfo)
    // const [activeMenum,setActiveMenu] = useState("Live Creator")
    const [dataSource, setDataSource] = useState({
        total: 0,
        datas: []
    })
    const [form] = Form.useForm();


    const pressEnter = async () => {
        await initDataSource()
    }
    let initDataSource = async (key="",page = 1, pageSize = 8) => {
        let keyword = form.getFieldsValue().keyword || key  || ""

        let res = await bus.initCreators( keyword, page, pageSize)
        if (res.code == 0) {
            setDataSource({
                total: res.data.total,
                datas: res.data.datas
            })
        }
    }

    const pageChange = async (page, pageSize) => {
        form.setFieldsValue({ keyword: "" })
        await initDataSource("", page, pageSize)
    }

    const onFinish = async () => {
        await initDataSource()
    }

    useEffect(async () => {
        let params = utils.parseParams(location.search.split("?")[1])
        setKeyword(params.keyword)
        // setUserInfo(props.userInfo)
        await initDataSource(params.keyword)
    }, [keyword])
    return (
        <>
            <Helmet>
                <title>NFT creators – Unique Hash</title>
                <meta name="description" content="Buy from our NFT creators in the uniquehash marketplace" />
            </Helmet>
            <ActiveBanner></ActiveBanner>
            <div className="my-layout">
                <Layout>
                    <Header className="shadow-lg">
                        <MyHeader usersSearch={initDataSource} userInfo={userInfo}></MyHeader>
                    </Header>
                    <Content className="my-content my-creator-content">
                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows">
                                    {/* <div className="my-mini-title w-full">
                                        <Form
                                            name="basic"
                                            form={form}
                                            initialValues={{ remember: true }}
                                            onFinish={onFinish}
                                        >
                                            <Form.Item
                                                name="keyword"
                                            >
                                                <div className="flex items-center">
                                                    <Input placeholder="Search" prefix={<SearchOutlined />} className="my-Creator-input" onPressEnter={pressEnter}></Input>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div> */}
                                    <div className="flex flex-wrap  my-Creators">

                                        {
                                            dataSource.datas.map(v => {
                                                return <div className="mr-4">
                                                    <Creator info={v}></Creator>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="px-6 flex justify-center py-10">
                                        <Pagination
                                            total={dataSource.total}
                                            showQuickJumper
                                            onChange={pageChange}
                                            showTotal={total => `Total ${total} items`}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>
                    </Content>
                    <Footer>
                        <MyFooter></MyFooter>
                    </Footer>
                </Layout>

            </div>
        </>
    )
}

export default Creators