
import { Table, Spin, Tag, message } from "antd"
import React, { useState, useEffect } from 'react';
import bus from "utils/bus";

function Users() {
    const [dataSource, setDataSource] = useState({
        total: 0,
        datas: []
    })

    const [currentPage, setCurrentPage] = useState(1)

    const initUsers = async (keyword = "", currentPage = 1, pageSize = 8) => {
        let res = await bus.getUsers(keyword, currentPage, pageSize)
        if (res.code == 0) {
            setDataSource({ total: res.data.total, datas: res.data.datas })
        }
    }

    useEffect(async () => {
        initUsers()
    }, [])



    const handleAllow = async (record) => {
        let status = 0
        if (record.status == 0) {
            status = 1;
        } else {
            status = 0;
        }

        await bus.allowUser(record.address_key, status)
        let res = await bus.getUsers()
        if (res.code == 0) {
            setDataSource({ total: res.data.total, datas: res.data.datas })
        }
        // setDataSource({ total: res.data.total, datas: res.data.datas })
    }

    const columns = [
        {
            title: '#',
            render: (text, record, index) => `${index + 1}`,
            key: 'index',
        },
        {
            title: 'Nickname',
            key: "nicename",
            dataIndex: "nicename"
        },
        {
            title: 'Address',
            key: "address",
            dataIndex: "address_key"
        },
//////////////
        {
            title: 'instagram',
            key: "instagram",
            render: (text, record, index) => <img alt="" src={record.instagram} className="h-8" />,
        },
        {
            title: 'twitter',
            key: "twitter",
            render: (text, record, index) => <img alt="" src={record.twitter} className="h-8" />,
        },
        {
            title: 'bio',
            key: "bio",
            render: (text, record, index) => <img alt="" src={record.bio} className="h-8" />,
        },
//////////////

        {
            title: 'Avatar',
            key: "avatar",
            render: (text, record, index) => <img alt="" src={record.avavtor} className="h-8" />,
        },
        {
            title: 'Background',
            key: "background",
            render: (text, record, index) => <img alt="" src={record.teaser_poster} className="h-8" />,
        },
        {
            title: 'Created',
            key: "created",
            render: (text, record, index) => bus.formatDate(record.created),
        }, {
            title: 'Email',
            key: "email",
            dataIndex: "email"
        }, {
            title: 'Action',
            key: "Action",
            render: (text, record, index) => {
                if (record.status == 0) {
                    return <div className="px-2 py-1 rounded-sm bg-blue-400 inline-block text-white cursor-pointer w-20" onClick={() => handleAllow(record)}>Allow</div>
                }

                if (record.status == 1) {
                    return <div className="px-2 py-1 rounded-sm bg-blue-400 inline-block text-white cursor-pointer w-20" onClick={() => handleAllow(record)}>Not allow</div>
                }
            },
        },

    ]
    return (
        <>
            <div className=" border-w-4 overflow-x">
                <Table dataSource={dataSource.datas} columns={columns} scroll={{
                    x: '100vw',
                }} pagination={{
                    // 设置总条数
                    total: dataSource.total,
                    // 显示总条数
                    showTotal: total => `total ${dataSource.total}`,
                    // 改变页码时
                    onChange: ((page, pageSize) => {
                        setCurrentPage(page)
                        initUsers("", page)
                    })
                }} />
            </div>
            {/* <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div> */}
        </>
    )
}

export default Users