
import { Table, Spin, Tag, message } from "antd"
import React, { useState, useEffect } from 'react';
import bus from "utils/bus";
import MusicLogo from "assets/Music.png"
import {PlayCircleOutlined,PauseOutlined } from "@ant-design/icons"

function MyBiding(props) {
    const [loading, setLoading] = useState(false)
    const [selectionType, setSelectionType] = useState('checkbox');
    const [userinfo, setUserInfo] = useState({})
    const [playIndex , setPlayIndex] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [dataSource, setDataSource] = useState({
        total: 0,
        datas: []
    })
    const [audioPlay,setAutioPlay]=useState(false)
    const handleRefund = async (record) => {
        let res = null
        await setLoading(true)
        try {
            res = await bus.handleRefund(record)
        } catch {
            await setLoading(false)
        }
        await setLoading(false)

        if (res) {
            if (res.code == 0) {
                message.success(res.message);

                initItems()
            } else {
                message.warning(res.message);
            }
        }

    }

    const playAudio = (src,index,e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src=src
        audioRef.play()
        setAutioPlay(true)

        setPlayIndex(index)
     }
 
     const pauseAudio=(index,e)=>{
         e.stopPropagation();
         e.preventDefault()
         let audioRef = document.getElementById("audioRef")
         audioRef.pause()
         setAutioPlay(false)

         setPlayIndex(null)
     }
 

    const heandleTransfer = async (record) => {
        let res = null

        await setLoading(true)
        try {
            res = await bus.heandleTransfer(record)
        } catch {
            await setLoading(false)
        }

        await setLoading(false)


        if (res) {
            if (res.code == 0) {
                message.success(res.message);

                initItems()
            } else {
                message.warning(res.message);
            }
        }

    }
    let initItems = async (currentPage = 1, pageSize = 8) => {
        let resItems = await bus.initItems(currentPage, pageSize)
        if (resItems.code == 0) {
            await setDataSource({
                total: resItems.data.total,
                datas: resItems.data.datas.map(v => {
                    return {
                        ...v,
                        key: "binding" + v.auction.aid
                    }
                })
            })


        }
    }
    useEffect(async () => {
        initItems()
        let userinfo = bus.getLocalStore("userInfo")

        await setUserInfo(userinfo)
    }, [])

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    /*
    
    STATUS_BIDDING_GOING   = 0 // 竞拍进行中
    STATUS_BIDDING_SUCCESS = 1 // 成功竞拍
    STATUS_BIDDING_FAILED  = 2 // 竞拍已退款

    */
    const showDrawDown = (record) => {

        for (let i = 0; i < record.biddings.length; i++) {
            if (record.biddings[i].status == 0) {
                if (record.auction.highest_bidding.from_address !== userinfo.address_key) {
                    return true
                }
            }
        }

        return false
    }

    const columns = [
        {
            title: '#',
            render: (text, record, index) => `${index + 1}`,
            key: 'index',
            width:80
        },
        {
            title: 'Item name',
            dataIndex: 'title',
            key: 'title',
            width:300,
            render: (text, record, index) => record.good.title
        },
        {
            title: 'Item',
            width:120,
            dataIndex: 'image',
            key: 'image',
            render: (text, record, index) => {
                if(bus.getFileType(record.good.image) == 'img'){
                    return <div className="w-24 h-24 border flex items-center my-good_image2" style={{ background: `url(${record.good.image}) no-repeat` }}></div>
                }
                if(bus.getFileType(record.good.image) == 'audio'){
                    return <div className="w-24 h-24 border flex items-center my-good_image2 justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                        {
                            audioPlay && playIndex===index  && <PauseOutlined onClick={(e)=>pauseAudio(index,e)}  style={{fontSize:'68px',opacity:0.4}} />
                        }
                        {
                            (playIndex!==index || playIndex===null)   && <PlayCircleOutlined onClick={(e)=>playAudio(record.good.image,index,e)} style={{fontSize:'68px',opacity:0.4}}/>
                        } 
                    </div>
                }
  
                if(bus.getFileType(record.good.image) == 'video'){
                   return <div className="w-24 h-24 border flex items-center my-good_image2"><video controls  muted autoplay="autoplay"  src={record.good.image} /> </div>
                }
                // <img alt="" src={record.good.image} className="h-8" />
            }
        },
        {
            title: 'Item link',
            dataIndex: 'link',
            width:300,
            key: 'link',
            render: (text, record, index) => record.good.external_link
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width:150,
            render: (text, record, index) => {
                if (record.auction.second_duration <= 0) {
                    return <Tag color="volcano">Auction closed</Tag>
                }
                if (record.auction.second_duration > 0) {
                    return <Tag color="green">The auction is in progress</Tag>
                }
            }
        },
        {
            title: 'Network',
            dataIndex: 'network',
            key: 'network',
            width:200,
            render: (text, record, index) => bus.getNameOfNetwork(record.good.network) 
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'description',
        //     key: 'description',
        //     render: (text, record, index) => record.good.desc
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {

                if (userinfo && record.auction.second_duration == 0 && (record.auction.status == 3 || record.auction.status == 5) && record.auction.highest_bidding.from_address == userinfo.address_key) {
                    return <div className="px-2 py-1 rounded-sm bg-green-400 inline-block text-white cursor-pointer w-24" onClick={() => heandleTransfer(record)}>get the item</div>
                }

                if (showDrawDown(record)) {
                    return <div className="px-2 py-1 rounded-sm bg-blue-400 inline-block text-white cursor-pointer w-24" onClick={() => handleRefund(record)}>draw-down</div>
                }

                else {
                    return
                }
            }
        },
    ];


    return (
        <>
             <audio id="audioRef" />
            <div className=" border-w-4 overflow ">
                {/* <div className="flex justify-between py-4">
                    <span></span>
                    <div className="px-2 py-1 rounded-sm bg-blue-400 inline-block text-white cursor-pointer w-24" onClick={() => handleRefunds()}>draw-down</div>
                </div> */}

                <Table dataSource={dataSource.datas} columns={columns} expandable={{
                    expandedRowRender: record => {
                        return <p>
                            <ul>
                                {/* <li>
                                    <span className="ml-12">
                                        {record.auction.highest_bidding.from_address}
                                    </span>
                                    <span className="ml-8">--&gt; </span>
                                    <span className="ml-8"> {record.auction.highest_bidding.owned_address}</span>
                                    <span className="ml-8">{record.auction.highest_bidding.price} ETH</span>
                                </li> */}
                                {
                                    record.biddings.map(v => {
                                        return <li className="py-6">
                                            <span className="ml-12">
                                                {v.from_address}
                                            </span>
                                            <span className="ml-8">--&gt; </span>
                                            <span className="ml-8"> {v.owned_address}</span>
                                            <span className="ml-8">{v.price} ETH</span>
                                        </li>
                                    })
                                }
                            </ul>
                        </p>
                    },
                    rowExpandable: record => record.biddings.length > 0,
                }} scroll={{
                    x: '100%',
                }} pagination={{
                    // 设置总条数
                    total: dataSource.total,
                    // 显示总条数
                    showTotal: total => `total ${dataSource.total}`,
                    // 改变页码时
                    onChange: ((page, pageSize) => {
                        setCurrentPage(page)
                        initItems(page)
                    })
                }} />

                <div className="flex items-center">
                    <span className="text-red-600 font-bold text-xl mr-2">*</span>
                    <span>After winning the auction, you need to click "Get The Item" to get it. If you are not the winner, you can click "Refund" to get your bid coins back</span>
                </div>
            </div>
            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>
        </>

    )
}

export default MyBiding