import temp_src_exhibits from 'assets/temp/exhibits.png'
import React, { useState, useEffect } from "react";
import { message } from "antd"
import {PlayCircleOutlined,PauseOutlined } from "@ant-design/icons"
import "./auction.scss"
import MusicLogo from "assets/Music.png"
import { Link, Redirect, useLocation } from 'react-router-dom'
import bus from 'utils/bus';
function Auction(props) {
    const detail = props.info || null

    const [timerClock, setTimerClock] = useState(Math.floor(detail.second_duration))

    const [eth, setEth] = useState(0)
    const [audioPlay,setAutioPlay]=useState(false)

    const clockTimer = () => {
        if (timerClock <= 0) {
            return
        }
        setTimeout(async () => {
            await setTimerClock(timerClock - 1);
        }, 1000);
    }

    const playAudio = (src,e)=>{
       e.stopPropagation();
       e.preventDefault()
       let audioRef = document.getElementById("audioRef")
       audioRef.src=src
       audioRef.play()
       setAutioPlay(true)
    }

    const pauseAudio=(e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlay(false)
    }

    useEffect(async () => {
        console.log("detail ## detail", detail)
        if (Math.floor(detail.second_duration) == timerClock) {
            let eth_temp = await bus.eth(detail.good.contract_address)

            console.log("##########5555",eth_temp , detail)
            setEth(eth_temp)
        }
        // await setTimerClock(Math.floor(detail.second_duration))
        clockTimer()
    }, [timerClock])
    return (
        <>
            <audio id="audioRef" />
            {
                detail && <Link to={`/detail/${detail.aid}`} >
                    <div className="inline-block  shadow-xl border mb-4 rounded-lg text-black">
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="w-64 h-64 border flex items-center my-good_image" style={{ background: `url(${detail.good && detail.good.image}) no-repeat` }}></div>
                        } 
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="w-64 h-64 border flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                {
                                    audioPlay && <PauseOutlined onClick={(e)=>pauseAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                                }
                                {
                                    !audioPlay && <PlayCircleOutlined onClick={(e)=>playAudio(detail.good.image,e)} style={{fontSize:'68px',opacity:0.4}}/>
                                }
                            </div>
                        }
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="w-64 h-64 border flex justify-center items-center my-good_image"><video controls loop muted autoplay="autoplay"  src={detail.good.image} /> </div>
                        }
                        <div className="flex flex-col py-3 pl-4">
                            <span className="font-bold text-sm  ellipse-hidden">{detail.good.title}</span>
                            {
                                detail.user && <span className="text-xs font-bold text-gray-500">@{detail.user.uri_name || bus.formatUid(detail.user.address_key || detail.user.address)}</span>
                            }
                            {
                                detail.creator && <span className="text-xs font-bold text-gray-500">@{detail.creator.uri_name || bus.formatUid(detail.creator.address_key || detail.creator.address)}</span>
                            }
                        </div>
                        <div className="inline-block bg-black rounded-bl-xl rounded-br-xl h-24 w-full  text-sm text-white flex items-center pl-4 my-auction-info ">
                            <div className=" inline-block w-24 h-20 flex-col flex justify-center">
                                <span className="text-gray-200 ">Current Price</span>
                                {
                                    detail.highest_bidding && <>
                                        <span className="font-bold">{detail.highest_bidding.price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                        <span className="text-gray-400 "> ${(eth * detail.highest_bidding.price).toFixed(2)}</span>
                                    </>
                                }
                                {
                                    !detail.highest_bidding && <>
                                        <span className="font-bold">{detail.start_price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                        <span className="text-gray-400 "> ${(eth * detail.start_price).toFixed(2)}</span>
                                    </>
                                }
                            </div>
                            {
                                // 拍卖进行中
                                timerClock > 0 && detail.second_duration> 0 && <div className=" flex-col flex h-20 justify-center" >
                                    <span className="text-gray-200">Auction ending in</span>
                                    <div className="flex justify-between text-left">
                                        <div className=" w-full font-bold mr-1">{bus.sToHMS(timerClock)[0]}</div>
                                        <div className="mr-2 w-full font-bold ">{bus.sToHMS(timerClock)[1]}</div>
                                        <div className=" w-full font-bold">{bus.sToHMS(timerClock)[2]}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className=" w-full text-gray-400  mr-1">Hours</span>
                                        <span className="mr-2 w-full text-gray-400">Minutes</span>
                                        <span className=" w-full text-gray-400">Seconds</span>
                                    </div>
                                </div>
                            }
                            {
                                // 拍卖已结束
                                detail.second_duration == 0 && <div className=" flex-col flex h-20 justify-center" >
                                    <span>Auction has ended</span>
                                </div>
                            }
                            {
                                // 拍卖尚未开始
                                detail.second_duration == -1 && <div className="  flex-col flex h-20 justify-center" >
                                    <span>No one pay</span>
                                </div>
                            }
                        </div>
                    </div>
                </Link>
            }

        </>
    )
}

export default Auction