import { Layout, Row, Col, Divider, Pagination, Input, Spin, Form, message } from 'antd';
import MyHeader from "components/header"
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'
import ActiveBanner from "components/active-banner"
import temp_src_exhibits from 'assets/temp/exhibits.png'
import React, { useEffect, useState } from "react";
import MyFooter from "components/footer"
import uh from "assets/uh-origin.jpg"
import metamask from "assets/metamask.png"
import metamask2 from "assets/metamask2.png"
import auction from "assets/auction.png"
import dodge from "assets/dodge.png"
import adminImg from "assets/admin.png"
import cItem from "assets/create-item.png"
import bus from "utils/bus";
import "./follow.scss"
import * as utils from "utils/utils"
const { Header, Footer, Content } = Layout
function FollowPage(props) {
    const location = useLocation();
    const [userInfo, setUserInfo] = useState(props.userInfo)

    const [followList,setFollowList]=useState(null)
    // const [friends,setFriendsList]=useState([])

    let params = utils.parseParams(location.search.split("?")[1])
    
    useEffect(async () => {
        if(params.type=='followee'){
            let followee = await bus.getFollowees(params.address)
            console.log(3,followee)
            if (followee.code == 0) {
                setFollowList(followee.data.datas)
            }
        }
        if(params.type=='friends'){
            let friends = await bus.getFriends(params.address)
            console.log(4,friends)
            if (friends.code == 0) {
                setFollowList(friends.data.datas)
            }
        }
        setTimeout(() => {
            console.log(2   ,followList)
        }, 1000);
    }, [])


    return (
        <>
            <div className="">
                <ActiveBanner></ActiveBanner>
                <div className="my-layout my-help">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content" style={{minHeight:'40vh'}}>
                            <Row>
                                <Col span={2} className="my-col-left"></Col>
                                <Col span={20} className="my-col-content">
                                    <div className="content">
                                        <ul className="flex  py-8 flex-wrap">
                                            {
                                               followList && followList.map(v=>{
                                                    return <Link to={`/creator?address=${v.address_key}`} >
                                                                <li className="mr-12 w-16 mt-6">
                                                                    <div className="flex flex-col justify-center items-center">
                                                                        <img src={`${v.avatar || temp_src_exhibits}`} className="w-12 h-12 rounded-full" />
                                                                        <span className="over-eli">{v.uri_name || v.nicename}</span>
                                                                    </div>
                                                                </li>
                                                            </Link>
                                                })
                                            }
                                            
                                        </ul>
                                    </div>
                                </Col>
                                <Col span={2} className="my-col-right"></Col>
                            </Row>
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>
                </div>
            </div>
        </>
    )
}

export default FollowPage