import temp_src_exhibits from 'assets/temp/exhibits.png'
import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'
import {PlayCircleOutlined,PauseOutlined } from "@ant-design/icons"
import bus from 'utils/bus';
import MusicLogo from "assets/Music.png"
import "./featured.scss"


function Featured(props) {
    const [timerClock, setTimerClock] = useState(0)
    const detail = props.info || {}
    const [eth, setEth] = useState(0)

    const [audioPlay,setAutioPlay]=useState(false)
    const clockTimer = () => {
        let timer = setInterval(() => {
            if (timerClock <= 0) {
                clearInterval(timer);
                return
            }
            setTimerClock(--timerClock);
        }, 1000);
    }

    const playAudio = (src,e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src=src
        audioRef.play()
        setAutioPlay(true)
     }
 
     const pauseAudio=(e)=>{
         e.stopPropagation();
         e.preventDefault()
         let audioRef = document.getElementById("audioRef")
         audioRef.pause()
         setAutioPlay(false)
     }

    useEffect(async () => {
        let eth_temp = await bus.eth(detail.good.contract_address)
        setEth(eth_temp)
        clockTimer()
    }, [])
    return (
        <>
            <audio id="audioRef" />
            {
                detail &&
                <Link to={`/detail/${detail.aid}`} >
                    <div className="inline-block  shadow-xl border mb-4 rounded-lg  text-black">
                        {/* <div className="w-72 h-72 border flex items-center my-good_image" style={{ background: `url(${detail.good && detail.good.image}) no-repeat` }}>

                        </div> */}
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="w-64 h-64 border flex items-center my-good_image" style={{ background: `url(${detail.good && detail.good.image}) no-repeat` }}></div>
                        } 
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="w-64 h-64 border flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                {
                                    audioPlay && <PauseOutlined onClick={(e)=>pauseAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                                }
                                {
                                    !audioPlay && <PlayCircleOutlined onClick={(e)=>playAudio(detail.good.image,e)} style={{fontSize:'68px',opacity:0.4}}/>
                                }
                            </div>
                        }
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="w-64 h-64 border flex justify-center items-center my-good_image"><video loop controls muted autoplay="autoplay"  src={detail.good.image} /> </div>
                        }
                        <div className="flex flex-col py-3 pl-4 shadow-md ">
                            <span className="font-bold text-sm ellipse-hidden">{detail.good.title}</span>
                            
                            {
                                detail.user && <span  className="text-xs font-bold text-gray-500">@{detail.user.uri_name || bus.formatUid(detail.user.address_key)}</span>
                            }
                            {
                                !detail.user && detail.owner && <span  className="text-xs font-bold text-gray-500">@{detail.owner.uri_name || bus.formatUid(detail.owner.address_key)}</span>
                            }

                            
                        </div>
                        <div className="inline-block  rounded-bl-xl rounded-br-xl h-24 w-full  text-sm  flex items-center pl-4 ">
                            <div className=" inline-block w-48 h-20 flex-col flex justify-center ">
                                <span className="text-gray-600 ">Starting Price</span>
                                <span className="font-bold">{detail.start_price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                <span className="text-gray-600 "> ${(eth * detail.start_price).toFixed(2)}</span>
                            </div>
                            {/* <div className="w-40  flex-col flex h-20 justify-center">
                        <span className="text-gray-200">Auction ending in</span>
                        <div className="flex justify-between text-left">
                            <div className=" w-full font-bold">12</div>
                            <div className="mr-2 w-full font-bold ">04</div>
                            <div className=" w-full font-bold">36</div>
                        </div>
                        <div className="flex justify-between">
                            <span className=" w-full text-gray-400">Hours</span>
                            <span className="mr-2 w-full text-gray-400">Minutes</span>
                            <span className=" w-full text-gray-400">Seconds</span>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </Link>
            }
        </>
    )
}

export default Featured