import React, { useEffect, useState } from "react";
import temp_src_exhibits from 'assets/temp/exhibits.png'
import "./collection.scss"
import { Layout, Row, Col, Divider, Table, Button,  Pagination,Popover, Empty, Spin  } from 'antd';
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'
import MyHeader from "components/header"
import FixedItem from "components/fixedItem"
import Auction from "components/auction"
import Featured from "components/featured"
import MyFooter from "components/footer"
import ActiveBanner from "components/active-banner"

import defaultBg from "assets/default-creator-bg.png"
import { TwitterOutlined, ShareAltOutlined, InstagramFilled } from '@ant-design/icons';
import * as utils from "utils/utils"
import bus from "utils/bus";
const { Header, Footer, Content } = Layout;

function Collection(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [category, setCategory] = useState("Created")
    const location = useLocation();
    const [followees, setFollowee] = useState([])
    const [collectionGoods, setCollections] = useState(null)
    const [collectionInfo, setCollectionInfo] = useState(null)
    const [friends, setFriends] = useState([])
    const [detail, setDetail] = useState(null)

    let params = location.pathname.split("/") // utils.parseParams(location.search.split("?")[1])

    console.log("**********************", params)

    useEffect(async () => {

        // setUserInfo(props.userInfo)
        let res = await bus.initUserInfo(params[3])
      
        if (res.code == 0) {
            await setDetail(res.data)
            let followee = await bus.getFollowees(params[3])
            let friends = await bus.getFriends(params[3])
            if (followee.code == 0) {
                 setFollowee(followee.data.datas)
            }
            if(friends.code == 0){
                 setFriends(friends.data.datas)
            }
        }
        collectionOfCreator()
    }, [location])

    const handlePager = (page, pageSize)=>{
        console.log("abcd", page, pageSize)
        collectionOfCreator(page, pageSize)
        
    }

    const collectionOfCreator = async(currentPage=1,pageSize=10)=>{
        let res = await bus.collectionDetail(params[2], currentPage, pageSize)
        console.log(222,res,res.goods)
        // let temp = res.goods.map(v=>{
        //     return {
        //         ...v,
        //         good: v,

        //     }
        // })
       
        // setCollections(temp)

        // setCollectionInfo(res)

        setCollections(res)

    }



    return (
        <>
            <ActiveBanner></ActiveBanner>
            {
                detail && <div className="my-layout">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content">
                            <div className="my-col-content-rows">
                                <div className="w-full h-60 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${detail.teaser_poster || defaultBg})` }}>

                                </div>
                                <Row>
                                    <Col span={2} className="my-col-creator-left"></Col>
                                    <Col span={20} className="my-col-creator-content" >
                                        <div className="flex relative pt-16 pb-10 my-mini-creator-content">
                                            <div>
                                                <div className="flex flex-col">
                                                    <span className="font-bold text-2xl">{detail.nicename}</span>
                                                    <span>{bus.formatUid(detail.address_key)}</span>
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text-sm font-bold">Bio</span>
                                                    <div className="w-60 break-words">{detail.bio}</div>
                                                </div>
                                                <div className="flex py-6">
                                                    
                                                    {
                                                        <Link to={`/follow?type=friends&address=${detail.address_key}`} >
                                                            <div className="flex flex-col mr-4 cursor-pointer">
                                                                <span className="font-bold text-xl">{detail.count_friends}</span>
                                                                <span className="text-gray-400 font-bold">Followimg</span>
                                                            </div>
                                                        </Link>
                                                    }
                                                    

                                                    
                                                    {
                                                        <Link to={`/follow?type=followee&address=${detail.address_key}`} >
                                                            <div className="flex flex-col cursor-pointer">
                                                                <span className="font-bold text-xl">{detail.count_followee}</span>
                                                                <span className="text-gray-400 font-bold">Followers</span>
                                                            </div>
                                                        </Link>
                                                    }
                                                </div>
                                                {
                                                    detail.count_followee*1>0 && <div className="flex flex-col" >
                                                        <span className="font-bold text-gray-400 mb-2">Followed by</span>
                                                        <div className="flex">
                                                            {
                                                                followees.slice(0, 4).map(v => {
                                                                    return <Link to={`/creator?address=${v.address_key}`} >
                                                                        <img alt="" className="w-6 h-6 mr-2 rounded-full" src={v.avatar} />
                                                                    </Link>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="mt-8">
                                                    {
                                                        detail.twitter && <a href={`https://twitter.com/${detail.twitter}`}>
                                                        <div className="w-60 rounded-full bg-black text-white px-5 py-1 my-2 flex items-center justify-between">
                                                            <div className="flex items-center">
                                                                <TwitterOutlined />
                                                                <span className="ml-2">{detail.twitter}</span>
                                                            </div>
                                                            <ShareAltOutlined />
                                                        </div>
                                                    </a>
                                                    }
                                                    {
                                                        detail.instagram && <a href={`https://instagram.com/${detail.instagram}`}>
                                                        <div className="w-60 rounded-full bg-black text-white px-5 py-1 my-2 flex items-center justify-between">
                                                            <div className="flex items-center">
                                                                <InstagramFilled />
                                                                <span className="ml-2">{detail.instagram}</span>
                                                            </div>
                                                            <ShareAltOutlined />
                                                        </div>
                                                    </a>
                                                    }
                                                </div>
                                            </div>
                                            <div className=" w-full ml-12 my-mini-creators-created">
                                                <div className=" w-full ">

                                                    <div className="flex items-center w-full text-3xl font-bold ">
                                                        {/* {
                                                         collectionInfo && collectionInfo.title  
                                                        } */}

                                                        {
                                                            collectionGoods && collectionGoods.title  
                                                        }
                                                    </div>
                                                    <Divider />

                                                    <div className="flex flex-wrap">
                                                        {
                                                            collectionGoods &&  collectionGoods.goods.datas.map(v => {
                                                                return <div className="mr-4">
                                                                {
                                                                    v.second_duration != -1 && !v.versions && <Auction info={v}></Auction>
                                                                }
                                                                {
                                                                    v.second_duration == -1 && !v.versions && <Featured info={v}></Featured>
                                                                }
                                                                {
                                                                    v.versions && <div ><FixedItem info={v} ></FixedItem></div>
                                                                }
                                                                </div>
                                                            })
                                                        }

                                                        {
                                                            collectionGoods &&  collectionGoods.goods.datas.length === 0 && <div className="content-loading"><Empty  image={Empty.PRESENTED_IMAGE_SIMPLE}  /></div> 
                                                        }
                                                    </div>

                                                   {collectionGoods &&
                                                        <Pagination defaultCurrent={1}  total={collectionGoods.goods.total} onChange={handlePager} />
                                                   }
                                                   
                                                   {/* {
                                                     <Table dataSource={collections} columns={columns}  pagination={null}/>
                                                   } */}

                                                   {
                                                       !collectionGoods && <div className="content-loading"><Spin size="large" /></div>
                                                   }
                                                 
                                                </div>
                                            </div>

                                            <div className="rounded-full p-3 bg-white inline-block absolute -top-12">
                                                <img alt={detail.nicename} className="w-20 h-20 rounded-full" src={detail.avatar || temp_src_exhibits} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={2} className="my-col-creator-right"></Col>

                                </Row>
                            </div>
                            
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>

                </div>
            }
        </>

    )
}

export default Collection