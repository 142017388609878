import { Row, Col } from 'antd';
import logo from 'assets/logo-white.png'
import { Link, useLocation } from 'react-router-dom'
import "./footer.scss"
function MyFooter() {
    return (
        <div className="bg-black text-white w-full absolute">
            <Row>
                <Col span={2} className="my-col-left"></Col>
                <Col span={20} className="my-col-content">
                    <div className=" flex justify-between flex-wrap my-mini-content my-mini-flex pc" >
                        <div className="flex items-center my-mini-flex">
                            <img src={logo} alt="" className="w-8 py-6" />
                            <ul className="flex flex-wrap  pr-3 py-6">
                                <li className="px-8 cursor-pointer text-center ">
                                    <a href="https://instagram.com/uniquehash" className="text-white">Instagram</a>
                                </li>
                                <li className="px-8 cursor-pointer text-center ">
                                    <a href="https://twitter.com/uniquehash" className="text-white">Twitter</a>
                                </li>
                                <li className="px-8 cursor-pointer text-center ">
                                    <a href="https://discord.gg/CXbVYcrp" className="text-white">Discord</a>
                                </li>
                            </ul>
                        </div>
                        <div className="flex items-center my-mini-flex ">
                            <div className="w-8 "></div>
                            <ul className="flex my-mini-flex flex-wrap ">
                                <li className="px-8 py-4 cursor-pointer text-center text-white">
                                    <Link to={`/team`} > <span className="text-white"> Terms of Service </span> </Link>
                                </li>
                                <li className="px-8 py-4 cursor-pointer text-center text-white ">
                                    <Link to={`/privacy`} > <span className="text-white"> Privacy </span> </Link>
                                </li>
                                <li className="px-8 py-4 cursor-pointer text-center text-white ">
                                    <Link to={`/help`} > <span className="text-white"> Help </span> </Link>
                                </li>
                                <li className="px-8 py-4 cursor-pointer text-center text-white ">
                                    <Link to={`/contact`} > <span className="text-white"> Contact us </span> </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="phone">
                        <img src={logo} />
                        <div  className="flex flex-wrap py-6 pl-4">
                            <div  className="cursor-pointer text-center leading-6">
                                <a href="https://instagram.com/uniquehash" className="text-white">Instagram</a>
                                <span  className="inline-block pl-3  pr-3">|</span>     
                            </div>   
                            <div  className="cursor-pointer text-center leading-6">
                                <a href="https://twitter.com/uniquehash" className="text-white">Twitter</a>
                                <span className="inline-block pl-3 pr-3">|</span>     
                            </div>    
                            <div  className="cursor-pointer text-center leading-6">
                                <a href="https://discord.gg/CXbVYcrp" className="text-white">Discord</a>
                                <span className="inline-block pl-3 pr-3">|</span>     
                            </div>  
                            <div  className="cursor-pointer text-center leading-6">
                                <Link to={`/privacy`} > <span className="text-white"> Privacy </span> </Link>
                                <span className="inline-block pl-3 pr-3">|</span>     
                            </div>  
                            <div  className="cursor-pointer text-center leading-6">
                                <Link to={`/team`} > <span className="text-white"> Terms of Service </span> </Link>
                                <span className="inline-block pl-3 pr-3">|</span>     
                            </div>  
                            
                            <div  className="cursor-pointer text-center leading-6">
                                <Link to={`/help`} > <span className="text-white"> Help </span> </Link>
                                <span className="inline-block pl-3 pr-3">|</span>     
                            </div>  
                            <div  className="cursor-pointer text-center leading-6">
                                <Link to={`/contact`} > <span className="text-white"> Contact us </span> </Link>
                                {/* <span className="inline-block pl-3 pr-3">|</span>      */}
                            </div>                 
                        </div>
                    </div>
                </Col>
                <Col span={2} className="my-col-right"></Col>
            </Row>
        </div>
    )
}

export default MyFooter