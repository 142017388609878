import React, { useEffect, useState } from "react";
import "./Home.scss"
import { Layout, Row, Col, Divider } from 'antd';
import MyHeader from "components/header"
import Exhibits from "components/exhibits";
import NftInfo from "components/nft-info";
import Auction from "components/auction"
import FixedItem from "components/fixedItem"
import {Helmet} from "react-helmet";
import Featured from "components/featured"
import Creator from "components/creator"
import MyFooter from "components/footer"
import ActiveBanner from "components/active-banner"
// const MyFooter = React.lazy(() => import("components/footer"))
import bus from "utils/bus"
import { Link, useLocation } from 'react-router-dom'
const { Header, Footer, Content } = Layout;

function Home(props) {
    const [detail, setDetail] = useState(null)
    const [liveAuctions, setLiveAuctions] = useState([])
    const [fixedPriceItems, setFixedPriceItems] = useState([])
    const [featuredArtwork, setFeaturedArtwork] = useState([])
    const [creators, setCreators] = useState([])
    const [userInfo, setUserInfo] = useState(props.userInfo)

    const updateCreators = async () => {
        let creators = await bus.getCreators()
        if (creators.code == 0) {
            setCreators(creators.data.datas)
        }
    }

    const chooseFixed = (v) => {
        console.log(111, v)
        props.setItemInfo(v)
    }

    useEffect(async () => {

        // setUserInfo(props.userInfo)
        // let userInfo bus.getLocalStore("userInfo")
        // props.setUserInfo()
        let boot = await bus.initBoot()

        await setDetail(boot.feature_auction)

        let liveAuctions = await bus.getLiveAuctions()
        if (liveAuctions.code == 0) {
            setLiveAuctions(liveAuctions.data.datas)
        }

        let featuredArtworks = await bus.getFeaturedArtworks()
        if (featuredArtworks.code == 0) {
            setFeaturedArtwork(featuredArtworks.data.datas)
        }

        let fixedPriceArtworks = await bus.getFixedPriceItems()
        if (fixedPriceArtworks.code == 0) {
            setFixedPriceItems(fixedPriceArtworks.data.datas)
        }


        let creators = await bus.getCreators()
        if (creators.code == 0) {
            setCreators(creators.data.datas)
        }
    }, [userInfo])

    return (
        <>
            <Helmet>
                <title>Unique Hash</title>
                <meta name="description" content="Create Your unique NFT. Share & Sell NFT in the uniquehash marketplace" />
            </Helmet>
            <ActiveBanner></ActiveBanner>
            <div className="my-layout">
                <Layout>
                    <Header className="shadow-lg">
                        <MyHeader userInfo={userInfo}></MyHeader>
                    </Header>
                    <Content className="my-content">
                        {
                            !!detail && <Exhibits detail={detail}></Exhibits>
                        }

                        <NftInfo></NftInfo>

{/* 
                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows">
                                    <div className="my-mini-title w-full">
                                        <h1 className="font-bold text-2xl mt-16 w-10/12">Featured Artwork</h1>
                                        <Divider />
                                    </div>
                                    <div className=" flex flex-wrap  my-featured-artwork">

                                        {
                                            featuredArtwork.map(v => {
                                                return <div className="mr-4">
                                                    {
                                                        v.second_duration != -1 && !v.versions && <Auction info={v}></Auction>
                                                    }
                                                    {
                                                        v.second_duration == -1 && !v.versions && <Featured info={v}></Featured>
                                                    }

                                                </div>
                                            })
                                        }

                                    </div>
                                    <div className="border border-gray-400 border-solid inline-block rounded-full py-1 px-6 font-bold my-view-more-btn cursor-pointer">
                                        <Link to={`/artwork/Featured`} className="my-link">
                                            View All Artwork
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row> */}

                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows">
                                    <div className="my-mini-title w-full">
                                        <h1 className="font-bold text-2xl mt-16 w-10/12">Live Auctions</h1>
                                        <Divider />
                                    </div>
                                    <div className=" flex flex-wrap  my-live-auctions">
                                        {
                                            liveAuctions.map(v => {
                                                return <div className="mr-4">
                                                    {
                                                        v.second_duration != -1 && !v.versions && <Auction info={v}></Auction>
                                                    }
                                                    {
                                                        v.second_duration == -1 && !v.versions && <Featured info={v}></Featured>
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="border border-gray-400 border-solid inline-block rounded-full py-1 px-6 font-bold my-view-more-btn cursor-pointer">
                                        <Link to={`/artwork/Live`} className="my-link">
                                            View All Live Auctions
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>

                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows">
                                    <div className="my-mini-title w-full">
                                        <h1 className="font-bold text-2xl mt-16 w-10/12">Fixed Price Items</h1>
                                        <Divider />
                                    </div>
                                    <div className=" flex flex-wrap  my-live-auctions">
                                        {
                                            fixedPriceItems.map(v => {
                                                return <div className="mr-4">
                                                    {
                                                        v.second_duration != -1 && !v.versions && <Auction info={v}></Auction>
                                                    }
                                                    {
                                                        v.second_duration == -1 && !v.versions && <Featured info={v}></Featured>
                                                    }
                                                    {
                                                        v.versions && <div onClick={() => chooseFixed(v)}><FixedItem info={v} setItemInfo={props.setItemInfo}></FixedItem></div>
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="border border-gray-400 border-solid inline-block rounded-full py-1 px-6 font-bold my-view-more-btn cursor-pointer">
                                        <Link to={`/artwork/Fixed`} className="my-link">
                                            View All Fixed Price Items
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>

                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows">
                                    <div className="my-mini-title w-full">
                                        <h1 className="font-bold text-2xl mt-16 w-10/12">Featured Creator</h1>
                                        <Divider />
                                    </div>
                                    <div className="flex flex-wrap  my-featured-creator">
                                        {
                                            creators.map(v => {
                                                return <div className="mr-4"><Creator info={v} updateCreators={updateCreators}></Creator></div>
                                            })
                                        }
                                    </div>

                                    <div className="border border-gray-400 border-solid inline-block rounded-full py-1 px-6 font-bold my-view-more-btn cursor-pointer">
                                        <Link to={`/creators`} className="my-link">
                                            View All Creators
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>


                    </Content>
                    <Footer>
                        {/* <React.Suspense fallback={<div>loading...</div>}>
                        <MyFooter></MyFooter>
                    </React.Suspense> */}

                        <MyFooter></MyFooter>
                    </Footer>
                </Layout>

            </div>
        </>
    )
}

export default Home