/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-09-28 13:07:37
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-11-17 19:13:27
 */
import { Layout, Row, Col,Button, Divider, Pagination, Table, Input, Spin, Form, message, Empty  } from 'antd';
import MyHeader from "components/header"
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'
import ActiveBanner from "components/active-banner"
import temp_src_exhibits from 'assets/temp/exhibits.png'
import React, { useEffect, useState } from "react";
import MyFooter from "components/footer"
import {Helmet} from "react-helmet";
import uh from "assets/uh-origin.jpg"
import metamask from "assets/metamask.png"
import metamask2 from "assets/metamask2.png"
import auction from "assets/auction.png"
import dodge from "assets/dodge.png"
import adminImg from "assets/admin.png"
import cItem from "assets/create-item.png"
import bus from "utils/bus";
import "./collections.scss"
import * as utils from "utils/utils"
const { Header, Footer, Content } = Layout

function CollectionsPage(props) {
    const location = useLocation();
    const [userInfo, setUserInfo] = useState(props.userInfo)

    const [collectionList,setCollectionList] = useState(null)

    useEffect(async () => {
        loadCollections()
    }, [])

    const loadCollections = async (currentPage = 1, pageSize = 8)=>{
        let res = await bus.loadAllCollections(currentPage, pageSize )

        console.log(3333333333333,res.data)
      
        if(res.code == 0){
            setCollectionList(res.data)
        }
    }

    const pageChange = (page, pageSize) => {
        loadCollections(page, pageSize)
    }

    // const columns = [
    //     {
    //         title: '#',
    //         render: (text, record, index) => `${index + 1}`,
    //         key: 'index',
    //     },
    //     {
    //         title: 'Title',
    //         dataIndex: 'title',
    //         key: 'title',
    //     },
    //     {
    //         title: 'Poster',
    //         dataIndex: 'poster',
    //         key: 'image',
    //         render: (text, record, index) => {
    //             return <div className="w-24 h-24 border flex items-center my-good_image" style={{ background: `url(${record.poster}) no-repeat` }}></div>
    //         }
    //     },
    //     {
    //         title: 'Creator',
    //         dataIndex: 'creator',
    //         key: 'creator',
    //     },
    //     {
    //         title: 'Created',
    //         dataIndex: 'created',
    //         key: 'created',
    //     },
    //     {
    //         title: 'Action',
    //         key: 'action',
    //         render: (text, record, index) => {
    //             return <div className="flex items-center">
    //                  <Link to={`/collection?cid=${record.cid}&address=${record.creator.address_key}`} >
    //                     <Button type="link" block>
    //                         Detail
    //                     </Button>
    //                 </Link>
    //             </div>
    //         }
    //     }
    // ];


    return (
        <>
            <Helmet>
                <title>NFT collections - Unique Hash</title>
                <meta name="description" content="Buy from the unique NFT collections in the uniquehash marketplace" />
            </Helmet>
            <div className="">
                <ActiveBanner></ActiveBanner>
                <div className="my-layout my-help">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content" style={{minHeight:'40vh'}}>
                            <Row>
                                <Col span={2} className="my-col-left"></Col>
                                <Col span={20} className="my-col-content ">

                                    <div className="content mt-6  flex flex-wrap item-collections">
 
                                        {
                                            collectionList && collectionList.datas.length>0 &&  collectionList.datas.map(v => {
                                                return <Link to={`/collection/${v.cid}/${v.creator.address_key || v.creator.address}`} >
                                                    <div className="item-collection border" > 
                                                        {/* <img  src={v.poster}/> */}
                                                        <div className="poster" style={{backgroundImage:`url(${v.poster})`}}></div>
                                                        <div className="item-info">
                                                            <span>{v.title}</span>
                                                            <div className="text-lg">
                                                                <span className="mr-8 text-gray-300">
                                                                    {v.creator.uri_name || bus.formatUid(v.creator.address_key || v.creator.address)}
                                                                </span>
                                                                <span className=" text-gray-300">
                                                                    {v.count || 0} items
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            })
                                        }
                                    </div>
                                    {
                                        collectionList &&  collectionList.datas.length>0 &&  <div className="my-8">
                                            <Pagination
                                                showSizeChanger
                                                onChange={pageChange}
                                                total={collectionList.total}
                                            />
                                        </div>
                                    }

                                    {
                                        collectionList && collectionList.datas.length ===0 &&  <div className="flex flex-col  h-96 text-center text-gray-400 items-center justify-center ">
                                            <svg t="1633692441374" class="icon" className="w-24" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1992"><path d="M958.66 288.643h0.025L511.996 65.304 66.12 288.236h-0.83V735.35l446.707 223.345L958.711 735.35V288.643h-0.05z m-446.664-169.23l335.907 168.38-127.914 64.487-337.52-167.877 129.527-64.99z m-188.06 96.672l341.852 164.713L511.972 455.3 176.095 288.643l147.84-72.558zM121.125 345.14h17.754l-17.754-0.653V316.57l362.96 181.485v390.872l-362.96-181.482V345.14z m781.74 27.275v335.031L539.906 888.928V498.056l362.962-181.485v55.844z" p-id="1993" fill="#cdcdcd"></path></svg>
                                            <span className="text-2xl">No Data</span>
                                        </div>
                                    }

                                    {
                                        !collectionList && <div className="content-loading"><Spin size="large" /></div>
                                    }
                                                                        

                                        
                                </Col>
                                <Col span={2} className="my-col-right"></Col>
                            </Row>
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>
                </div>
            </div>
        </>
    )
}

export default CollectionsPage