/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-06-13 20:52:32
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-11-17 19:08:18
 */
import temp_src_exhibits from 'assets/temp/exhibits.png'
import temp_src_teastor from 'assets/default-creator-bg.png'
import { Link, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import "./creator.scss"
import bus from 'utils/bus';
function Creator(props) {
    const [id, setId] = useState(1)
    const [followees,setFollowees] = useState([])
    const [hasFollow, setHasFollow] = useState(false)

    const detail = props.info || {}

    useEffect(async () => {
        // let temp = res.data && res.data.datas && res.data.datas.length
        
        // let pathname = location.pathname.slice(1)
        // setPathName(pathname)
        followStatus()
    }, [])

    const followStatus =async ()=>{
        let res = await bus.getFollowees(detail.address || detail.address_key)

       

        let temp = false
        if(res.data && res.data.datas && res.data.datas.length){

            setFollowees(res.data.datas)
            let userInfo = bus.getLocalStore('userInfo')
            
                if(userInfo && res.data.datas.map(v=>v.address_key).indexOf(userInfo.address_key) !=-1) {
                temp=true
            }
        }

        console.log(666,temp)

        setHasFollow(temp)
    }

    const followFn= async (e)=>{
        await bus.follow(detail.address || detail.address_key, e); 
        props.updateCreators && await props.updateCreators()
        followStatus()
    }

    const unfollowFn=async (e)=>{
        await bus.unfollow(detail.address || detail.address_key, e);
        props.updateCreators && await  props.updateCreators()
        followStatus()
    }

    return (
        <Link to={`/creator/${detail.address_key}`} >
            <div className="inline-block w-80  shadow-xl  mb-4 rounded-lg  text-black">
                <div className="flex items-center w-72 h-72 my-teaser_poster" style={{ background: `url(${detail.teaser_poster || temp_src_teastor}) no-repeat` }}>
                    {/* <img src={detail.teaser_poster || temp_src_teastor} alt="" className="w-80 rounded-none" /> */}
                </div>
                <div className=" px-6 pt-10">
                    <div className=" relative flex justify-between py-4 ">
                        <div className="flex flex-col">
                            <span className="font-bold text-xl w-40 truncate">{detail.nicename || 'Anonymous'}</span>
                            <span className="text-gray-400">@{detail.uri_name || bus.formatUid(detail.address_key)}</span>
                        </div>

                        <div className="flex flex-col">
                            <span className="font-bold text-xl">{followees.length}</span>
                            <span className="text-gray-400">Followers</span>
                        </div>

                        <div className="absolute  p-2 rounded-full bottom-20 bg-white">
                            <img src={detail.avatar || temp_src_exhibits} alt={detail.nicename } className="w-16 h-16 rounded-full" />
                        </div>
                    </div>

                    {/* <div className="pb-2 ">
                        {detail.bio}
                    </div> */}
                </div>
                {
                    !!hasFollow &&
                    <div className="text-black border border-l-0 border-r-0 border-b-0 border-t-1  flex justify-center py-4" onClick={(e) => { unfollowFn(e) }} >
                        <div className="bg-black rounded-full text-white inline-block px-5 py-1" >Unfollow</div>
                    </div>
                }
                {
                    !hasFollow &&
                    <div className="text-black border border-l-0 border-r-0 border-b-0 border-t-1  flex justify-center py-4" onClick={(e) => { followFn(e) }} >
                        <div className="bg-black rounded-full text-white inline-block px-5 py-1" >Follow</div>
                    </div>
                }

            </div>
        </Link>
    )
}

export default Creator