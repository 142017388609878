import { Form, Input, Button, Checkbox, Upload, message, Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { getAction, postAction } from "utils/http"
import Web3 from "web3";
import bus from "utils/bus"
function Rate(props) {
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };


    const [rateInfo, setRateInfo] = useState({})

    const [loading, setLoading] = useState(false)
    const tailLayout = {
        wrapperCol: { offset: 0, span: 8 },
    };

    const toBack = () => {
        props.setAdminKey("My items")
    }

    const onFinish = async (values) => {
        let { address, rate } = form.getFieldsValue()

        rate = rate * 1;

        // 不是合法的地址
        if (address && !Web3.utils.isAddress(address)) {
            message.error('please provide a valid beneficiary address  ');
            return
        }

        // 必须大于0 
        if (rate && rate <= 0) {
            message.error('rate must be greater than 0');
            return
        }

        // 必须是数字
        if (rate && !Number.isInteger(rate)) {
            message.error('rate must be a integer');
            return
        }

        // 修改
        if (address) {

            await setLoading(true);

            try {
                await bus.updateFeeReceiver(address);
                await setLoading(false);
            } catch (err) {
                message.error(err.toString());
            }

        }


        if (rate) {
            await setLoading(true);
            try {
                await bus.updateFeeRate(rate);
                await setLoading(false);
            } catch (e) {
                message.error(e.toString());
            }
        }

    };


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const [form] = Form.useForm();

    useEffect(async () => {
        let res = await bus.viewFeeReceiverAndFate()
        let temp = Object.values(res)

        console.log("受益人信息", temp)

        setRateInfo({
            address: temp[0],
            rate: temp[1]
        })
    }, [])

    return (
        <>
            <div>
                <div className="flex justify-between pb-6">
                    <span className="font-bold">Rate information</span>
                    {/* <div className="font-bold bg-black text-white rounded-full px-3 py-1 cursor-pointer" onClick={() => toBack()}>Back</div> 
                    */}
                </div>
                <div className="border py-6 px-6">
                    <Form
                        form={form}
                        {...layout}
                        layout="vertical"
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Beneficiary Address"
                            name="address"
                        >
                            {
                                rateInfo.address && <Input defaultValue={rateInfo.address} disabled={props.address_key != "0x41FF30F24849526fA44bAcffc17389A2be76D56D"} />
                            }
                            {
                                !rateInfo.address && <Input />
                            }

                        </Form.Item>

                        <Form.Item
                            label="Rate"
                            name="rate"
                        >
                            <div className="flex items-center">
                                {
                                    rateInfo.rate && <Input defaultValue={rateInfo.rate} type="number" disabled={props.address_key != "0x41FF30F24849526fA44bAcffc17389A2be76D56D"} />
                                }
                                {
                                    !rateInfo.rate && <Input type="number" />
                                }
                                &emsp;
                                <div className="flex items-center"> /10000 </div>
                            </div>
                        </Form.Item>

                        {
                            props.address_key == "0x41FF30F24849526fA44bAcffc17389A2be76D56D" && <Form.Item {...tailLayout} >
                                <div className="mr-8 inline-block">
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </div>
                                <div className=" inline-block">
                                    <Button type="" onClick={() => { toBack() }}>
                                        Quit
                                    </Button>
                                </div>
                            </Form.Item>
                        }

                    </Form>
                </div>
            </div >

            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>
        </>
    )
}

export default Rate