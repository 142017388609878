import temp_src_exhibits from 'assets/temp/exhibits.png'
import "./exhibits.scss"
import bus from "utils/bus"
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react';
import {PlayCircleOutlined,PauseOutlined } from "@ant-design/icons"
import MusicLogo from "assets/Music.png"
import { Layout, Row, Col, Divider, Modal, Spin, Form, Input, message, Button } from 'antd';


function Exhibits(props) {
    const [timerClock, setTimerClock] = useState(-1)
    const [detail, setDetail] = useState(props.detail)
    const [eth, setEth] = useState(0)
    const [isModalVisible, setModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    const clockTimer = () => {
        if (timerClock <= 0) {
            return
        }
        setTimeout(async () => {
            await setTimerClock(timerClock - 1);
        }, 1000);
    }

    const [form] = Form.useForm();

    const [audioPlay,setAutioPlay]=useState(false)

    const placeABid = () => {
        
        if(!bus.hasMetamask()){
            return
        }

        setModalVisible(true)
    }

    const onFinish = async () => {
        let data = form.getFieldsValue()

        let maxPrice = null
        if (detail.highest_auction) {
            maxPrice = detail.highest_auction.price
        } else {
            maxPrice = detail.start_price
        }
        if (((data.price * 1) < (maxPrice * 1)) && !detail.highest_auction) {
            message.warning(`Price is too low ( >= ${maxPrice} ${bus.getNetUnit(detail.good.contract_address)})`)
            return
        } else if (((data.price * 1) <= (maxPrice * 1)) && detail.highest_auction) {
            message.warning(`Price is too low ( > ${maxPrice} ${bus.getNetUnit(detail.good.contract_address)})`)
            return
        }
        setLoading(true);
        setModalVisible(false)
        let payRes = null
        try {
            payRes = await bus.payThis(detail, data.price)
        } catch {
            setLoading(false);
        }
        setLoading(false);
        if (payRes && payRes.code == 0) {
            window.location.reload()
        }
    }

    const playAudio = (src,e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src=src
        audioRef.play()
        setAutioPlay(true)
     }
 
     const pauseAudio=(e)=>{
         e.stopPropagation();
         e.preventDefault()
         let audioRef = document.getElementById("audioRef")
         audioRef.pause()
         setAutioPlay(false)
     }

    useEffect(async () => {
        if (timerClock == -1) {
            let eth_temp = await bus.eth(detail.good.contract_address)
            setEth(eth_temp)
            await setTimerClock(Math.floor(props.detail.second_duration))
        }
        clockTimer()
    }, [timerClock])
    return (
        <>
            <audio id="audioRef" />
            <Row>
                <Col span={2} className="my-col-left"></Col>
                <Col span={22} className="my-col-content">
                    <div className=" mt-20 flex my-exhibit">
                        <div className=" flex-shrink-0 rounded-xl my-exhibits-picture border flex items-center justify-center">
                            
                            {
                                 detail.good && bus.getFileType(detail.good.image) == 'img' &&<img src={detail.good.image} alt="" />
                            }
                            {
                                detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="w-72 h-72  flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                {
                                    audioPlay && <PauseOutlined onClick={(e)=>pauseAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                                }
                                {
                                    !audioPlay && <PlayCircleOutlined onClick={(e)=>playAudio(detail.good.image,e)} style={{fontSize:'68px',opacity:0.4}}/>
                                }
                                </div>
                            }
                            {
                                detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="  flex items-center exhibit-video"><video controls loop muted autoplay="autoplay"  src={detail.good.image} /> </div>
                            }
                        </div>
                        <div className="flex flex-col-reverse ml-10  w-full my-exhibit-info">
                            <div className="mt-6 inline-block">
                                {
                                    timerClock > 0 && <div className="cursor-pointer mr-4 rounded-full bg-black text-white inline-block border border-soli border-black py-1 px-6" onClick={() => placeABid()}>Place Bid</div>
                                }

                                <Link to={`/detail?aid=${detail.aid}`} >
                                    <div className="cursor-pointer rounded-full text-black inline-block border-gray-600 border-solid border py-1 px-6" >View Artwork</div>
                                </Link>
                            </div>
                            <div className="inline-block bg-black rounded-tl-xl rounded-bl-xl h-28 w-full text-white flex items-center pl-12 my-auction-info ">
                                <div className=" inline-block w-36 h-24 flex-col flex justify-center">
                                    <span className="text-gray-200">Current Price</span>
                                    <span className="font-bold text-xl">{detail.highest_auction.price}  {bus.getNetUnit(detail.good.contract_address)} </span>
                                    <span className="text-gray-400"> ${(eth * detail.highest_auction.price).toFixed(2)}</span>
                                </div>
                                <div className="w-44  flex-col flex h-24 justify-center">
                                    <span className="text-gray-200">Auction ending in</span>
                                    <div className="flex justify-between text-left">
                                        <div className=" w-full font-bold text-xl  mr-2">{bus.sToHMS(timerClock)[0]}</div>
                                        <div className="mr-3 w-full font-bold text-xl">{bus.sToHMS(timerClock)[1]}</div>
                                        <div className=" w-full font-bold text-xl">{bus.sToHMS(timerClock)[2]}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className=" w-full text-gray-400  mr-2">Hours</span>
                                        <span className="mr-3 w-full text-gray-400">Minutes</span>
                                        <span className=" w-full text-gray-400">Seconds</span>
                                    </div>
                                </div>
                            </div>
                            <h1 className="text-3xl font-bold">{detail.good.title}</h1>
                            <div className="rounded-full my-user flex mb-4 flex-shrink-0 py-1 justify-center inline-block w-40">
                                <img className="rounded-full mr-1" src={detail.user.avatar} width="22" alt="" />
                                <span className="font-semibold text-sm">@{detail.user.uri_name || bus.formatUid(detail.user.address)}</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal title={'The current item was created in '+bus.getNameOfNetwork(detail && detail.good.network)}  visible={isModalVisible} footer={null} onCancel={() => setModalVisible(false)}>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Bid price"
                        name="price"
                        rules={[{ required: true, message: 'Please input your price!' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <Input type="number" title="Enter a valid value. The two closest valid values are 0 and 1" />
                            <span className="font-bold flex-shrink-0 h-6">&emsp;  {bus.getNetUnit(detail.good.contract_address)} </span>
                        </div>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <div className="flex ">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                            <div>
                                <Button type="" onClick={() => setModalVisible(false)}>
                                    Quit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>

                </Form>
            </Modal>
            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>,
        </>
    )
}

export default Exhibits