
import { Table, Tag, Button, Modal, Spin, message, Form, Input, InputNumber,Radio  } from "antd"
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { getAction, postAction } from "utils/http"
import bus from "utils/bus"
import "./sell-items.scss"
import temp_src_exhibits from 'assets/temp/exhibits.png'
import MusicLogo from "assets/Music.png"
import { PlayCircleOutlined, PauseOutlined ,LoadingOutlined } from "@ant-design/icons"

const antIcon = <LoadingOutlined style={{ fontSize: 18,color:'white' ,'margin-left':'6px'}} spin />;

function SellItems(props) {

    const [dataSource, setDataSource] = useState({ datas: props.sellInfo, total: 0 })
    const [dataList, setDataList] = useState(props.sellInfo.versions)
    const [handleLoading,setHandleLoading] = useState(false)
    const [typeChoose,setTypeChoose] = useState("multi")
    const [currentPage, setCurrentPage] = useState(1)
    const [curItem, setCurItem] = useState(null)
    const [isModalVisibleFixedPrice, setModalVisibleFixedPrice] = useState(false)

    const [loading, setLoading] = useState(false)
    const [record, setRecord] = useState({})
    const [recordFixedPrice, setRecordFixedPrice] = useState({})

    const [pageSize, setPageSize] = useState(8)
    const [playIndex, setPlayIndex] = useState(null)
    const [audioPlay, setAutioPlay] = useState(false)
    const [audioPlayD, setAutioPlayD] = useState(false)

    const toCreateItem = () => {
        console.log(props)
        let userInfo = bus.getLocalStore("userInfo")
        if (!userInfo.status) {
            message.warning(
                "You cannot create items. Please contact the administrator"
            );
            return;
        }

        props.setAdminKey("Create item")

    }

    const playAudio = (src, index, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src = src
        audioRef.play()
        setAutioPlay(true)
        setPlayIndex(index)
    }

    //  const back = (record)=>{
    //     props.setAdminKey("sell item")
    //  }

    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };


    const pauseAudio = (index, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlay(false)
        setPlayIndex(null)
    }

    const playAudioD = (src, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src = src
        audioRef.play()
        setAutioPlayD(true)

    }

    const onChange = (e)=>{
        setTypeChoose(e.target.value)
    }

    const onFinishFixedPrice = async () => {

        // setHandleLoading(true)

        if(handleLoading || loading) return

        let data = form.getFieldsValue()
        data.tokenId = dataSource.datas.gid
        data.accountAddress = dataSource.datas.user.address_pubkey

        typeChoose == "multi" && setLoading(true);
        typeChoose == "fixed" && setHandleLoading(true);

        try {
            let res = await bus.startFixPrice(data,typeChoose)
            if (res) {
                message.success("Fixed Price has been successfully initiated");
                props.setAdminKey("My items")

            } else {
                message.warning(res.message);
            }
        } catch {
            setLoading(false);
            setHandleLoading(false);
        }
        setLoading(false);
        setHandleLoading(false);
        form.setFieldsValue({})
    }

    const pauseAudioD = (e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlayD(false)
    }

    const setPrice = (item) => {
        setCurItem(item)
        setModalVisibleFixedPrice(true)
    }

    const handlePrice = async (values) => {
        // console.log(111, curItem.owner.address_key, dataSource.datas.gid, values.price * 1, curItem.version)
        // let res = await bus.sellTokenSerial(curItem.owner.address_key, dataSource.datas.gid, values.price * 1, curItem.version)
        // if (res.code == 0) {
        //     props.setAdminKey('My items')
        // }
    }

    useEffect(() => {
        // loadData()

    }, [])

    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: 'Item name',
            key: `title`,
            render: (text, item, index) => {
                return <>
                    {dataSource.datas.title}
                </>
            }
        },
        {
            title: 'Item',
            key: 'image',
            render: (text, item, index) => {
                if (bus.getFileType(dataSource.datas.image) == 'img') {
                    return <div className="w-24 h-24 border flex items-center my-good_image" style={{ background: `url(${dataSource.datas.image}) no-repeat` }}></div>
                }
                if (bus.getFileType(dataSource.datas.image) == 'audio') {
                    return <div className="w-36 h-36 border flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                        {
                            audioPlay && playIndex === index && <PauseOutlined onClick={(e) => pauseAudio(index, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                        }
                        {
                            (playIndex !== index || playIndex === null) && <PlayCircleOutlined onClick={(e) => playAudio(dataSource.datas.image, index, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                        }
                    </div>
                }

                if (bus.getFileType(dataSource.datas.image) == 'video') {
                    return <div className="w-36 h-36 border flex items-center my-good_image"><video controls muted autoplay="autoplay" src={dataSource.datas.image} /> </div>
                }
            }
        },
        {
            title: 'Edition',
            key: `versions`,
            render: (text, item, index) => {
                return <div>
                    {index + 1} / {dataList.length}
                </div>
            }
        },
        {
            title: 'Creation time',
            key: 'created',
            render: created => {
                return bus.formatDate(dataSource.datas.created)
            }
        },
        // {
        //     title: 'Network',
        //     dataIndex: 'network',
        //     key: 'network',
        //     render: (text, item, index) => bus.getNameOfNetwork(item.good.network) 
        // },
        {
            title: 'Item link',
            key: 'link',
            render: () => {
                return dataSource.datas.external_link
            }
        },
        {
            title: 'Item condition',
            dataIndex: 'auction',
            key: 'condition',
            render: auction => {
                {
                    if (!auction) {
                        return <Tag color="magenta">No auction</Tag>
                    } else {
                        switch (auction.status) {
                            case 0:
                                return <Tag color="cyan">Reserve not met</Tag>
                            case 1:
                                return <Tag color="geekblue">Sold</Tag>
                            case 2:
                                return <Tag color="volcano">Failed</Tag>
                            case 3:
                                if (auction.second_duration > 0) {
                                    return <Tag color="green">Live Auction</Tag>
                                } else {
                                    return <Tag color="geekblue">Sold</Tag>
                                }
                        }
                    }
                }
            }
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'description',
        },
        // {
        //     title: 'Action',
        //     render: (text, item, index) => {
        //         return <>
        //             <div className="flex">
        //                 {
        //                     <div className=" mr-2 w-24 h-6 bg-gray-900 text-white rounded-full text-center cursor-pointer" onClick={() => setPrice(item)}>Set Price</div>
        //                 }
        //             </div>
        //         </>
        //     }
        // },

    ];


    return (
        <>
            <audio id="audioRef" />
            <div>
                {
                    !dataList && <div className="flex justify-between py-2" >
                        <span className="font-bold"><h1 className="text-3xl">{dataSource.datas.title}</h1></span>
                    </div>
                }

                {
                    dataList && <Table dataSource={dataList} columns={columns} pagination={false} />
                }
                {
                    !dataList && <div className="mt-4 ">

                        {/* <p>
                            <h1 className="text-3xl">{dataSource.datas.title}</h1>
                        </p> */}
                        <div className="flex items-center">
                            <div className="mr-12 h-64 w-64 border flex items-center">
                                {
                                    bus.getFileType(dataSource.datas.image) == "img" && <img alt="" src={dataSource.datas.image} className="w-64" />
                                }
                                {
                                    bus.getFileType(dataSource.datas.image) == "audio" && <div className="w-64 h-64 border flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                        {
                                            audioPlayD && <PauseOutlined onClick={(e) => pauseAudioD(e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                        {
                                            !audioPlayD && <PlayCircleOutlined onClick={(e) => playAudioD(dataSource.datas.image, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                        }
                                    </div>
                                }
                                {
                                    bus.getFileType(dataSource.datas.image) == "video" && <video controls muted autoplay="autoplay" loop src={dataSource.datas.image} />
                                }
                                {/* <img alt=""  src={temp_src_exhibits} className="h-64" /> */}
                            </div>
                            <Form
                                {...layout}
                                name="basic"
                                form={form}
                                initialValues={{ remember: true }}
                                onFinish={onFinishFixedPrice}
                            >
                                <Form.Item
                                    label="Fixed Price"
                                    name="price"
                                    form={form}
                                    rules={[{ required: true, message: 'Please input your price!' }]}
                                >
                                    <div className="flex items-center">
                                        &emsp;
                                        <Input type="number" />
                                        <span className="font-bold flex-shrink-0 h-6">&emsp; {bus.getNetUnit(record.contract_address)}</span>
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    label="Choose"
                                    name="type"
                                    form={form}
                                    rules={[{ required: true, message: 'Please choose type!' }]}
                                >
                                    <div className="flex items-center">
                                        &emsp;
                                        <Radio.Group onChange={onChange} value={typeChoose}>
                                            <div className="">
                                            <Radio value={"fixed"}>Fixed version</Radio>
                                            <Radio value={"multi"}>Multi version</Radio>
                                            </div>
                                        </Radio.Group>
                                        <span className="font-bold flex-shrink-0 h-6">&emsp;</span>
                                    </div>
                                </Form.Item>

                                {
                                    typeChoose == "multi" && <Form.Item
                                        label="Edition"
                                        name="total"
                                        form={form}
                                        rules={[{ required: true, message: 'Please input your Edition!' }]}
                                    >
                                        <div className="flex items-center">
                                            &emsp;
                                            <Input type="number" />
                                            <span className="font-bold flex-shrink-0 h-6">&emsp;</span>
                                        </div>
                                    </Form.Item>
                                }


                                <Form.Item
                                    label="Rate"
                                    name="dividend"
                                    form={form}
                                    rules={[{ required: true, message: 'Please input your rate!' }]}
                                >
                                    <div className="flex items-center">
                                        &emsp;
                                        <Input type="number" />
                                        <span className="font-bold flex-shrink-0 h-6">&emsp;</span>
                                    </div>
                                </Form.Item>

                                {/* <Form.Item {...tailLayout}>
                                    <div className="flex ">
                                        <div className="mr-4">
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </div>
                                        <div>
                                            <Button type="" onClick={() => setModalVisibleFixedPrice(false)}>
                                                Quit
                                            </Button>
                                        </div>
                                    </div>
                                </Form.Item> */}

                            </Form>
                        </div>
                        <div>
                            <div className="flex mt-6">
                                <div className="mr-4" onClick={() => onFinishFixedPrice()}>
                                    <Button type="primary">
                                        <div className="flex items-center">
                                            Submit
                                            {
                                                handleLoading && <Spin  indicator={antIcon} />
                                            }
                                            
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <Modal title="Set Price" footer={null} visible={isModalVisibleFixedPrice} onCancel={() => setModalVisibleFixedPrice(false)}>
                <Form
                    name="Set Price"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={handlePrice}
                >
                    <Form.Item
                        label="Price"
                        name="price"
                        rules={[{ required: true, message: 'Please input price!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <div className="flex justify-end">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>
        </>
    )
}

export default SellItems