
import logo from 'assets/logo.png'
import { Link, useLocation, useHistory } from 'react-router-dom'
import "./header.scss"
import { Input, Drawer, Row, Col, Menu, message, Select , Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { SearchOutlined, MenuUnfoldOutlined, WalletOutlined } from '@ant-design/icons';
import bus from "utils/bus"
import temp_src_exhibits from 'assets/temp/exhibits.png'


function MyHeader(props) {
    const [visible, setVisible] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [searchType,setSearchType] = useState("items")

    const [supportNet, setSupportNet] = useState(bus.ifAllow())

    const [keyword, setKeyword] = useState("");
    const [pathName, setPathName] = useState('home');
    // const [refresh, setRefresh] = useState(false)
    const location = useLocation();
    let history = useHistory()
    useEffect(() => {
        // let userInfo = bus.getLocalStore("userInfo")

        let userInfo1 = bus.getLocalStore("userInfo")

        console.log(99999,supportNet, userInfo1)

        userInfo1 && setUserInfo(userInfo1)
        let pathname = location.pathname.slice(1)
        setPathName(pathname)
    }, [])

    async function redirect() {
        if (!supportNet) {
            return
        }

        let res = await bus.connectWallet()
        console.log("header 中调用", res)
        if (res) {
            history.push("/admin")
        } else {
            bus.removeLocalStore("userToken")
            bus.removeLocalStore("userInfo")
            window.location.reload()
        }
    }

    const chooseSearchType = (e)=>{
        setSearchType(e)
    }

    function onKeyDownchange(e) {
        if (e.keyCode == 13) {
            keywordSearch()
        }
        setTimeout(async () => {
            await setKeyword(e.target.defaultValue)
        }, 0);
    }

    async function keywordSearch() {
        console.log(keyword)
        // let res =await bus.searchAuctions(keyword)
        let onode = document.getElementById("oLink")
        let cnode = document.getElementById("cLink")

        if(searchType == "items"){
            onode.click()
            setTimeout(() => {
                if (props.searchList) {
                    props.searchList(keyword)
                }
            }, 0);
        }else {
            cnode.click()

            setTimeout(() => {
                if (props.usersSearch) {
                    props.usersSearch(keyword)
                }
            }, 0);
        }
    }

    // 目前通过物理像素来判断
    function ifPhone() {
        return window.screen.width < 600
    }

    async function connectWallet() {

        let res = null
        try {
            res = await bus.loginWallet()
        } catch (e) {
            // if(!ifPhone()){
            //     const modal = Modal.info();
            //     modal.update({
            //     content: <div>
            //         <p>Please install the MetaMask extension in order to use Uniquehash</p>
            //         <a href="https://metamask.io/" style={{padding:'10px 20px',background:'black',color:'white',borderRadius:'5px',display:'inline-block',marginTop:'20px'}}>Go to MetaMask's website</a>
            //     </div>
            //     });
            // }

        }

        if (res === null) {
            // message.warning("Please connect to the Ethereum mainnet")
            message.warning("network is not supported right now.")
        }
        // doRefresh()
    }

    return (
        <>
            <Link id="oLink" to={`/search?keyword=${keyword}`} ></Link>
            <Link id="cLink" to={`/creators?keyword=${keyword}`} ></Link>
            <div className="my-header">

                <Row>
                    <Col span={2}></Col>
                    <Col span={20}>
                        <div className="flex mt-4 justify-between">
                            <div className=" flex">
                                <Link to={`/`} >
                                    <img src={logo} alt="" width="40px" height="40px" className="flex-shrink-0" />
                                </Link>
                                <div className="my-menu flex items-center ml-10  mr-12">
                                    <ul className="flex h-6  items-center text-sm font-bold">
                                        <Link to="/home" >
                                            <li className={`mr-4 py-2 px-6 rounded-full text-black ${pathName == 'home' ? 'my-active-item' : ''}`}>
                                                Home
                                            </li>
                                        </Link>
                                        <Link to="/artwork">
                                            <li className={`mr-4 py-2 px-6 rounded-full text-black  ${pathName == 'artwork' ? 'my-active-item' : ''}`}>
                                                Artwork
                                            </li>
                                        </Link>
                                        <Link to="/creators">
                                            <li className={`mr-4 py-2 px-6 rounded-full text-black  ${pathName == 'creators' ? 'my-active-item' : ''}`}>
                                                Creators
                                            </li>
                                        </Link>
                                        <Link to="/collections">
                                            <li className={`mr-4 py-2 px-6 rounded-full text-black  ${pathName == 'collections' ? 'my-active-item' : ''}`}>
                                                Collections
                                            </li>
                                        </Link>
                                    </ul>
                                </div>
                                <Input className="my-input" placeholder="Search" onKeyDown={e => onKeyDownchange(e)} suffix={<SearchOutlined onClick={(e) => keywordSearch(e)} />} ></Input>
                                <Select className="my-search-items" onChange={chooseSearchType} defaultValue={searchType} style={{ width: 150 }} bordered={false}>
                                    <Select.Option value="creators">Search Creators</Select.Option>
                                    <Select.Option value="items">Search Items</Select.Option>
                                </Select>
                            </div>
                            {
                                userInfo && supportNet && <div onClick={() => redirect()} className="my-connect-btn w-36 text-center h-10 rounded-full leading-9 flex items-center" >
                                    <img alt="" src={userInfo.avatar || temp_src_exhibits} className="w-6 h-6 rounded-full ml-2" />
                                    {/* <span className="text-sm ml-2">{userInfo.nicename || userInfo.nanoUid}</span> */}
                                    <div className="flex flex-col">
                                        <div className="text-sm ml-2 w-20  truncate">{userInfo.nicename || userInfo.nanoUid}</div>
                                        {
                                            userInfo.nicename && <div className="text-sm ml-2">{userInfo.nanoUid}</div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                (!userInfo || !supportNet) && <div className=" w-36 text-center rounded-full leading-9 my-connect-btn" >
                                    <div className="my-connect-big-screen cursor-pointer" onClick={connectWallet}>
                                        Wallet Connect
                                    </div>
                                    <div className="my-connect-mini-screen" onClick={connectWallet}>
                                        <span className="mr-2">
                                            Connect
                                        </span>
                                        <WalletOutlined className="my-wallet-icon" />
                                    </div>
                                </div>
                            }

                            <MenuUnfoldOutlined className="text-3xl my-icon-menu" onClick={() => {
                                setVisible(!visible)
                            }} />
                            <Drawer
                                title="Menu"
                                placement="top"
                                closable={true}
                                onClose={() => setVisible(false)}
                                visible={visible}
                            >
                                <div>
                                    <Menu>
                                        <Link to="/home" >
                                            <Menu.Item key="9">
                                                <span className="text-black">Home</span>
                                            </Menu.Item>
                                        </Link>
                                        <Link to="/artwork" >
                                            <Menu.Item key="10">
                                                <span className="text-black">Artwork</span>
                                            </Menu.Item>
                                        </Link>
                                        <Link to="/creators" >
                                            <Menu.Item key="11">
                                                <span className="text-black">Creators</span>
                                            </Menu.Item>
                                        </Link>
                                        <Link to="/collections" >
                                            <Menu.Item key="11">
                                                <span className="text-black">Collections</span>
                                            </Menu.Item>
                                        </Link>
                                    </Menu>
                                </div>
                            </Drawer>
                        </div>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </div>
        </>
    )
}


export default MyHeader