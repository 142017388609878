import React, { useState, useEffect } from "react";
import "./artwork.scss"
import { Layout, Row, Col, Divider, Pagination } from 'antd';
import MyHeader from "components/header"
// import Exhibits from "components/exhibits";
import {Helmet} from "react-helmet";
// import NftInfo  from "components/nft-info";
import Auction from "components/auction"
import Featured from "components/featured"
// import Creator from "components/creator"
import MyFooter from "components/footer"
import ActiveBanner from "components/active-banner"
import * as utils from "utils/utils"
import { Link, useLocation } from 'react-router-dom'
import bus from "utils/bus";
import FixedItem from "components/fixedItem"
const { Header, Footer, Content } = Layout;

function Artwork(props) {

    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [activeMenum, setActiveMenu] = useState("Live Auction")
    const location = useLocation();
    const [dataSource, setDataSource] = useState({ datas: [], total: 0 })

    const initDataSource = async (currentPage = 1, pageSize = 8, temp) => {
        let res = {}
        let tempActiveMenum = temp ? temp : activeMenum
        switch (tempActiveMenum) {
            case "Live Auction":
                res = await bus.onLiveGoods(currentPage, pageSize)
                break;
            case "Reserve Not Met":
                res = await bus.notReserveGoods(currentPage, pageSize)
                break;
            case "Sold":
                res = await bus.solidGoods(currentPage, pageSize)
                break;
            case "Fixed Price":
                res = await bus.getFixedPriceItems(currentPage, pageSize)
                break;
        }

        // console.log(55555, res)

        if (res.code == 0) {
            setDataSource({
                datas: res.data.datas,
                total: res.data.total
            })
        }


    }

    const changeActive = (active) => {
        setActiveMenu(active)
        initDataSource(1, 8, active)
    }

    const pageChange = (page, pageSize) => {
        console.log("*****", page, pageSize)
        initDataSource(page, pageSize)
    }
    useEffect(() => {
        // setUserInfo(props.userInfo)

        console.log("#1234567", location)
        let params = location.pathname.split("/")
        
        // let res = utils.parseParams(location.search.split("?")[1])
        if (params[2]) {
            if (params[2] == "Live") {
                setActiveMenu("Live Auction")
                initDataSource(1, 8, "Live Auction")
            } else if (params[2] == "Fixed") {
                setActiveMenu("Fixed Price")
                initDataSource(1, 8, "Fixed Price")
            } else if (params[2] == "Featured") {
                setActiveMenu("Sold")
                initDataSource(1, 8, "Sold")
            } 
            // else {
            //     setActiveMenu("Reserve Not Met")
            //     initDataSource(1, 8, "Reserve Not Met")
            // }

        } else {
            initDataSource()
        }

    }, [])

    return (
        <>
            <Helmet>
                <title>NFT artwork - Unique Hash</title>
                <meta name="description" content="NFT artwork for sale in the uniquehash marketplace" />
            </Helmet>
            <ActiveBanner></ActiveBanner>
            <div className="my-layout">
                
                <Layout>
                    <Header className="shadow-lg">
                        <MyHeader userInfo={userInfo}></MyHeader>
                    </Header>
                    <Content className="my-content">
                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows">
                                    <div className="my-mini-title w-full">
                                        <div className="pt-16">
                                            <div className={`rounded-full font-bold px-4 py-1 inline-block cursor-pointer ${activeMenum == 'Live Auction' ? 'bg-black text-white' : 'text-black'}`} onClick={() => changeActive('Live Auction')}>Live Auction</div>
                                            {/* <div className={`rounded-full font-bold px-4 py-1 inline-block cursor-pointer ${activeMenum == 'Reserve Not Met' ? 'bg-black text-white' : 'text-black'}`} onClick={() => changeActive('Reserve Not Met')}>Reserve Not Met</div> */}
                                            <div className={`rounded-full font-bold px-4 py-1 inline-block cursor-pointer ${activeMenum == 'Sold' ? 'bg-black text-white' : 'text-black'}`} onClick={() => changeActive('Sold')}>Sold</div>
                                            <div className={`rounded-full font-bold px-4 py-1 inline-block cursor-pointer ${activeMenum == 'Fixed Price' ? 'bg-black text-white' : 'text-black'}`} onClick={() => changeActive('Fixed Price')}>Fixed Price</div>
                                        </div>
                                        <Divider />
                                    </div>
                                    <div className="flex flex-wrap  my-live-auctions">
                                        {
                                            dataSource.datas.map(v => {
                                                return <div className="mr-2">
                                                    {
                                                        !v.versions && v.highest_bidding && <Auction info={v}></Auction>
                                                    }
                                                    {
                                                        !v.versions && !v.highest_bidding && <Featured info={v}></Featured>
                                                    }
                                                    {
                                                        v.versions && <FixedItem info={v}></FixedItem>
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="px-6 flex justify-center py-10">
                                        <Pagination
                                            total={dataSource.total}
                                            showQuickJumper
                                            onChange={pageChange}
                                            pageSize={8}
                                            showTotal={total => `Total ${total} items`}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>
                    </Content>
                    <Footer>
                        <MyFooter></MyFooter>
                    </Footer>
                </Layout>

            </div>
        </>
    )
}

export default Artwork