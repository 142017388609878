import { Form, Input, Button, Checkbox, Upload, message } from 'antd';
import React, { useState, useEffect } from 'react';
import bus from "utils/bus"
function Manually(props) {
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };
  
    const tailLayout = {
        wrapperCol: { offset: 0, span: 8 },
    };

    const onFinish = async (values) => {
        let data = form.getFieldsValue()

        console.log("data",data)

        let res = await bus.manuallyAuction(data);
        if(res.code ==0){
            message.success(res.message);
        }else{
            message.warning(res.message);
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const [form] = Form.useForm();

    useEffect(async () => {

    }, [])

    return (
        <div>
            <div className="flex justify-between pb-6">
                <span className="font-bold">Manually open an auction</span>
            </div>
            <div className="border py-6 px-6">
            <span style={{color:'rgba(0,0,0,0.3)'}}>You can find this information on etherscan.io</span>
                <Form
                    form={form}
                    {...layout}
                    layout="vertical"
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >

                    <Form.Item
                        label="Token"
                        name="token"
                    >
                        <Input /> 
                        
                    </Form.Item>

                    <Form.Item
                        label="Start Price"
                        name="start_price"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Hash"
                        name="tx"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Contract Address"
                        name="contract_address"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="User Address"
                        name="user_address"
                    >
                        <Input />
                    </Form.Item>



                    <Form.Item {...tailLayout}>
                        <div className="mr-8 inline-block">
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div >
    )
}

export default Manually