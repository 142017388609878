/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-07-27 21:43:51
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-10-27 20:04:37
 */
import React, { useState, useEffect } from 'react';
import "./active-banner.scss";
import { Link, useLocation } from 'react-router-dom'
import banner1 from "../../assets/banner-1.png"
import banner2 from "../../assets/banner-2.png"
import banner3 from "../../assets/banner-3.png"
// import banner4 from "../../assets/banner-4.png"
function ActiveBanner() {
    useEffect(async () => {
    }, [])
    return (
        <>
            <Link to={`/active`}>
                <div className="active-banner flex items-center justify-center">
                    <img src={banner1}/>
                    <img src={banner2}/>
                    <img src={banner3}/>
                    {/* <img src={banner4}/> */}
                </div>
            </Link>
        </>
    )
}

export default ActiveBanner