
import { Table, Spin, Tag,Upload, message, Button, Form, Modal, Input, Progress } from "antd"
import React, { useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom'
import bus from "utils/bus";

import MusicLogo from "assets/Music.png"
import { LoadingOutlined,PlusOutlined , PlayCircleOutlined, PauseOutlined } from "@ant-design/icons"

function MyCollection(props) {
    const [loading, setLoading] = useState(false)
    const [selectionType, setSelectionType] = useState('checkbox');
    const [userinfo, setUserInfo] = useState({})
    const [playIndex, setPlayIndex] = useState(null)
    const [headers, setHeaders] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [imageUrl, setPoster] = useState("")
    const [imageUrlUp, setPosterUp] = useState("")
    const [upPosterPro, setUpProgress] = useState(0)
    const [isModalVisible, setModalVisible] = useState(false)
    const upPro = {
        strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
        },
        strokeWidth: 3,
        format: percent => `${parseFloat(percent.toFixed(2))}%`,
    }
    const [dataSource, setDataSource] = useState({
        total: 0,
        datas: []
    })
    const initCollections = async (currentPage = 1, pageSize = 8) => {
        let resItems = await bus.initCollections(currentPage, pageSize)
        if (resItems.code == 0) {
            await setDataSource({
                datas: resItems.data
            })
        }
    }
    const toCreateCollection = async ()=>{
        setModalVisible(true)
    }
    useEffect(async () => {
        initCollections()
        let userinfo = bus.getLocalStore("userInfo")
        await setUserInfo(userinfo)
        let headers = await bus.getAuthorization();
        setHeaders(headers)
        // let userinfo = bus.getLocalStore("userInfo")
        // await setUserInfo(userinfo)
    }, [])

    /*
    
    STATUS_BIDDING_GOING   = 0 // 竞拍进行中
    STATUS_BIDDING_SUCCESS = 1 // 成功竞拍
    STATUS_BIDDING_FAILED  = 2 // 竞拍已退款

    */

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const columns = [
        {
            title: '#',
            render: (text, record, index) => `${index + 1}`,
            key: 'index',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            render: (text, record, index) => {
                return <div>
                     <Link to={`/collection?cid=${record.cid}&address=${record.creator.address_key}`} >
                        <Button type="link">{record.title}</Button>
                    </Link>
                </div>
            }
        },
        {
            title: 'Poster',
            dataIndex: 'poster',
            key: 'image',
            render: (text, record, index) => {
                return <div className="w-24 h-24 border flex items-center my-good_image22" style={{ background: `url(${record.poster}) no-repeat` }}></div>
            }
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (text, record, index) => {
        //         return <div>
        //              <Link to={`/collection?cid=${record.cid}&address=${record.creator.address_key}`} >
        //                 <Button type="link">View</Button>
        //             </Link>
        //         </div>
        //     }
        // }
    ];

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    const [form] = Form.useForm();

    const onFinish = async ()=>{
        let formData = form.getFieldsValue()
        // console.log(formData, imageUrlUp , userinfo)
        formData.poster = imageUrlUp
        formData.creator = userinfo.address_key
        let res = await bus.createCollection(formData)
        // console.log(res)
        if(res.code == 0){
            message.success("create success")
            setModalVisible(false)
            initCollections()
        }else{
            message.warning(res.message)
            setModalVisible(false)
        }

    }

    const handleUpChange = (info)=>{
        const event = info.event
        if (event) { // 一定要加判断，不然会报错
            let percent = Math.floor((event.loaded / event.total) * 100)
            // this.setState({ percent: percent })
            //  console.log(percent) // percent就是进度条的数值
            setUpProgress(percent)
        }
        if (!info.file.response) {
            return null;
        }

        if (info.file.response && info.file.response.code == 0) {
            setPoster(info.file.response.data.url)
            setPosterUp(info.file.response.data.uri)
        }
    }

    return (
        <>
            <div className=" border-w-4 overflow ">
                <div className="flex justify-between py-2" >
                    <span className="font-bold">My Collection</span>
                    <div className="bg-black rounded-full px-3 py-1 text-white font-bold  cursor-pointer" onClick={() => toCreateCollection()}>Create Collection</div>
                </div>
                <Table dataSource={dataSource.datas} columns={columns}  pagination={null}/>
            </div>
            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>

            <Modal title="Create Collection" visible={isModalVisible} footer={null} onCancel={() => setModalVisible(false)}>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        form={form}
                        rules={[{ required: true, message: 'Please input collection title !' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <Input />
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Poster"
                        name="poster"
                        form={form}
                    >
                        <div className="flex items-center">
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="api/upload"
                                headers={headers}
                                progress={upPro}
                                onChange={handleUpChange}
                            >
                                <div className="h-24 flex flex-col items-center justify-center">
                                    {
                                        imageUrl && <img src={imageUrl} style={{ height: '100%' }} />
                                    }
                                    {
                                        !imageUrl && uploadButton
                                    }

                                </div>
                            </Upload>
                            {
                                !!upPosterPro && upPosterPro != 100 && <Progress percent={upPosterPro} size="small" />
                            }
                        </div>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <div className="flex ">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

        </>

    )
}

export default MyCollection