import React, { useEffect, useState } from "react";
import temp_src_exhibits from 'assets/temp/exhibits.png'
import "./creator-detail.scss"
// import { useQuery } from "react-query";
import { Layout, Row, Col, Divider, Table, Button,  Pagination,Popover } from 'antd';
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'
import MyHeader from "components/header"
import Auction from "components/auction"
import Featured from "components/featured"
import MyFooter from "components/footer"
import ActiveBanner from "components/active-banner"

import defaultBg from "assets/default-creator-bg.png"
import { TwitterOutlined, ShareAltOutlined, InstagramFilled } from '@ant-design/icons';
import * as utils from "utils/utils"
import bus from "utils/bus";
const { Header, Footer, Content } = Layout;

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

function CreatorDetail(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [category, setCategory] = useState("Created")
    const location = useLocation();
    const [followees, setFollowee] = useState([])
    const [collections, setCollections] = useState([])
    const [friends, setFriends] = useState([])
    const [detail, setDetail] = useState(null)

    const query = useQuery()

    const columns = [
        {
            title: '#',
            render: (text, record, index) => `${index + 1}`,
            key: 'index',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Poster',
            dataIndex: 'poster',
            key: 'image',
            render: (text, record, index) => {
                return <div className="w-24 h-24 border flex items-center my-good_image" style={{ background: `url(${record.poster}) no-repeat` }}></div>
            }
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record, index) => {
                return <div className="flex items-center">
                     <Link to={`/collection?cid=${record.cid}&address=${detail.address_key}`} >
                        <Button type="link" block>
                            Detail
                        </Button>
                    </Link>
                </div>
            }
        }
    ];
    // const FollowimgContent = (
    //     <div>
    //         <p>Content</p>
    //         <p>Content</p>
    //     </div>
    // )
    
    // let params = utils.parseParams(location.search.split("?")[1])

    let params = location.pathname.split("/")

    // const filterCollection =(cid)=>{
    //     bus.collectionDetail(cid)
    // }

    // componentWillReceiveProps(nextProps) {
        
    // }


    useEffect(async () => {

        // setUserInfo(props.userInfo)
        let res = await bus.initUserInfo(params[2])
      
        if (res.code == 0) {
            await setDetail(res.data)
            let followee = await bus.getFollowees(params[2])
            let friends = await bus.getFriends(params[2])
            if (followee.code == 0) {
                 setFollowee(followee.data.datas)
            }
            if(friends.code == 0){
                 setFriends(friends.data.datas)
            }
        }

        collectionOfCreator()
    }, [location])

    const collectionOfCreator = async()=>{
        let res = await bus.collectionOfCreator(params[2])

        if(res.code == 0){
            setCollections(res.data)
        }
    }

    return (
        <>
            <ActiveBanner></ActiveBanner>
            {
                detail && <div className="my-layout">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content">
                            <div className="my-col-content-rows">
                                <div className="w-full h-60 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${detail.teaser_poster || defaultBg})` }}>

                                </div>
                                <Row>
                                    <Col span={2} className="my-col-creator-left"></Col>
                                    <Col span={20} className="my-col-creator-content" >
                                        <div className="flex relative pt-16 pb-10 my-mini-creator-content">
                                            <div>
                                                <div className="flex flex-col">
                                                    <span className="font-bold text-2xl">{detail.nicename}</span>
                                                    <span>{bus.formatUid(detail.address_key)}</span>
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text-sm font-bold">Bio</span>
                                                    <div className="w-60 break-words">{detail.bio}</div>
                                                </div>
                                                <div className="flex py-6">
                                                    {/* {
                                                        friends.length>0 && <Popover content={
                                                            <div>
                                                                {
                                                                    friends.map(v=>{
                                                                            return <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>
                                                                    })
                                                                   
                                                                }
                                                            </div>
                                                        } trigger="hover">
                                                            <div className="flex flex-col mr-4">
                                                                <span className="font-bold text-xl">{detail.count_friends}</span>
                                                                <span className="text-gray-400 font-bold">Followimg</span>
                                                            </div>
                                                        </Popover>
                                                    } */}
                                                    {
                                                        <Link to={`/follow?type=friends&address=${detail.address_key}`} >
                                                            <div className="flex flex-col mr-4 cursor-pointer">
                                                                <span className="font-bold text-xl">{detail.count_friends}</span>
                                                                <span className="text-gray-400 font-bold">Following</span>
                                                            </div>
                                                        </Link>
                                                    }
                                                    

                                                    {/* {
                                                       followees.length && <Popover content={
                                                            <div className="flex follow-content px-6 py-6 flex-wrap">
                                                                {
                                                                    followees.map(v=>{
                                                                            return <>
                                                                            <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>

                                                                        <div className="connect-item">
                                                                            <img src={v.avatar || temp_src_exhibits}></img>
                                                                            <span>{v.uri_name || v.nicename}</span>
                                                                        </div>
                                                                            </>
                                                                    })
                                                                }
                                                            </div>
                                                        } trigger="hover">
                                                            <div className="flex flex-col">
                                                                <span className="font-bold text-xl">{detail.count_followee}</span>
                                                                <span className="text-gray-400 font-bold">Followers</span>
                                                            </div>
                                                        </Popover>
                                                    } */}
                                                    {
                                                        <Link to={`/follow?type=followee&address=${detail.address_key}`} >
                                                            <div className="flex flex-col cursor-pointer">
                                                                <span className="font-bold text-xl">{detail.count_followee}</span>
                                                                <span className="text-gray-400 font-bold">Followers</span>
                                                            </div>
                                                        </Link>
                                                    }
                                                </div>
                                                {
                                                    detail.count_followee*1>0 && <div className="flex flex-col" >
                                                        <span className="font-bold text-gray-400 mb-2">Followed by</span>
                                                        <div className="flex">
                                                            {
                                                                followees.slice(0, 4).map(v => {
                                                                    return <Link to={`/creator?address=${v.address_key}`} >
                                                                        <img alt="" className="w-6 h-6 mr-2 rounded-full" src={v.avatar} />
                                                                    </Link>
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                }
                                                
                                                <div className="mt-8">
                                                    {
                                                        detail.twitter && <a href={`https://twitter.com/${detail.twitter}`}>
                                                        <div className="w-60 rounded-full bg-black text-white px-5 py-1 my-2 flex items-center justify-between">
                                                            <div className="flex items-center">
                                                                <TwitterOutlined />
                                                                <span className="ml-2">{detail.twitter}</span>
                                                            </div>
                                                            <ShareAltOutlined />
                                                        </div>
                                                    </a>
                                                    }
                                                    {
                                                        detail.instagram && <a href={`https://instagram.com/${detail.instagram}`}>
                                                        <div className="w-60 rounded-full bg-black text-white px-5 py-1 my-2 flex items-center justify-between">
                                                            <div className="flex items-center">
                                                                <InstagramFilled />
                                                                <span className="ml-2">{detail.instagram}</span>
                                                            </div>
                                                            <ShareAltOutlined />
                                                        </div>
                                                    </a>
                                                    }
                                                </div>
                                            </div>
                                            <div className=" w-full ml-12 my-mini-creators-created">
                                                <div className=" w-full ">
                                                    <div className="flex items-center w-full">
                                                        <div className={`rounded-full mr-8 px-4 py-1 font-bold cursor-pointer ${category == "Created" ? 'text-white bg-black' : 'text-black'}`} onClick={() => setCategory("Created")}>Created</div>
                                                        <div className={`rounded-full mr-8 px-4 py-1 font-bold cursor-pointer ${category == "Collections" ? 'text-white bg-black' : 'text-black'}`} onClick={() => setCategory("Collections")}>Collections</div>
                                                        {/* <div className={`rounded-full mr-8 px-4 py-1 font-bold cursor-pointer ${category == "Owned" ? 'text-white bg-black' : 'text-black'}`} onClick={() => setCategory("Owned")}>Owned</div>
                                                        <div className={`rounded-full mr-8 px-4 py-1 font-bold cursor-pointer ${category == "Sold" ? 'text-white bg-black' : 'text-black'}`} onClick={() => setCategory("Sold")}>Sold</div> */}
                                                    </div>
                                                    <Divider />

                                                    {
                                                        category === "Created"  && <div className="flex flex-wrap">
                                                        {
                                                            detail.auction_created && detail.auction_created.datas.map(v => {
                                                                return <div className="mr-2">
                                                                    {
                                                                      v.second_duration == -1  &&  <Featured info={v}></Featured>
                                                                    }
                                                                    {
                                                                        v.second_duration != -1  &&  <Auction info={v}></Auction>
                                                                    }
                                                                   
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    }
                                                   
                                                   {
                                                     category === "Collections"  &&  <Table dataSource={collections} columns={columns}  pagination={null}/>
                                                   }

                                                    {/* <div className="px-6 flex justify-center py-10">
                                                        <Pagination
                                                            total={detail.auction_created.total}
                                                            showQuickJumper
                                                            showTotal={total => `Total ${total} items`}
                                                        />
                                                    </div> */}
                                                </div>
                                            </div>

                                            <div className="rounded-full p-3 bg-white inline-block absolute -top-12">
                                                <img alt="" className="w-20 h-20 rounded-full" src={detail.avatar || temp_src_exhibits} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={2} className="my-col-creator-right"></Col>

                                </Row>
                            </div>
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>

                </div>
            }
        </>

    )
}

export default CreatorDetail