import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Divider, Pagination } from 'antd';
import MyHeader from "components/header"
// import Exhibits from "components/exhibits";
// import NftInfo  from "components/nft-info";
import Auction from "components/auction"
import Featured from "components/featured"
// import Creator from "components/creator"
import MyFooter from "components/footer"
import * as utils from "utils/utils"
import ActiveBanner from "components/active-banner"
import { Link, useLocation } from 'react-router-dom'
import poster from "assets/poster.png"
import bus from "utils/bus";
import "./active.scss"
const { Header, Footer, Content } = Layout;

function ActivePage(props) {

    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [detail, setDetail] = useState(null)

    useEffect(async () => {
        let res = await bus.initActiveList()
        if (res.code == 0) {
            await setDetail(res.data)
        }
    }, [])

    return (
        <>
            <ActiveBanner></ActiveBanner>
            <div className="my-layout">
                <Layout>
                    <Header className="shadow-lg">
                        <MyHeader userInfo={userInfo}></MyHeader>
                    </Header>
                    <Content className="my-content">
                        <Row>
                        <Col span={2} className="my-col-left"></Col>
                            <Col span={20}>
                                <div className="mt-16 active-info">
                                    <img src={poster}  />
                                    <div className="text-xl font-medium leading-8 flex flex-col justify-end">
                                        <p>
                                            The Dodge Sports x Uniquehash NFT collection is the first collection of its kind, and features high-quality artwork depicting the top fighters under the Dodge Sports brand. Dodge Sports is a full-service athlete management and marketing agency specializing in professional mixed martial arts and represents many of the biggest names in the UFC, Bellator, and other fighting organizations. 
                                        </p>
                                        <p>
                                            This collection features 17 never-before-released cards for each of Dodge Sports’ fighters, each custom-made by Dave Fretz. There is also a Dodge Sports logo NFT, which comes with a free Dodge Sports swag pack. The athletes depicted in the NFTs might also have a special surprise for the buyers of their NFTs, so make sure to reach out to them via Instagram if you win their auction. This collection represents the future of sports and finance and is the first foray for these elite fighters into the blockchain and NFT space.  
                                        </p>
                                    </div>

                                </div>
                            </Col>
                            <Col span={2} className="my-col-left"></Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows mt-16">
                                    <div className="flex flex-wrap active-item-cols">
                                        {
                                            detail  && detail.map(v => {
                                                return <div className="mr-2">
                                                    {
                                                        v.second_duration == -1 && <Featured info={v}></Featured>
                                                    }
                                                    {
                                                        v.second_duration != -1 && <Auction info={v}></Auction>
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>
                    </Content>
                    <Footer>
                        <MyFooter></MyFooter>
                    </Footer>
                </Layout>

            </div>
        </>
    )
}

export default ActivePage