import { Form, Input, Button, Checkbox, Modal,  Upload, Progress, message, Radio, Select } from 'antd';
import { LoadingOutlined, PlusOutlined, PlayCircleOutlined, PauseOutlined } from '@ant-design/icons';

import MusicLogo from "assets/Music.png"
import React, { useState, useEffect } from 'react';
import { getAction, postAction } from "utils/http"
import { create } from 'ipfs-http-client'
import bus from "utils/bus"

function BeCreatorPage(props) {
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 8 },
    };



    const tailLayout = {
        wrapperCol: { offset: 0, span: 8 },
    };


    const onFinish = async (values) => {
        let data = form.getFieldsValue()

        let emailReg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/

        if( !emailReg.test(data.email)  ){
            message.warning("Email format error")
            return 
        }


        let res = await bus.applyToBeCreator(data)
        console.log("333",res)
        if(res.code == 0){
            message.success("Waiting for administrator confirmation")
        }else{
            message.warning(res.message)
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

    };

    
    const [form] = Form.useForm();

    useEffect(async () => {
        console.log("#######################")
        let headers = await bus.getAuthorization();
    }, [])

    return (
        <>
            <div>
                <div className="flex justify-between pb-6">
                    <span className="font-bold">To Be Creator</span>
                </div>

                <div className="border py-6 px-6">
                    <Form
                        form={form}
                        {...layout}
                        layout="vertical"
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                         
                        <Form.Item
                            label="Your Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Your Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="Tell us more about yourself, for example, your profile and the link of your works"
                            name="intro"
                            rules={[{ required: true, message: 'Please input your intro!' }]}
                        >
                            <Input.TextArea />    
                            
                        </Form.Item>



                        <Form.Item {...tailLayout}>
                            <div className="mr-8 inline-block">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                {/* <Modal  width="80%" height="80%" visible={isModalVisible} footer={null} onCancel={closeDialog}>
                    <br/>
                    <CreateCollection></CreateCollection>
                </Modal> */}
                
            </div >
        </>
    )
}

export default BeCreatorPage