import { Layout, Row, Col, Divider, Pagination, Input, Spin, Form, message } from 'antd';
import MyHeader from "components/header"
import ActiveBanner from "components/active-banner"
import React, { useEffect, useState } from "react";
import MyFooter from "components/footer"
import "./contact.scss"
import {Helmet} from "react-helmet";

const { Header, Footer, Content } = Layout
function Contact(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)
    return (
        <>
            <Helmet>
                <title>Contact us - Unique Hash</title>
                <meta name="description" content="Contact us with any questions you have about NFT’s or related questions. We look forward to hearing from you" />
            </Helmet>
            <ActiveBanner></ActiveBanner>
            <div className="my-layout my-contact">
                <Layout>
                    <Header className="shadow-lg">
                        <MyHeader userInfo={userInfo}></MyHeader>
                    </Header>
                    <Content className="my-content my-creator-content">
                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="content flex flex-col items-center">
                                    <h1 className="text-3xl font-bold">Contact us</h1>
                                    <div className="text-2xl mt-16">
                                        <p>
                                            <span className="font-bold">Email: </span>
                                            <span> info@uniquehash.com</span>
                                        </p>
                                        <p>
                                            <span className="font-bold">Phone number:  </span>
                                            <span> 848-229-1658</span>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>
                    </Content>
                    <Footer>
                        <MyFooter></MyFooter>
                    </Footer>
                </Layout>
            </div>
        </>
    )
}

export default Contact