import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import "./nft-info.scss"
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'
import bus from "utils/bus"
function NftInfo() {
    const [userInfo, setUserInfo] = useState(null);

    useEffect(async () => {
        let userInfo = bus.getLocalStore("userInfo")
        console.log("userinfo", userInfo)
        if (userInfo) {
            await setUserInfo(userInfo)
        }
    }, [])

    async function connectWallet() {
        await bus.loginWallet()
    }
    return (

        <Row>
            <Col span={2} className="my-col-left"></Col>
            <Col span={22} className="my-col-content">
                <div className="items-center mt-20 my-ntf-info">
                    <div className="">
                        <span className="font-bold text-xl">Create and sell your NFTs</span>
                        {
                            userInfo && <Link to="/admin?key=Create item">
                                <div className="ml-10 inline-block bg-black text-white rounded-full py-1 px-4 cursor-pointer my-create-items-btn">Create Items</div>
                            </Link>
                        }
                        {
                            !userInfo && <div className="ml-10 inline-block bg-black text-white rounded-full py-1 px-4 cursor-pointer my-create-items-btn" onClick={()=>connectWallet()}>Create Items</div>
                        }

                    </div>
                    <div className=" inline-block">
                        <span className="font-medium text-gray-600 w-8/12 inline-block mt-4">
                            Unique Hash is a smart contract-based NFT trading platform designed to
                            provide a platform for lovers of digital collectibles and crypto art to
                            communicate and share.
                        </span>
                    </div>
                    {
                        userInfo && <Link to="/admin?key=Create item">
                            <div className="inline-block bg-black text-white rounded-full py-1 px-4 cursor-pointer my-create-items-btn-mini" >Create Items</div>
                        </Link>
                    }
                    {
                        !userInfo && <div className="inline-block bg-black text-white rounded-full py-1 px-4 cursor-pointer my-create-items-btn-mini" onClick={()=>connectWallet()}>Create Items</div>
                    }


                    <div className="my-nft-info-mini">
                        <div className="flex flex-col items-center mt-16">
                            <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.2778 8.88889V4.44444C40.2778 1.99333 38.2844 0 35.8333 0H6.94444C3.26888 0 0.277771 2.99111 0.277771 6.66667V33.3333C0.277771 38.2244 4.26444 40 6.94444 40H40.2778C42.7289 40 44.7222 38.0067 44.7222 35.5556V13.3333C44.7222 10.8822 42.7289 8.88889 40.2778 8.88889ZM35.8333 28.8889H31.3889V20H35.8333V28.8889ZM6.94444 8.88889C5.71777 8.88889 4.72222 7.89111 4.72222 6.66667C4.72222 5.44222 5.71777 4.44444 6.94444 4.44444H35.8333V8.88889H6.94444Z" fill="black" />
                            </svg>
                            <span className="text-xl font-bold my-4">Set up your wallet</span>
                            <div className="px-4">
                                To create or bid on works, you need to install the MetaMask wallet first.
                            </div>
                        </div>

                        <div className="flex flex-col items-center mt-16">
                            <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 16H4C1.794 16 0 17.794 0 20V36C0 38.206 1.794 40 4 40H32C34.206 40 36 38.206 36 36V20C36 17.794 34.206 16 32 16ZM4 8H32V12H4V8ZM8 0H28V4H8V0Z" fill="black" />
                            </svg>
                            <span className="text-xl font-bold my-4">Create your collection</span>
                            <div className="px-4">
                                After connecting the wallet, you can bid on the works to create your own collection.
                            </div>
                        </div>

                        <div className="flex flex-col items-center mt-16">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 0H2C0.894 0 0 0.894 0 2V14C0 15.106 0.894 16 2 16H14C15.106 16 16 15.106 16 14V2C16 0.894 15.106 0 14 0ZM34 20H22C20.894 20 20 20.894 20 22V34C20 35.106 20.894 36 22 36H34C35.106 36 36 35.106 36 34V22C36 20.894 35.106 20 34 20ZM28 0C23.588 0 20 3.588 20 8C20 12.412 23.588 16 28 16C32.412 16 36 12.412 36 8C36 3.588 32.412 0 28 0ZM8 20C3.588 20 0 23.588 0 28C0 32.412 3.588 36 8 36C12.412 36 16 32.412 16 28C16 23.588 12.412 20 8 20Z" fill="black" />
                            </svg>
                            <span className="text-xl font-bold my-4">Add your NFTs</span>
                            <div className="px-4">
                                You can also upload your own work and mint it into an NFT token.
                            </div>
                        </div>

                        <div className="flex flex-col items-center mt-16">
                            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9138 0.586C19.5398 0.21 19.0318 0 18.4998 0H8.49976C7.96776 0 7.45976 0.21 7.08576 0.586L1.08576 6.586C0.709756 6.96 0.499756 7.468 0.499756 8V18C0.499756 18.532 0.709756 19.04 1.08576 19.414L21.0858 39.414C21.4758 39.804 21.9878 40 22.4998 40C23.0118 40 23.5238 39.804 23.9138 39.414L39.9138 23.414C40.6958 22.632 40.6958 21.368 39.9138 20.586L19.9138 0.586ZM13.2058 16C11.3858 16 9.91176 14.526 9.91176 12.706C9.91176 10.886 11.3858 9.412 13.2058 9.412C15.0258 9.412 16.4998 10.884 16.4998 12.706C16.4998 14.528 15.0258 16 13.2058 16Z" fill="black" />
                            </svg>
                            <span className="text-xl font-bold my-4">List them for sale</span>
                            <div className="px-4">
                                After the token is minted, you can set the price and sell your work.
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>

    )
}

export default NftInfo