/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-06-13 20:52:33
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-11-17 18:33:03
 */
import axios from "axios";
import qs from 'qs';

let proxy = "api"
let defaultHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded'
}

let getAction = (url, params, headers) => {
    return axios({
        method: "get",
        timeout: 1000 * 60 * 5,
        url: `/${proxy}/${url}`,
        // baseURL: 'http://18.176.185.72:7123/',
        data: qs.stringify(params),
        headers: { ...defaultHeaders, ...headers }
    })
}

let getAction_other = (url, params, headers) => {
    return axios({
        method: "get",
        url: `/${url}`,
        timeout: 1000 * 60 * 5,
        // baseURL: 'http://18.176.185.72:7123/',
        data: qs.stringify(params),
        headers: { ...defaultHeaders, ...headers }
    })
}

let postAction = (url, data, headers) => {
    return axios({
        method: "post",
        timeout: 1000 * 60 * 5,
        // baseURL: 'http://18.176.185.72:7123/',
        url: `/${proxy}/${url}`,
        data: qs.stringify(data),
        headers: { ...defaultHeaders, ...headers }
    })
}


export { getAction, postAction, getAction_other }