/** 解析 params */
function parseParams(paramsStr){
    let params =paramsStr
    let res = {}
    if(params){
        let temp = params.split("&")
        for(let i=0;i<temp.length;i++){
            let key = temp[i].split("=")[0]
            let val = temp[i].split("=")[1]
            res[key]=val
        }
    }
    return res
}

export {parseParams}