/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-07-27 23:30:13
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-11-03 21:38:04
 */
import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Divider, Pagination } from 'antd';
import MyHeader from "components/header"
// import Exhibits from "components/exhibits";
// import NftInfo  from "components/nft-info";
import Auction from "components/auction"
import Featured from "components/featured"
import FixedItem from "components/fixedItem"
// import Creator from "components/creator"
import MyFooter from "components/footer"
import * as utils from "utils/utils"
import ActiveBanner from "components/active-banner"
import { Link, useLocation } from 'react-router-dom'
import bus from "utils/bus";
const { Header, Footer, Content } = Layout;

function SearchPaage(props) {
    const location = useLocation();
    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [dataSource, setDataSource] = useState({
        total:0,
        datas:[]
    })

    useEffect(async () => {
        searchList()
    }, [])

    function searchList(keyword=""){
        let params = utils.parseParams(location.search.split("?")[1])
        setTimeout(async() => {
            let res = await bus.searchAuctions(keyword || params.keyword)
            if(res.code == 0){
                setDataSource({
                    total:res.data.total,
                    datas:res.data.datas.map(v=>{

                        if(v.versions){
                            return {
                                ...v,
                                good:{
                                    ...v,
                                }
                            }
                        }else{
                            return {
                                ...v,
                                ...v.auction,
                                highest_bidding:v.auction.highest_bidding,
                                good:{
                                    ...v,
                                    ...v.auction
                                }
                            }
                        }
                        
                    })
                })
            }
        }, 1000);
    }

    return (
        <>
            <ActiveBanner></ActiveBanner>
            <div className="my-layout">
                <Layout>
                    <Header className="shadow-lg">

                        <MyHeader userInfo={userInfo} searchList={searchList}></MyHeader>
                    </Header>
                    <Content className="my-content">
                        <Row>
                            <Col span={2} className="my-col-left"></Col>
                            <Col span={20} className="my-col-content">
                                <div className="my-col-content-rows mt-16">
                                    <div className="flex flex-wrap">
                                        {
                                            dataSource.datas.map(v => {
                                                return <div className="mr-2">
                                                   {
                                                       v.second_duration !=-1 && !v.versions && <Auction info={v}></Auction>
                                                   }
                                                   {
                                                       v.second_duration == -1 && !v.versions && <Featured info={v}></Featured>
                                                   }
                                                   {
                                                        v.versions && <FixedItem info={v} ></FixedItem>
                                                   }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col span={2} className="my-col-right"></Col>
                        </Row>
                    </Content>
                    <Footer>
                        <MyFooter></MyFooter>
                    </Footer>
                </Layout>

            </div>
        </>
    )
}

export default SearchPaage