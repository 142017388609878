import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'

import React, { useEffect, useState } from "react";

import { Layout, Row, Col, Divider, Modal, Spin, Form, Input, message, Button, Tooltip,Slider } from 'antd';
import MyHeader from "components/header"
import * as utils from "utils/utils"
import ActiveBanner from "components/active-banner"
import MyFooter from "components/footer"
import temp_src_exhibits from 'assets/temp/exhibits.png'
import videoSpecial from 'assets/Powpow.mp4'
import "./detail.scss"
import {PlayCircleOutlined,PauseOutlined, TwitterOutlined, FullscreenOutlined } from "@ant-design/icons"
import MusicLogo from "assets/Music.png"
import DAPP from 'utils/dapp';
import Dragger from 'react-dragger-r'
import bus from "utils/bus";
const { Header, Footer, Content } = Layout;


function Detail(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)
    const location = useLocation();


    const [eth, setEth] = useState(0)
  
    const [slival, setSlival] = useState(10)
    const [bg_screen, set_bg_screen] = useState(100)
    const [loading, setLoading] = useState(false)
    const [timerClock, setTimerClock] = useState(0)
    const [isModalVisible, setModalVisible] = useState(false)
    const [detail, setDetail] = useState(null)
    let params = location.pathname.split("/") // utils.parseParams(location.search.split("?")[1])
    const [form] = Form.useForm();


    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    const [audioPlay,setAutioPlay] = useState(false)
    const [audioDetailPlay,setDetailAutioPlay] = useState(false)

    

    const playAudio = (src,e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src=src
        audioRef.play()
        setAutioPlay(true)
    }

    const pauseAudio=(e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlay(false)
    }

    const playDetailAudio = (src,e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioDetailRef")
        audioRef.src=src
        audioRef.play()
        setDetailAutioPlay(true)
    }

    const pauseDetailAudio=(e)=>{
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioDetailRef")
        audioRef.pause()
        setDetailAutioPlay(false)
    }

    const handleSlider = async (v)=>{
        await setSlival(v)
    }

    const handleFullscreen = ()=>{
        let el =  document.getElementById("my-fullscreen")

        el.style.display="block"

        let rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
        if (rfs) { // typeof rfs != "undefined" && rfs
            rfs.call(el)
        }
    }

    // const clockTimer = (timer) => {
    //     let temp = timer || timerClock
    //     let tim = setInterval(() => {
    //         if (temp <= 0) {
    //             clearInterval(tim)
    //             return
    //         }
    //         setTimerClock(temp - 1);
    //     }, 1000);
    // }

    const clockTimer = () => {
        if (timerClock <= 0) {
            return
        }
        setTimeout(async () => {
            await setTimerClock(timerClock - 1);
        }, 1000);
    }

    const handleShare=(type)=>{
        console.log(window.location)
        let url = window.location.href
        if(type === 'twitter'){
            window.open(`https://twitter.com/share?url=${url}&text=${detail.good.title}`)
            // window.location.href= `https://twitter.com/intent/tweet?url=${url}`
        }
    }

    const placeABid = () => {
        if(!bus.hasMetamask()){
            return
        }

        if (!bus.ifConnect()) {
            return false;
        }


        setModalVisible(true)
    }

    const handleWheel = (e) => {
        // console.log(1,e.nativeEvent.deltaY )
        // document.getElementById("zoomDiv").style.width = document.getElementById("zoomDiv").style.width * e.nativeEvent.deltaY 

    }

    const handleFullscreenWheel = (e)=>{
        set_bg_screen(bg_screen + e.nativeEvent.deltaY/50 )
    }

    const onFinish = async () => {
        let data = form.getFieldsValue()

        let maxPrice = null
        if (detail.highest_bidding) {
            maxPrice = detail.highest_bidding.price
        } else {
            maxPrice = detail.start_price
        }
        if (((data.price * 1) < (maxPrice * 1)) && !detail.highest_bidding) {
            message.warning(`Price is too low ( >= ${maxPrice} ${bus.getNetUnit(detail.good.contract_address)})`)
            return
        } else if (((data.price * 1) <= (maxPrice * 1)) && detail.highest_bidding) {
            message.warning(`Price is too low ( > ${maxPrice} ${bus.getNetUnit(detail.good.contract_address)})`)
            return
        }
        setLoading(true);
        setModalVisible(false)

        let payRes = null
        try {
            payRes = await bus.payThis(detail, data.price)
        } catch {
            setLoading(false);
        }
        setLoading(false);
        if (payRes && payRes.code == 0) {

            // window.location.reload()

            let res = await bus.auctionDetail(params[2])
            await setDetail(res)
            setTimerClock(Math.floor(res.second_duration))

        }
    }

    useEffect(async () => {
        if(timerClock == 0){
            setUserInfo(props.userInfo)
            let res = await bus.auctionDetail(params[2])
            await setDetail(res)
            setTimerClock(Math.floor(res.second_duration))
            let eth_temp = await bus.eth(res.good.contract_address)
            setEth(eth_temp)

            window.onresize = function () {
                let isFull = window.screen.height - window.document.documentElement.clientHeight <= 17;
                console.log("## 00011 **s", isFull)
                if(isFull){

                }else{
                    // let el = document;
                    // let cfs = el.cancelFullScreen || el.mozCancelFullScreen || el.msExitFullscreen || el.webkitExitFullscreen || el.exitFullscreen;
                    // if (cfs) {
                    //     cfs.call(el);
                    // }
                    let el =  document.getElementById("my-fullscreen")
                    el.style.display="none"
                }
            }
        }
        clockTimer()
    }, [timerClock])
    return (
        <>
             <audio id="audioRef" />
             <audio id="audioDetailRef" />
            <ActiveBanner></ActiveBanner>
            {
                detail && <div className="my-layout">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content">
                            <div className="bg-gray-300 w-full flex justify-center items-center relative"  style={{ height: '600px', overflow: 'hidden' }}>
                            {
                            detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="relative  flex items-center justify-center" style={{height:'460px',width:'460px'}}>
                                <Dragger>
                                    <div onWheel={(e) => handleWheel(e)} id="zoomDiv" className="flex items-center my-good_image custom-scale" style={{ width: 50 * slival, height: 50 * slival, background: `url(${detail.good && detail.good.image}) no-repeat`, backgroundPosition: ` center center` }}></div>
                                </Dragger>
                            </div>
                            } 
                            {
                                detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="h-80  w-80 flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                    {
                                        audioPlay && <PauseOutlined onClick={(e)=>pauseAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                                    }
                                    {
                                        !audioPlay && <PlayCircleOutlined onClick={(e)=>playAudio(detail.good.image,e)} style={{fontSize:'68px',opacity:0.4}}/>
                                    }
                                </div>
                            }
                            {
                                detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="h-80  w-80  justify-center  relative flex items-center detail-video">
                                    <video  controls muted loop autoplay="autoplay" src={detail.good.image} /> 
                                </div>
                            }
                            {
                                detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="absolute top-1 right-2 my-fullscreen-icon">
                                    <FullscreenOutlined onClick={()=>handleFullscreen()} className="text-3xl cursor-pointer" />
                                </div>
                            }
                            </div>
                            {
                                detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="" style={{ width: '300px', marginLeft: '50%', transform: 'translate(-150px, 0) ' }}>
                                    <Slider min={10} defaultValue={slival} onChange={(v) => setSlival(v)} />
                                </div>
                            }
                            <br /><br />
                            <Row>
                                <Col span={2} className="my-col-left"></Col>
                                <Col span={22} className="my-detail-content">
                                    <div className="py-10  relative flex justify-between my-mini-detail">
                                        <div className="w-1/2 my-detail-info">
                                            <h1 className="font-bold text-3xl flex items-center  ">
                                                <a className="text-black " href={detail.good.external_link}>{detail.good.title}</a>
                                                
                                            </h1>
                                            <div>
                                                <h3 className="font-bold">
                                                    Description
                                                </h3>
                                                <div className="break-words text-xl leading-6 w-full view-description ">
                                                    {detail.good.desc}
                                                </div>
                                                {
                                                    detail.good.video  && bus.getFileType(detail.good.video) === "video"  && <div className="mt-4" >
                                                        <video src={detail.good.video}  controls muted loop autoplay="autoplay"></video>
                                                    </div>
                                                }

                                                {
                                                     detail.good.video && bus.getFileType(detail.good.video) == 'audio' && <div className="h-36  w-36 flex items-center justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                                        {
                                                            audioDetailPlay && <PauseOutlined onClick={(e)=>pauseDetailAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                                                        }
                                                        {
                                                            !audioDetailPlay && <PlayCircleOutlined onClick={(e)=>playDetailAudio(detail.good.video,e)} style={{fontSize:'68px',opacity:0.4}}/>
                                                        }
                                                    </div>
                                                }

                                                {
                                                    detail.good.video  && bus.getFileType(detail.good.video) === "img"  && <div className="mt-4 w-1/2" >
                                                        <img src={detail.good.video} />
                                                    </div>
                                                }
                                                
                                            </div>
                                            <div className="py-6">
                                                <h3 className="font-bold">
                                                    Edition of
                                                </h3>
                                                <div className="break-words leading-3">
                                                    {detail.version}
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap View-btns">
                                                {/* <a href={`${detail.good.etherscan}`}>
                                                    <div className="w-full mt-4 bg-black text-white rounded-full px-4 py-2 flex items-center">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.57226 10.471C4.57224 10.3482 4.59649 10.2266 4.6436 10.1132C4.69072 9.99974 4.75978 9.89674 4.84682 9.81009C4.93386 9.72344 5.03716 9.65484 5.15079 9.60824C5.26442 9.56163 5.38614 9.53793 5.50896 9.5385L7.06191 9.54359C7.30952 9.54359 7.547 9.64196 7.72208 9.81705C7.89717 9.99214 7.99554 10.2296 7.99554 10.4772V16.3495C8.17048 16.2977 8.39484 16.2424 8.64062 16.1847C8.81128 16.1446 8.96337 16.0479 9.07223 15.9105C9.1811 15.7731 9.24036 15.6029 9.2404 15.4276V8.14356C9.24039 8.02094 9.26453 7.89951 9.31145 7.78622C9.35837 7.67292 9.42714 7.56998 9.51384 7.48326C9.60055 7.39654 9.70348 7.32775 9.81677 7.28082C9.93005 7.23388 10.0515 7.20972 10.1741 7.20971H11.7301C11.9777 7.20975 12.2152 7.30813 12.3903 7.48321C12.5653 7.65829 12.6637 7.89574 12.6638 8.14334V14.9041C12.6638 14.9041 13.0532 14.7464 13.4328 14.5862C13.5737 14.5266 13.694 14.4268 13.7786 14.2993C13.8632 14.1717 13.9084 14.0221 13.9085 13.869V5.80908C13.9085 5.68648 13.9327 5.56507 13.9796 5.4518C14.0265 5.33853 14.0953 5.23561 14.1819 5.14891C14.2686 5.06221 14.3716 4.99344 14.4848 4.94652C14.5981 4.8996 14.7195 4.87545 14.8421 4.87545H16.3981C16.6457 4.87545 16.8832 4.97381 17.0583 5.1489C17.2334 5.32399 17.3318 5.56146 17.3318 5.80908V12.446C18.6808 11.4684 20.0481 10.2924 21.1331 8.87844C21.2905 8.67319 21.3946 8.43217 21.4363 8.17688C21.4779 7.92159 21.4557 7.65997 21.3716 7.41535C20.6375 5.27589 19.2604 3.41541 17.4288 2.08828C15.5971 0.761149 13.4003 0.0321631 11.1386 0.000996897C5.03979 -0.0809305 -0.000546332 4.89754 5.27718e-05 10.9971C-0.0059355 12.9272 0.497815 14.8247 1.46037 16.4976C1.59311 16.7264 1.7884 16.9126 2.02329 17.0343C2.25818 17.156 2.52292 17.2081 2.78641 17.1846C3.08079 17.1586 3.44729 17.122 3.88299 17.0709C4.07263 17.0493 4.24771 16.9587 4.37489 16.8164C4.50206 16.6741 4.57244 16.49 4.57263 16.2991V10.471" fill="white" />
                                                            <path d="M4.53718 19.8887C6.17738 21.0819 8.11567 21.7981 10.1376 21.9581C12.1596 22.1181 14.1864 21.7156 15.9938 20.7952C17.8013 19.8748 19.3188 18.4723 20.3787 16.7429C21.4385 15.0136 21.9993 13.0247 21.999 10.9964C21.999 10.7432 21.9872 10.4929 21.9703 10.2439C17.9531 16.2349 10.5359 19.0357 4.53711 19.8889" fill="white" />
                                                        </svg>
                                                        <span className="ml-2">View on Etherscan</span>
                                                    </div>
                                                </a> */}

                                                { detail.collection &&
                                                    <Link to={`/collection?cid=${detail.collection.cid}&address=${detail.collection.creator.address_key}`} >
                                                        <div className="w-40 mt-4 mr-1 bg-black text-white rounded-full px-4 py-2 flex items-center">
                                                            <span className="ml-2">View Collection</span>
                                                        </div>
                                                    </Link>
                                                    
                                                     
                                                }
                                                
                                                {
                                                    detail.good.etherscan.split("tx")[detail.good.etherscan.split("tx").length-1].length > 5 && <a href={`${detail.good.etherscan}`} target="_blank" className="mr-1">
                                                    <div className="w-56 mt-4 bg-black text-white rounded-full px-4 py-2 flex items-center">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.57226 10.471C4.57224 10.3482 4.59649 10.2266 4.6436 10.1132C4.69072 9.99974 4.75978 9.89674 4.84682 9.81009C4.93386 9.72344 5.03716 9.65484 5.15079 9.60824C5.26442 9.56163 5.38614 9.53793 5.50896 9.5385L7.06191 9.54359C7.30952 9.54359 7.547 9.64196 7.72208 9.81705C7.89717 9.99214 7.99554 10.2296 7.99554 10.4772V16.3495C8.17048 16.2977 8.39484 16.2424 8.64062 16.1847C8.81128 16.1446 8.96337 16.0479 9.07223 15.9105C9.1811 15.7731 9.24036 15.6029 9.2404 15.4276V8.14356C9.24039 8.02094 9.26453 7.89951 9.31145 7.78622C9.35837 7.67292 9.42714 7.56998 9.51384 7.48326C9.60055 7.39654 9.70348 7.32775 9.81677 7.28082C9.93005 7.23388 10.0515 7.20972 10.1741 7.20971H11.7301C11.9777 7.20975 12.2152 7.30813 12.3903 7.48321C12.5653 7.65829 12.6637 7.89574 12.6638 8.14334V14.9041C12.6638 14.9041 13.0532 14.7464 13.4328 14.5862C13.5737 14.5266 13.694 14.4268 13.7786 14.2993C13.8632 14.1717 13.9084 14.0221 13.9085 13.869V5.80908C13.9085 5.68648 13.9327 5.56507 13.9796 5.4518C14.0265 5.33853 14.0953 5.23561 14.1819 5.14891C14.2686 5.06221 14.3716 4.99344 14.4848 4.94652C14.5981 4.8996 14.7195 4.87545 14.8421 4.87545H16.3981C16.6457 4.87545 16.8832 4.97381 17.0583 5.1489C17.2334 5.32399 17.3318 5.56146 17.3318 5.80908V12.446C18.6808 11.4684 20.0481 10.2924 21.1331 8.87844C21.2905 8.67319 21.3946 8.43217 21.4363 8.17688C21.4779 7.92159 21.4557 7.65997 21.3716 7.41535C20.6375 5.27589 19.2604 3.41541 17.4288 2.08828C15.5971 0.761149 13.4003 0.0321631 11.1386 0.000996897C5.03979 -0.0809305 -0.000546332 4.89754 5.27718e-05 10.9971C-0.0059355 12.9272 0.497815 14.8247 1.46037 16.4976C1.59311 16.7264 1.7884 16.9126 2.02329 17.0343C2.25818 17.156 2.52292 17.2081 2.78641 17.1846C3.08079 17.1586 3.44729 17.122 3.88299 17.0709C4.07263 17.0493 4.24771 16.9587 4.37489 16.8164C4.50206 16.6741 4.57244 16.49 4.57263 16.2991V10.471" fill="white" />
                                                            <path d="M4.53718 19.8887C6.17738 21.0819 8.11567 21.7981 10.1376 21.9581C12.1596 22.1181 14.1864 21.7156 15.9938 20.7952C17.8013 19.8748 19.3188 18.4723 20.3787 16.7429C21.4385 15.0136 21.9993 13.0247 21.999 10.9964C21.999 10.7432 21.9872 10.4929 21.9703 10.2439C17.9531 16.2349 10.5359 19.0357 4.53711 19.8889" fill="white" />
                                                        </svg>
                                                        <span className="ml-2">View on Etherscan</span>
                                                    </div>
                                                </a>
                                                }
                                               
                                                {
                                                    detail.good.etherscan.split("tx")[detail.good.etherscan.split("tx").length-1].length<=1 && <div className="w-56 mr-1 mt-4 bg-gray-600 text-white rounded-full px-4 py-2 flex items-center">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.57226 10.471C4.57224 10.3482 4.59649 10.2266 4.6436 10.1132C4.69072 9.99974 4.75978 9.89674 4.84682 9.81009C4.93386 9.72344 5.03716 9.65484 5.15079 9.60824C5.26442 9.56163 5.38614 9.53793 5.50896 9.5385L7.06191 9.54359C7.30952 9.54359 7.547 9.64196 7.72208 9.81705C7.89717 9.99214 7.99554 10.2296 7.99554 10.4772V16.3495C8.17048 16.2977 8.39484 16.2424 8.64062 16.1847C8.81128 16.1446 8.96337 16.0479 9.07223 15.9105C9.1811 15.7731 9.24036 15.6029 9.2404 15.4276V8.14356C9.24039 8.02094 9.26453 7.89951 9.31145 7.78622C9.35837 7.67292 9.42714 7.56998 9.51384 7.48326C9.60055 7.39654 9.70348 7.32775 9.81677 7.28082C9.93005 7.23388 10.0515 7.20972 10.1741 7.20971H11.7301C11.9777 7.20975 12.2152 7.30813 12.3903 7.48321C12.5653 7.65829 12.6637 7.89574 12.6638 8.14334V14.9041C12.6638 14.9041 13.0532 14.7464 13.4328 14.5862C13.5737 14.5266 13.694 14.4268 13.7786 14.2993C13.8632 14.1717 13.9084 14.0221 13.9085 13.869V5.80908C13.9085 5.68648 13.9327 5.56507 13.9796 5.4518C14.0265 5.33853 14.0953 5.23561 14.1819 5.14891C14.2686 5.06221 14.3716 4.99344 14.4848 4.94652C14.5981 4.8996 14.7195 4.87545 14.8421 4.87545H16.3981C16.6457 4.87545 16.8832 4.97381 17.0583 5.1489C17.2334 5.32399 17.3318 5.56146 17.3318 5.80908V12.446C18.6808 11.4684 20.0481 10.2924 21.1331 8.87844C21.2905 8.67319 21.3946 8.43217 21.4363 8.17688C21.4779 7.92159 21.4557 7.65997 21.3716 7.41535C20.6375 5.27589 19.2604 3.41541 17.4288 2.08828C15.5971 0.761149 13.4003 0.0321631 11.1386 0.000996897C5.03979 -0.0809305 -0.000546332 4.89754 5.27718e-05 10.9971C-0.0059355 12.9272 0.497815 14.8247 1.46037 16.4976C1.59311 16.7264 1.7884 16.9126 2.02329 17.0343C2.25818 17.156 2.52292 17.2081 2.78641 17.1846C3.08079 17.1586 3.44729 17.122 3.88299 17.0709C4.07263 17.0493 4.24771 16.9587 4.37489 16.8164C4.50206 16.6741 4.57244 16.49 4.57263 16.2991V10.471" fill="white" />
                                                        <path d="M4.53718 19.8887C6.17738 21.0819 8.11567 21.7981 10.1376 21.9581C12.1596 22.1181 14.1864 21.7156 15.9938 20.7952C17.8013 19.8748 19.3188 18.4723 20.3787 16.7429C21.4385 15.0136 21.9993 13.0247 21.999 10.9964C21.999 10.7432 21.9872 10.4929 21.9703 10.2439C17.9531 16.2349 10.5359 19.0357 4.53711 19.8889" fill="white" />
                                                    </svg>
                                                    <span className="ml-2">View on Etherscan</span>
                                                </div>
                                                }
                                               
                                                <a href={`${detail.good.ipfs_image}`} title="coming soon">
                                                {/* <Tooltip placement="topLeft" title="coming soon" color="#1890ff"> */}
                                                    <div className="w-56 mt-4 bg-black text-white rounded-full px-4 py-2 flex items-center mr-1" >
                                                        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0276 0.0791016L0 5.86862V17.4477L10.0276 23.2372L20.0553 17.4477V5.86862L10.0276 0.0791016ZM8.99234 1.47392C9.6054 1.92627 10.4418 1.92627 11.0549 1.47392L18.3252 5.67164C18.3107 5.80074 18.3107 5.93097 18.3252 6.06008L11.0549 10.2578C10.4418 9.80544 9.60553 9.80544 8.99243 10.2578L1.72206 6.05994C1.7366 5.93084 1.7366 5.80052 1.72206 5.67142L8.99243 1.47393L8.99234 1.47392ZM19.024 7.21957C19.1286 7.2967 19.2415 7.36186 19.3605 7.41383V15.8091C18.6623 16.1138 18.2441 16.8381 18.3293 17.5952L11.059 21.7929C10.9544 21.7157 10.8415 21.6507 10.7225 21.5987L10.7143 13.25C11.4126 12.9453 11.8308 12.2211 11.7455 11.464L19.0241 7.21943L19.024 7.21957ZM1.03085 7.2663L8.30122 11.4639C8.216 12.221 8.63423 12.9452 9.33252 13.25V21.6452C9.21345 21.6972 9.10057 21.7624 8.99603 21.8395L1.72612 17.6421C1.81133 16.885 1.39319 16.1608 0.694905 15.856V7.46079C0.813974 7.40882 0.926766 7.34366 1.03131 7.26653L1.03085 7.2663Z" fill="url(#paint0_linear)" />
                                                            <path d="M20.055 5.86865L10.0273 11.6582V23.2372L20.055 17.4477V5.86865ZM19.0237 7.21946C19.1282 7.2966 19.2411 7.36176 19.3602 7.41373V15.809C18.6619 16.1137 18.2437 16.838 18.3289 17.5951L11.0586 21.7928C10.954 21.7156 10.8412 21.6506 10.7221 21.5986L10.714 13.2499C11.4122 12.9452 11.8304 12.221 11.7452 11.4639L19.0237 7.21932L19.0237 7.21946Z" fill="black" fill-opacity="0.25098" />
                                                            <path d="M0 5.86865V17.4477L10.0276 23.2372V11.6582L0 5.86865ZM1.03121 7.26619L8.30158 11.4638C8.21637 12.2209 8.63459 12.9451 9.33288 13.2499V21.6451C9.21381 21.6971 9.10093 21.7623 8.99639 21.8394L1.72603 17.642C1.81124 16.8849 1.3931 16.1607 0.694814 15.8559V7.46067C0.813884 7.40871 0.926676 7.34355 1.03122 7.26641L1.03121 7.26619Z" fill="black" fill-opacity="0.039216" />
                                                            <path d="M10.0286 0.0791016L0.000976562 5.86862L10.0286 11.6581L20.0562 5.86862L10.0286 0.0791016ZM8.99332 1.47392C9.60637 1.92627 10.4427 1.92627 11.0558 1.47392L18.3262 5.67164C18.3116 5.80074 18.3117 5.93097 18.3262 6.06008L11.0558 10.2578C10.4428 9.80544 9.60651 9.80544 8.99341 10.2578L1.72304 6.05994C1.73757 5.93084 1.73757 5.80052 1.72304 5.67142L8.99341 1.47393L8.99332 1.47392Z" fill="black" fill-opacity="0.13018" />
                                                            <defs>
                                                                <linearGradient id="paint0_linear" x1="-9.04612e-05" y1="11.6581" x2="20.0554" y2="11.6581" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="white" />
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                        <span className="ml-2">View on IPFS</span>
                                                    </div>
                                                {/* </Tooltip> */}
                                                </a>
                                                     
                                                <a href={`${detail.good.ipfs_link}`}>
                                                {/* <Tooltip placement="topLeft" title="coming soon" color="#1890ff"> */}
                                                    <div className="w-56 mt-4 bg-black text-white rounded-full px-4 py-2 flex items-center">
                                                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span className="ml-2">View on IPFS Metadata</span>
                                                    </div>
                                                {/* </Tooltip> */}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="w-1/2 ml-36 my-mini-place-info">
                                            {
                                                (timerClock > 0 || detail.second_duration == -1) &&
                                                <div className="bg-black rounded-l-3xl  w-full pl-12 py-6">
                                                    <div className="flex items-center">
                                                        <div className="flex flex-col mr-12">
                                                            <span className="text-gray-400">Current Price</span>
                                                            {
                                                                detail.highest_bidding && <span className="font-bold text-lg text-white">{detail.highest_bidding.price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                                            }
                                                            {
                                                                !detail.highest_bidding && <span className="font-bold text-lg text-white">{detail.start_price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                                            }
                                                            {
                                                                detail.highest_bidding && <span className="text-gray-400">${(eth * detail.highest_bidding.price).toFixed(2)}</span>
                                                            }
                                                            {
                                                                !detail.highest_bidding && <span className="text-gray-400">${(eth * detail.start_price).toFixed(2)}</span>
                                                            }

                                                        </div>
                                                        {
                                                            detail.second_duration > 0 &&
                                                            <div className="flex flex-col inline-block mr-12">
                                                                {
                                                                    timerClock > 60 * 15 && <span className="text-gray-400">Auction ending in</span>
                                                                }
                                                                {
                                                                    timerClock <= 60 * 15 && timerClock > 60 && <span className="text-gray-400">This auction is ending soon!</span>
                                                                }
                                                                {
                                                                    timerClock <= 60 && timerClock > 0 && <span className="text-gray-400">This auction is ending very soon!</span>
                                                                }
                                                                <div className="font-bold text-lg text-white ">
                                                                    <span className="inline-block w-12 mr-2">{bus.sToHMS(timerClock)[0]}</span>
                                                                    <span className="inline-block w-12 mr-3">{bus.sToHMS(timerClock)[1]}</span>
                                                                    <span className="inline-block w-12">{bus.sToHMS(timerClock)[2]}</span>
                                                                </div>
                                                                <div className="text-gray-400  ">
                                                                    <span className="inline-block w-12 mr-2">Hours</span>
                                                                    <span className="inline-block w-12 mr-3">Minutes</span>
                                                                    <span className="inline-block w-12">Seconds</span>
                                                                </div>
                                                            </div>}
                                                        <div className="bg-white text-black py-1 px-4 rounded-full h-8 font-bold my-place-bid cursor-pointer" onClick={() => placeABid()}>Place bid</div>
                                                    </div>
                                                    {
                                                        timerClock <= 60 * 15 && timerClock > 60 && <div className="text-gray-400 mt-6 break-words">
                                                            Any bids placed in the last 15 minutes will extend the auction for antoher 15 minutes.
                                                        </div>
                                                    }
                                                    {
                                                        detail.second_duration == -1 && <div className="text-gray-400 mt-6 break-words">
                                                            Once a bid has been placed and the reserve price has been
                                                            met, a 24 hour auction for this artwork will begin.
                                                        </div>
                                                    }
                                                    <div className="bg-white text-black mt-2 py-1 px-4 rounded-full h-8 font-bold my-mini-place-bid" onClick={() => placeABid()}>Place bid</div>
                                                </div>
                                            }
                                            {
                                                // 拍卖已结束
                                                timerClock == 0 && detail.highest_bidding && <>
                                                    <div className="bg-black rounded-l-xl  w-full pl-12 py-6 text-white">
                                                        <div className="flex items-center">
                                                            <div className="flex flex-col mr-12">
                                                                <span className="text-gray-400">Sold for</span>
                                                                {
                                                                    detail.highest_bidding && <span className="font-bold text-lg text-white">{detail.highest_bidding.price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                                                }
                                                                {
                                                                    !detail.highest_bidding && <span className="font-bold text-lg text-white">{detail.start_price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                                                }
                                                            </div>
                                                            <div className="flex flex-col inline-block mr-12">
                                                                <span className="text-gray-400">Owned by</span>
                                                                <div className="text-white flex items-center">
                                                                    <img src={detail.owner.avatar || temp_src_exhibits} alt="" className="w-5 rounded-full" />
                                                                    <span className="font-bold">@ {detail.owner.uri_name || detail.owner.address_key}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex py-8 user-coin-info">
                                                        <div className="flex flex-col items-center w-96 border border-l-0 border-b-0 border-t-0 my-4">
                                                            <img alt="" src={detail.owner.avatar || temp_src_exhibits} className="w-12 h-12 rounded-full" />
                                                            <div className="font-bold text-center">
                                                                <span>Auction won by</span>
                                                                <span className="text-gray-400 ml-2">@{detail.owner.uri_name || detail.owner.address_key}</span>
                                                            </div>
                                                        </div>
                                                        <div className="w-96 ">
                                                            <div className="flex flex-col justify-center items-center  h-full font-bold">
                                                                {
                                                                    <span>Sold for {detail.highest_bidding.price} {bus.getNetUnit(detail.good.contract_address)}</span>
                                                                }
                                                                <span className="text-gray-400">${(eth * detail.highest_bidding.price).toFixed(2)}</span>
                                                                <span className="text-gray-400"> {bus.formatDate(detail.highest_bidding.created)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="my-padding">
                                                <div className="mt-10">
                                                    <h1 className="text-xl font-bold">History</h1>
                                                </div>
                                                <Divider />
                                                <div>
                                                    <ul>
                                                        {
                                                            detail.histories.map(v => {
                                                                return <li className="w-10/12 cursor-pointer" >
                                                                    <a className="flex justify-between text-black" href={v.etherscan}>
                                                                        <div className="flex">
                                                                            <img alt="" src={v.avatar || temp_src_exhibits} className="w-6 rounded-full flex-shrink-0 h-6 mr-2" />
                                                                            <div>

                                                                                {
                                                                                    (v.action == "Minted" || v.action == "Listed") && <span className="font-bold">{v.action} by</span>
                                                                                }
                                                                                {
                                                                                    (v.action != "Minted" && v.action != "Listed") && <span className="font-bold">{v.action} placed by</span>
                                                                                }
                                                                                {
                                                                                    (v.uri_name || v.user) && <span className="text-gray-400 ml-2 ">@{v.uri_name || v.user}</span>
                                                                                }

                                                                                <h3 className="text-gray-400 text-xs">{bus.formatDate(v.created)}</h3>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="flex">
                                                                                {
                                                                                    v.price && <span className="mr-2">{v.price} {bus.getNetUnit(detail.good.contract_address)} </span>
                                                                                }

                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M16 11V17C16 17.5304 15.7893 18.0391 15.4142 18.4142C15.0391 18.7893 14.5304 19 14 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H9" stroke="#101010" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M13 1H19V7" stroke="#101010" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M8 12L19 1" stroke="#101010" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </div>
                                                                            {
                                                                                v.price && <h3 className="text-gray-400">${(eth * v.price).toFixed(2)}</h3>
                                                                            }

                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="rounded-full bg-black text-white inline-block py-1 px-4 w-32 absolute -top-4 my-detail-address">{bus.formatUid(detail.creator.address_key)}</div>
                                        <div className="px-4  flex items-center mt-2 absolute -top-5 left-36 my-detail-twitter">
                                            <TwitterOutlined className="text-2xl cursor-pointer" onClick={()=>handleShare('twitter')} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>
                </div>
            }

            <div className="fullscreen-content bg-gray-300 " id="my-fullscreen">
                {
                    detail && detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="relative  flex items-center justify-center" >
                        <Dragger>
                            {/* <div onWheel={(e) => handleWheel(e)} id="zoomDiv" className="flex items-center my-good_image custom-scale" style={{ width: 50 * slival, height: 50 * slival, background: `url(${detail.good && detail.good.image}) no-repeat`, backgroundPosition: ` center center` }}></div> */}
                            <div onWheel={(e) => handleFullscreenWheel(e)} id="zoomDiv" className="flex items-center my-good_image custom-scale " style={{ width: bg_screen+ "vw", height: bg_screen+ "vh", background: `url(${detail.good && detail.good.image}) no-repeat`, backgroundPosition: ` center center` }}></div>
                        </Dragger>
                    </div>
                } 
                {
                    detail && detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="h-80  w-80 flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                    {
                        audioPlay && <PauseOutlined onClick={(e)=>pauseAudio(e)}  style={{fontSize:'68px',opacity:0.4}} />
                    }
                    {
                       !audioPlay && <PlayCircleOutlined onClick={(e)=>playAudio(detail.good.image,e)} style={{fontSize:'68px',opacity:0.4}}/>
                    }
                    </div>
                }
                {
                    detail && detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="h-80  w-80  justify-center  relative flex items-center detail-video">
                        <video  controls muted loop autoplay="autoplay" src={detail.good.image} /> 
                    </div>
                }
            </div>

            <Modal title={'The current item was created in '+bus.getNameOfNetwork(detail && detail.good.network)} visible={isModalVisible} footer={null} onCancel={() => setModalVisible(false)}>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Bid price"
                        name="price"
                        form={form}
                        rules={[{ required: true, message: 'Please input your price!' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <Input type="number" title="Enter a valid value. The two closest valid values are 0 and 1" />
                            <span className="font-bold flex-shrink-0 h-6">&emsp; {bus.getNetUnit()} </span>
                        </div>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <div className="flex ">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                            <div>
                                <Button type="" onClick={() => setModalVisible(false)}>
                                    Quit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>

                </Form>
            </Modal>

            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>
        </>
    )
}

export default Detail