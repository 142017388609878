/*
 * @Title: 
 * @Describe: 
 * @Author: longlonglaobiao
 * @Date: 2021-10-06 00:19:20
 * @LastEditors: longlonglaobiao
 * @LastEditTime: 2021-10-06 00:48:16
 */

import { Table, Spin, Tag, message } from "antd"
import React, { useState, useEffect } from 'react';
import bus from "utils/bus";

function CreatorsList() {
    const [dataSource, setDataSource] = useState({
        total: 0,
        datas: []
    })

    const initCreators = async (currentPage = 1, pageSize = 8) => {
        let res = await bus.CreatorsList()
        if (res.code == 0) {
            setDataSource({ total: res.data.total, datas: res.data.datas })
        }
    }

    const handlePass = async (record)=>{
        let res = await bus.Approved(record.aid)
        console.log("**********",res)

        if (res.code == 0) {
            message.success("The email has been sent to the applicant's mailbox")
            initCreators()
        }
    }

    useEffect(async () => {
        initCreators()
    }, [])



    const columns = [
        {
            title: '#',
            render: (text, record, index) => `${index + 1}`,
            key: 'index',
        },
        {
            title: 'Name',
            key: "name",
            dataIndex: "name"
        },
        {
            title: 'Email',
            key: "email",
            dataIndex: "email"
        },
        {
            title: 'Intro',
            key: "intro",
            dataIndex: "intro"
        },
        {
            title: 'Address',
            key: "user_address",
            dataIndex: "user_address"
        },
        {
            title: 'Created time',
            key: "created",
            dataIndex: "created"
        }, 
        {
            title: 'Action',
            key: "Action",
            render: (text, record, index) => {
                if (record.approved == 0) {
                    return <div className="px-2 py-1 rounded-sm bg-blue-400 inline-block text-white cursor-pointer w-20 text-center" onClick={() => handlePass(record)}>Pass</div>
                }

                if (record.approved == 1) {
                    return <div className="px-2 py-1 rounded-sm inline-block text-blue-500 w-20 text-center">Passed</div>
                }

                if (record.approved == 2) {
                    return <div className="px-2 py-1 rounded-sm inline-block text-green-500 w-20 text-center">Done</div>
                }
            },
        },
    ]
    return (
        <>
            <div className=" border-w-4 overflow-x">
                <Table dataSource={dataSource.datas} columns={columns} scroll={{
                    x: '100vw',
                }}  pagination={{
                    // 设置总条数
                    total: dataSource.total,
                    // 显示总条数
                    showTotal: total => `total ${dataSource.total}`,
                    // 改变页码时
                    onChange: ((page, pageSize) => {
                        initCreators(page)
                    })
                }} />
            </div>
            {/* <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div> */}
        </>
    )
}

export default CreatorsList