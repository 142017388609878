import contractJSON from './contract.1.0.0.json';
import erc1155ContractJSON from './uniquehash.contract.json';
import erc1155FactoryJSON from './uniquehash.factory.json';
import Web3 from "web3";
import { getAction, postAction } from "./http"
import { ethers } from 'ethers';
import { Modal, Button } from 'antd';
// var contractAddress = '0xeDDb32bD6892d7D53a040CC19bC91a908c2e874a';// rinkeby
// var contractAddress = '0xf04d0c04aeaedff65b5bc9e9b9fe11b8ea10e9ca'; // eth main net
// const contractAddressAndNetworkMapping = [
//     ['0xeDDb32bD6892d7D53a040CC19bC91a908c2e874a', 4], // rankeby
//     ['0xf04d0c04aeaedff65b5bc9e9b9fe11b8ea10e9ca', 1], // ethereum mainnet
//     ['0x04a292Da40AeF0dB3F2FF6352568EB3D20d905Fa', 97],
// ];

// allowed network -
// var allowedNetwork = [1, 56]; // online site - only allow main network
// const allowedNetwork = [1] 
// const allowedNetwork = [1, 4, 56, 97]; // dev site - allow main  & test network

const contractAddressAndNetworkMapping = [
    {
        netId: 1,
        contractAddress: "0xf04d0c04aeaedff65b5bc9e9b9fe11b8ea10e9ca",
        unit: "ETH",
        enable: true
    },
    {
        netId: 4,
        contractAddress: "0xeDDb32bD6892d7D53a040CC19bC91a908c2e874a",
        unit: "ETH",
        enable: true// eth 测试网络
    },
    {
        netId: 56,
        contractAddress: "...", // 还未上线 【不能为空】
        unit: "BNB",
        enable: false
    },
    {
        netId: 97,
        contractAddress: "0x04a292Da40AeF0dB3F2FF6352568EB3D20d905Fa",
        unit: "BNB",
        enable: false
    },
];

const erc1155FactoryAddressAndNetworkMapping = [{
    netId: 1, 
    contractAddress: '0xddcd023a9ee5682d87092344aee62936de29d44b',
    unit: "ETH",
    enable: true,
}, {
    netId: 4, // rinkeby 
    contractAddress: '0x23D179df790D3ec4d73A707A4417865C4950A4aE',
    unit: 'ETH',
    enable: true,
}];

const erc1155ContractAddressAndNetworkMapping = [
    {
        netId: 1, // eth main net 
        contractAddress: '0xcab9788a73c7f35493166b2118b3df1e189d7f2e',
        unit: 'ETH',
        enable: true,
    },
    {
        netId: 4, // rinkeby 
        contractAddress: '0xa57D7Dc2F3b49d7F0F393cb4aDfB9FAcD117841b',
        unit: 'ETH',
        enable: true,
    },
    {
        netId: 56,
        contractAddress: '...',
        unit: 'BNB',
        enable: false, // BSC main net
    },
    {
        netId: 97,
        contractAddress: '0xD740b3AB0d6346BeC3dF9c9Bc2afb3121de78325',
        unit: 'BNB',
        enable: false, // BSC test net
    },
];

var ERROR_METAMASK_UNABLE = 'Please install metamask wallet first';
var metamaskInstallURL = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en';

/**
 * NFT 合约 交互库
 */
async function updateUserInfo(uid) {

    console.log("调用账户切换",uid)
    let res = await postAction(`login/wallet`, {
        address: uid,
    });

    let formatUid = (address) => {
        if (address) {
            return `${address.slice(0, 6)}...${address.slice(address.length - 4)}`;
        } else {
            return ''
        }
    }

    if (res.data.code == 0) {
        if (res.data && res.data.data) {
            let res1 = await getAction(
                `user/center`,
                {},
                {
                    Authorization: `Bearer ${res.data.data.token}`,
                }
            );

            window.localStorage.setItem("userToken", res.data.data.token)
            window.localStorage.setItem("expired", res.data.data.expired_in)

            if (res1.data.code == 0) {
                window.localStorage.setItem("userInfo", JSON.stringify({
                    ...res1.data.data,
                    nanoUid: formatUid(res.data.data.address_key),
                }))

                setTimeout(()=>{
                    window.location.href="/"
                },200)

            }
        }
    }
}
function DAPPContract() {
    this._web3 = null; // web3 实例对象
    this._contract = null;// 合约实例对象
    this.ethereum = window.ethereum; // 以太坊实例对象

    this._erc1155Contract = null;// erc1155 合约对象
    this._erc1155Factory = null; // erc1155 工厂合约对象

    // 非 iphone 手机 弹出 提示框安装 metamask
    if (!this.ethereum) {
        // if (!ifPhone()) {
        //     const modal = Modal.info();
        //     modal.update({
        //         content: <div>
        //             <p>Please install the MetaMask extension in order to use Uniquehash</p>
        //             <a href="https://metamask.io/" style={{ padding: '10px 20px', background: 'black', color: 'white', borderRadius: '5px', display: 'inline-block', marginTop: '20px' }}>Go to MetaMask's website</a>
        //         </div>
        //         ,
        //     });
        // }
    } else {

        // 监听当前账户是否登出
        this.ethereum.on('disconnect', function () {
            window.localStorage.removeItem("userInfo");
            window.localStorage.removeItem("userToken");
            window.location.href = '/';// 回到主页
        });

        // 账户切换
        this.ethereum.on('accountsChanged', function (accounts) {
            
            updateUserInfo(accounts[0])
        });

        // 网络切换后 退出程序
        this.ethereum.on('chainChanged', function () {
            // window.localStorage.removeItem("userInfo");
            // window.localStorage.removeItem("userToken");
            setTimeout(() => {
                window.location.href = '/';// 回到主页
            }, 1000);
        });

    }
}

DAPPContract.prototype.fromWei = function (wei) {
    return Web3.utils.fromWei(wei+ '', 'ether');
}

DAPPContract.prototype.toWei = function (price) {
    return Web3.utils.toWei(price, "ether");
}

// 创建一个空投
DAPPContract.prototype.createAirdrop = async function(token, serialNo) {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    const signer = provider.getSigner();


    const airdop = {token, serialNo};
    const {version} = this.currentContext();
    const contract = this.getERC1155ContractAddressOfNetwork( version );
    const chainDomain = {
        name: "Uniquehash",
        version: "1",
        verifyingContract: contract,
        chainId: version,
    };
    const types = {
        NFTAirdrop: [ {name: "token", type: "uint256"}, {name: "serialNo", type: "uint256"} ]
    };
    const from = this.ethereum.selectedAddress;
    const params = {domain: chainDomain, types: types, message: airdop};
    console.log(params);

    const singature = await signer._signTypedData(chainDomain, types, airdop);
    return singature;
    // params['primaryType'] = 'NFTAirdrop';
    // console.log(params);
    // const res = await this.ethereum.request({
    //     method: 'eth_signTypedData_v3',
    //     params: [from, JSON.stringify( params ) ],
    //     from: from,
    // });
    // console.log(res);
}


// 目前通过物理像素来判断
function erc1155_factoryifPhone() {
    return window.screen.width < 600
}
DAPPContract.prototype.getSelectedAddress = function () {
    return this.ethereum && this.ethereum.selectedAddress
}

DAPPContract.prototype.getUnit = function (contract_address) {
    // return this.ethereum && this.ethereum.selectedAddress

    let cur = contractAddressAndNetworkMapping.filter(v => v.contractAddress.toLocaleLowerCase() == contract_address)[0]

    let cur1155 = erc1155ContractAddressAndNetworkMapping.filter(v => v.contractAddress.toLocaleLowerCase() == contract_address)[0]
    
    // 
    if (cur || cur1155) {
        return ( cur && cur.unit) || (cur1155 && cur1155.unit)
    } else {
        const { version } = this.currentContext();
        let netObj = contractAddressAndNetworkMapping.filter(v => v.netId == version)[0]
        let netObj1155 = erc1155ContractAddressAndNetworkMapping.filter(v => v.netId == version)[0]
        if (netObj || netObj1155)
            return (netObj && netObj.unit) || (netObj1155 && netObj1155.unit)
        else
            return ""
    }
}
// getContractAddressOfNetowork
DAPPContract.prototype.getContractAddressOfNetowork = function (network) {
    for (let map of contractAddressAndNetworkMapping) {
        if (map.netId == network && map.enable) {
            return map.contractAddress;
        }
    }
};

DAPPContract.prototype.getERC1155ContractAddressOfNetwork = function (network) {
    for (let map of erc1155ContractAddressAndNetworkMapping) {
        if (map.netId == network && map.enable) {
            return map.contractAddress;
        }
    }
};

DAPPContract.prototype.getERC1155FactoryContractAddressOfNetwork = function(network) {
    for (let map of erc1155FactoryAddressAndNetworkMapping) {
        if (map.netId == network && map.enable) {
            return map.contractAddress;
        }
    }
}

DAPPContract.prototype.getContractAddressOfNetwork = function (contractAddress) {
    for (let map of contractAddressAndNetworkMapping) {
        // if (map[0] == contractAddress) {
        //     return map[1];
        // }
        if (map.contractAddress == contractAddress) {
            return map.netId;
        }
    }
}

// 检查Metamask 钱包是否安装
DAPPContract.prototype.metamaskInstalled = function () {
    return typeof this.ethereum !== 'undefined' && this.ethereum.isMetaMask;
};

// 是否已经连接到钱包并且分配了账户
DAPPContract.prototype.connected = function () {
    return this.ethereum && !!this.ethereum.selectedAddress;
}

// 如果metamask 钱包没有安装 则抛出异常
DAPPContract.prototype._checkWallet = function () {
    if (!this.metamaskInstalled()) {
        alert(ERROR_METAMASK_UNABLE);
        window.open(metamaskInstallURL);

        throw ERROR_METAMASK_UNABLE;
    }
}

// 返回web3对象 用来和以太坊进行网络交互
DAPPContract.prototype.enableWeb3 = function () {
    // 已经激活 直接返回
    if (this._web3) {
        return Promise.resolve(this._web3);
    }
    // 钱包必须安装
    this._checkWallet();
    window.web3 = this._web3 = new Web3(this.ethereum);
    this._web3.eth.handleRevert = true; // 处理require返回错误

    // 激活钱包

    return this.requestMetamaskAccount();
}

// metamask 请求用户账户
DAPPContract.prototype.requestMetamaskAccount = function () {
    return this.ethereum.request({
        method: 'eth_requestAccounts',
        params: [{
            eth_accounts: {}
        }]
    });
}

// 监听账户变化
// 一般处理账户变化后 直接刷新浏览器
DAPPContract.prototype.onAccountChange = function (cb) {
    this.ethereum.on('accountsChanged', function (accounts) {
        cb(accounts);
    });
}

// 登出钱包
DAPPContract.prototype.disconnect = async function () {

    const walletAddress = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [{
            eth_accounts: {}
        }]
    });

    const permission = await window.ethereum.request({
        method: "wallet_requestPermissions",
        params: [{
            eth_accounts: {}
        }]
    });

    console.log(['wallet address', walletAddress, permission]);
}

// 返回当前运行的网络版本和登录的用户名
DAPPContract.prototype.currentContext = function () {
    if (!this.ethereum) {
        return {
            account: null,
            version: 0,
        }
    }

    let temp = {
        account: this.ethereum.selectedAddress,
        version: this.ethereum.networkVersion,
    }

    return temp
}

DAPPContract.prototype.deployContract = async function () {
    await this.enableWeb3();
    const account = this.ethereum.selectedAddress;
    if (account == null) {
        throw "Please select account";
    }
    const contract = new this._web3.eth.Contract(contractJSON.abi);
    const bytecode = contractJSON.bytecode;
    if (!bytecode) {
        throw "not found bytecode";
    }
    console.log(account);
    const rs = await contract.deploy({
        data: bytecode,
    }).send({
        from: account,
        //gas: this._web3.utils.toHex(800000),
        //gasPrice: this._web3.utils.toHex(this._web3.utils.toWei('30', 'gwei')),
    });
    if (rs._address) {
        return rs._address
    }
    throw "error accurred when depoly contract";
}

// 检查当前网络是否被允许连接
DAPPContract.prototype.isAllowedNetwork = function (netId) {
    const { version } = this.currentContext();
    if (netId) {
        if (netId != version) return false
    }

   setTimeout(() => {
    console.log(['net id', netId, version]);
   }, 1000);

    let netObj = contractAddressAndNetworkMapping.filter(v => v.netId == version)[0];
    let netObj2 = erc1155ContractAddressAndNetworkMapping.filter(v => v.netId == version)[0];
    console.log(66666,[erc1155ContractAddressAndNetworkMapping, version,netObj, netObj2]);
    if (!netObj && !netObj2) return false

    return netObj.enable || netObj2.enable// allowedNetwork.map(v=>v.netId).indexOf(version * 1) != -1;

};

DAPPContract.prototype.erc1155Contract = function () {
    if (this._erc1155Contract != null) {
        return Promise.resolve(this._erc1155Contract);
    }
    let self = this;
    let promise = new Promise(function (resolve, reject) {
        self.enableWeb3().then(function () {
            let account = self.ethereum.selectedAddress; // 当前钱包地址
            if (account == null) {
                return reject('You don\'t have account in your wallet');
            }

            const { version } = self.currentContext();
            let contractAddress = self.getERC1155ContractAddressOfNetwork(version);
            console.log(['合约地址', contractAddress, version]);

            if (contractAddress == '') {
                return reject('network is not supported right now.');
            }

            let instance = new self._web3.eth.Contract(erc1155ContractJSON.abi, contractAddress, {
                from: account,
            });
            self._erc1155Contract = new ERC1155Contract(instance, self._web3);
            resolve(self._erc1155Contract);

        });
    });

    return promise;
};

DAPPContract.prototype.erc1155Factory = function() {
    if (this._erc1155Factory != null) {
        return Promise.resolve(this._erc1155Factory);
    }
    let self = this;
    let promise = new Promise(function (resolve, reject) {
        self.enableWeb3().then(function () {
            let account = self.ethereum.selectedAddress; // 当前钱包地址
            if (account == null) {
                return reject('You don\'t have account in your wallet');
            }

            const { version } = self.currentContext();
            let contractAddress = self.getERC1155FactoryContractAddressOfNetwork(version);
            console.log(['合约地址', contractAddress, version]);

            if (contractAddress == '') {
                return reject('network is not supported right now.');
            }

            let instance = new self._web3.eth.Contract(erc1155FactoryJSON.abi, contractAddress, {
                from: account,
            });
            self._erc1155Factory = new ERC1155Factory(instance, self._web3);
            resolve(self._erc1155Factory);
        });
    });

    return promise;
}

// 合约对象
DAPPContract.prototype.contract = function () {
    if (this._contract != null) {
        return Promise.resolve(this._contract);
    }
    var self = this;
    var promise = new Promise(function (resolve, reject) {

        // 激活钱包
        self.enableWeb3().then(function () {
            var account = self.ethereum.selectedAddress;
            if (account == null) {
                reject('You don\'t have account in your wallet');
            } else {

                // 根据当前的网络选择对应的合约地址
                const { version } = self.currentContext();
                let contractAddress = self.getContractAddressOfNetowork(version);

                if (!self.isAllowedNetwork()) {
                    return reject('network is not supported right now.');
                }

                var instance = new self._web3.eth.Contract(contractJSON.abi, contractAddress, {
                    from: account
                });
                self._contract = new Contract(instance, self._web3);
                resolve(self._contract);
            }
        });
    });
    return promise;
}

/**
 * ERC1155 工厂合约
 * @param {*} instance 
 * @param {*} web3 
 */
function ERC1155Factory(instance, web3) {
    this._instance = instance;
    this._web3 = web3;
    if (this._instance == null) {
        throw 'NFT contract is not found';
    }
    this.address = this._instance._address;
}

/**
 * 买家Mint 后 直接 transfer 到买家账户下
 * @param {*} accountAddress 
 * @param {*} tokenId 
 * @param {*} total 
 * @param {*} price 
 * @param {*} fate 
 * @returns 
 */
ERC1155Factory.prototype.Mint = function(accountAddress, tokenId, total, price, fate) {
    price = this._web3.utils.toWei(price + "", "ether");
    return this._instance.methods.Mint(accountAddress, tokenId, total, price, fate, "0x0").send({
        value: price,
        handleRevert: true,
    });
}

function ERC1155Contract(instance, web3) {
    this._instance = instance;
    this._web3 = web3;
    if (this._instance == null) {
        throw 'NFT contract is not found';
    }
    this.address = this._instance._address;
}

/**
 * 铸币 和 批量设置限量版的价格和 永久分红的费率
 * @param {*} accountAddress 
 * @param {*} tokenId 
 * @param {*} total 
 * @param {*} price 
 * @param {*} fate 
 * @returns 
 */
ERC1155Contract.prototype.mint = function (accountAddress, tokenId, total, price, fate) {
    // 转换成 wei 
    price = this._web3.utils.toWei(price + "", "ether");
    return this._instance.methods.mint(accountAddress, tokenId, total, price, fate, "0x0").send();
}

/**
 * 买家购买
 * @param {*} fromAddress token 作者地址
 * @param {*} tokenId 
 * @param {*} serialNo 版本序号 
 * @returns 
 */
ERC1155Contract.prototype.transferTokenSerial = function (fromAddress, tokenId, serialNo, price) {
    price = this._web3.utils.toWei(price + "", "ether");
    return this._instance.methods.transferTokenSerial(fromAddress, tokenId, serialNo).send({
        value: price,
        handleRevert: true,
    });
}

/**
 * 单独销售 NFT 版本
 * @param {*} fromAddress 
 * @param {*} token 
 * @param {*} price
 * @param {*} serialNo 
 */
ERC1155Contract.prototype.sellTokenSerial = function (fromAddress, token, price, serialNo) {
    price = this._web3.utils.toWei(price + "", "ether");
    return this._instance.methods.sellTokenSerial(fromAddress, token, price, serialNo).send();
}

/**
 * 取消单独销售 NFT 版本
 * @param {*} token 
 * @param {*} serialNo 
 * @returns 
 */
ERC1155Contract.prototype.cancelTokenSerialSale = function (token, serialNo) {
    return this._instance.methods.cancelTokenSerialSale(token, serialNo).send();
}

/**
 * 转移自己的token serial 到其他用户上
 * @param {*} toAddress 
 * @param {*} token 
 * @param {*} serialNo 
 * @returns 
 */
ERC1155Contract.prototype.freeTransferTokenSerial = function (toAddress, token, serialNo) {
    return this._instance.methods.ownerTransfer(toAddress, token, serialNo).send();
}

/**
 * 设置平台合约受益人地址
 * @param {*} receiveAddress 
 * @returns 
 */
ERC1155Contract.prototype.setUhReceiver = function (receiveAddress) {
    return this._instance.methods.setUhReceiver(receiveAddress).send();
};

/**
 * 设置平台 费率
 * @param {*} fate 整数  千分制 比如 1 ，则是 0.1% 
 * @returns 
 */
ERC1155Contract.prototype.setUhFate = function (fate) {
    return this._instance.methods.setUhFate(fate).send();
}

/**
 * 合约类
 */
function Contract(instance, web3) {
    this._instance = instance;
    this._web3 = web3;
    if (this._instance == null) {
        throw 'NFT contract is not found';
    }
    this.address = this._instance._address;
}

// 铸造一个TOKEN
// 用户拍卖之前先铸造TOKEN
// tokenId 使用 bid
Contract.prototype.mint = function (accountAddress, tokenId) {
    return this._instance.methods.safeMint(accountAddress, tokenId).send();
};

/**
 * TOKEN拍卖 
 * @param {*} tokenId 
 * @param {*} second 
 */
Contract.prototype.startAuction = function (tokenId) {

    return this._instance.methods.startAuction(tokenId).send();
}

/**
 * 用户竞拍TOKEN 
 * @param {*} tokenId 
 * @param {*} price 单位:ether
 */
Contract.prototype.bid = function (tokenId, price) {
    // ethAmount 必须是数字
    if (isNaN(price * 1)) {
        throw 'price is not valid';
    }

    // ether -> wei
    var priceWei = this._web3.utils.toWei(price, "ether");
    return this._instance.methods.bid(tokenId).send({
        value: priceWei,
        handleRevert: true,
    });
}

/**
 * 当前出价最高的用户
 * @param {*} tokenId 
 */
Contract.prototype.highestBid = function (tokenId) {
    return this._instance.methods.highestBidder(tokenId).call();
}

/**
 * TOKEN所属人地址
 * @param {*} tokenId 
 */
Contract.prototype.tokenOwner = function (tokenId) {
    return this._instance.methods.ownerOf(tokenId).call();
}

/**
 * 取回竞拍用户的coin
 * @param {*} tokenId 
 */
Contract.prototype.withdraw = function (tokenId) {
    return this._instance.methods.withdraw(tokenId).send();
}

/*
 * 结束拍卖 并且把TOKEN转移到出价最高的账户上
 * @param {*} tokenId 
 * @returns 
 */
Contract.prototype.endAuction = function (tokenId) {
    return this._instance.methods.endAuction(tokenId).send();
}

/**
 * 设置费率受益人地址 - 只能是合约管理员调用
 * @param {*} address 收益地址
 */
Contract.prototype.setFeeReceiver = function (address) {
    return this._instance.methods.setFeeReceiver(address).send();
}

/**
 * 设置费率 万分制  - 只能是合约管理员调用
 * @param {*} fee 
 */
Contract.prototype.setFeeRate = function (fee) {
    if (!Number.isInteger(fee)) {
        throw "only integer allowed";
    }
    return this._instance.methods.setFeeRate(fee).send();
}

/**
 * 查看当前的费率受益人和费率
 * @returns 
 */
Contract.prototype.viewFeeReceiverAndFate = function () {
    return this._instance.methods.viewFeeReceiverAndFate().call();
}

export default new DAPPContract();