import { Layout, Row, Col, Divider, Pagination, Input, Spin, Form, message } from 'antd';
import MyHeader from "components/header"
import ActiveBanner from "components/active-banner"
import React, { useEffect, useState } from "react";
import MyFooter from "components/footer"
import "./help.scss"
import uh from "assets/uh-origin.jpg"
import metamask from "assets/metamask.png"
import metamask2 from "assets/metamask2.png"
import auction from "assets/auction.png"
import dodge from "assets/dodge.png"
import adminImg from "assets/admin.png"
import cItem from "assets/create-item.png"


const { Header, Footer, Content } = Layout
function Help(props) {
    const [userInfo, setUserInfo] = useState(props.userInfo)

    //函数定义
    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'instant' }); }
        }
    }

    useEffect(async () => {
        scrollToAnchor("activity1")
    }, [])



    return (
        <>
            <div id='activity1'>
                <ActiveBanner></ActiveBanner>
                <div className="my-layout my-help">
                    <Layout>
                        <Header className="shadow-lg">
                            <MyHeader userInfo={userInfo}></MyHeader>
                        </Header>
                        <Content className="my-content my-creator-content">
                            <Row>
                                <Col span={2} className="my-col-left"></Col>
                                <Col span={20} className="my-col-content">
                                    <div className="content">
                                        <h1 className="text-3xl font-bold">How to Buy, Sell, and Mint NFTs for Free on Unique Hash, the World’s Newest NFT Marketplace</h1>
                                        <div>
                                            <span>Lincoln Murr </span>
                                            <span className="ml-8">2021-08-05 00:22:27</span>
                                        </div>
                                        <div className="px-6 py-8 mt-4" style={{ background: "#f0f2f5" }}>
                                            Summary: Unique Hash (https://uniquehash.com), a revolutionary marketplace where users can discover, trade, and create non-fungible tokens on the Ethereum blockchain, was recently released. Let’s take a look at how anyone can use Unique Hash to either create their own NFT or buy an NFT artwork on the platform with 0 fees for a limited time. Unique Hash is bringing ...
                                        </div>
                                        <div className="mt-10">
                                            <p>Unique Hash (https://uniquehash.com), a revolutionary marketplace where users can discover, trade, and create non-fungible tokens on the Ethereum blockchain, was recently released. Let’s take a look at how anyone can use Unique Hash to either create their own NFT or buy an NFT artwork on the platform with 0 fees for a limited time.</p>
                                            <p>Unique Hash is bringing the billion-dollar art world to the blockchain sector. Current NFT marketplaces are niche platforms within the cryptocurrency community, while Unique Hash is focusing on bringing artists and users outside of the blockchain space to their platform, while also charging no fees. Here is a tutorial about how to get started with Unique Hash and join the NFT revolution.</p>
                                        </div>
                                        <div>
                                            <img src={uh} />
                                        </div>
                                        <div>
                                            <br />
                                            <h3 className="font-bold">Step 1: Install the MetaMask Extension</h3>
                                            <p>MetaMask is an extension available on all computers that allows web browsers to interact with the Ethereum blockchain. When you install MetaMask, you will be asked to go through a setup process, where you will be creating a new Ethereum wallet. A link to MetaMask can be found here. While going through the process, you will be asked to create a password and write down a 24-word seed phrase. This seed phrase is crucial, as it is the main password for your Etheruem wallet. If you lose the seed phrase, or a hacker steals it, they will be able to steal all of your funds. The most secure way to store a seed phrase is by writing it down on a piece of paper, and not storing it on your computer. </p>
                                            <p>Once you finish the setup process, you will be greeted with a screen that looks like this:</p>
                                        </div>
                                        <div className="flex justify-center">
                                            <img src={metamask} />
                                        </div>
                                        <div>
                                            <br />
                                            <h3 className="font-bold">Step 2: Fund your MetaMask account with Ethereum</h3>
                                            <div>
                                                <p>Great work setting up your MetaMask account, that is the hardest step. Now, using your favorite cryptocurrency exchange, buy Ethereum (ETH), which will be used to buy, sell, and mint NFTs on Unique Hash. Some of the most popular exchanges include Coinbase, Binance, and Kraken, and these can be found on the App Store as well as by Googling their names.</p>
                                                <p>Once you buy your desired amount of ETH, we are going to withdraw it to your MetaMask account. On the balance page of your exchange, there should be a button that says “withdraw.” Choose to withdraw to an Ethereum address. Now, go back to your MetaMask, and look at the diagram below. </p>
                                            </div>
                                        </div>
                                        <div className="flex justify-center">
                                            <img src={metamask2} />
                                        </div>
                                        <div>
                                            <br />
                                            <p>Click on the circled area, which should copy your Ethereum address to your clipboard. Now, paste this address into the exchange’s withdrawal page, and double check that the address you are withdrawing to is the same as the address on your MetaMask screen. Once you confirm this, confirm your withdrawal and wait about 5-10 minutes for the Ethereum to show up in your MetaMask screen.</p>
                                        </div>
                                        <div>
                                            <h3 className="font-bold">Step 3: Log into Unique Hash Using MetaMask</h3>
                                            <p>Now, you are ready to use the Unique Hash platform. Go to uniquehash.com, which should take you to the homepage where you can see NFTs for sale. In the top right, there is a black button that says “connect wallet.” Click on this, and your MetaMask should open up, and confirm that you want to connect to Unique Hash. </p>
                                        </div>

                                        <div>
                                            <h3 className="font-bold">Step 4: Bid on your Favorite NFTs</h3>
                                            <p>Now, you can buy any NFT you like on the Unique Hash platform. All NFTs are put up for auction, so you must bid on the NFTs you like. To see a full list of NFTs up for auction, there are two places you can check. To look at NFTs that already have a bid and whose auctions will end in less than 24 hours, look at the Live Auctions tab on the home page. For artworks that have not received a bid yet, look at the Featured Artworks tab.  </p>
                                        </div>
                                        <div className="flex justify-center">
                                            <img src={auction} />
                                        </div>
                                        <div>
                                            <br />
                                            <p>Once you find an NFT worth bidding on, such as the Dodge Sports Genesis NFT, click on the artwork, and place your bid. Once you confirm the bid in MetaMask, you are done, and can come back at any time to ensure that you are still the highest bidder.</p>
                                        </div>
                                        <div className="flex justify-center">
                                            <img src={dodge} />
                                        </div>
                                        <div>
                                            <br />
                                            <h3 className="font-bold">Step 5: Create and Mint NFTs</h3>
                                            <p>Now that you are an expert in buying NFTs, let’s learn how to mint your own! On the home page, click the “Create Items” button. This will take you to a page that looks like this: </p>
                                        </div>
                                        <div className="flex justify-center">
                                            <img src={adminImg} />
                                        </div>
                                        <div>
                                            <br />
                                            <p>From here, click on “My Items” on the left side of the screen. Then, in the top right, below your address, click the “Create Items” button. </p>
                                            <p>Now, you can upload any image or artwork you like, and give it a title, description, and link if appropriate. </p>
                                        </div>
                                        <div className="flex justify-center">
                                            <img src={cItem} />
                                        </div>
                                        <div>
                                            <br />
                                            <p>This item will now appear under the “My Items'' page. In order to permanently mint this to the Etheruem blockchain and put it up for auction on Unique Hash, scroll over on the item until you reach the “Start the auction” button. Click on this, and enter the starting price from the item. Once you click submit, your MetaMask will open up and ask you to confirm your listing. This costs around $10, due to the current high fees on Ethereum. Unique Hash is looking into ways to solve this issue, but for now the fee is required to mint the NFT. </p>
                                            <p>After confirming the listing, your NFT will be listed on Unique Hash, and millions of buyers around the world will be able to bid on your NFT! Congratulations! </p>
                                            <p>Unique Hash offers a simple and convenient way for anyone to buy, sell, and create NFTs. Our mission is to bring as many users as possible to the NFT space so they can see the convenience and simplicity that this new form of art offers, which is why anyone can mint and buy NFTs without fees for a limited time. Thank you for joining us on this journey, and we hope to see you buying and minting on our platform soon.</p>
                                            <br />
                                            <p>By Lincoln Murr</p>
                                        </div>

                                    </div>
                                    {/* <iframe width="100%" style={{height:'100vh'}}  src="https://en.bitpush.news/articles/3541915"></iframe> */}

                                </Col>
                                <Col span={2} className="my-col-right"></Col>
                            </Row>
                        </Content>
                        <Footer>
                            <MyFooter></MyFooter>
                        </Footer>
                    </Layout>
                </div>
            </div>
        </>
    )
}

export default Help