
import { Table, Tag, Button, Modal, Spin, message, Form, Input, InputNumber } from "antd"
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { getAction, postAction } from "utils/http"
import bus from "utils/bus"
import "./my-items.scss"
import MusicLogo from "assets/Music.png"
import { PlayCircleOutlined, PauseOutlined } from "@ant-design/icons"



function MyBiding(props) {

    const [dataSource, setDataSource] = useState({ datas: [], total: 0 })
    const [currentPage, setCurrentPage] = useState(1)
    const [userinfo,setUserinfo] = useState(null)
    const [isModalVisible, setModalVisible] = useState(false)
    const [isModalVisibleFixedPrice, setModalVisibleFixedPrice] = useState(false)

    const [loading, setLoading] = useState(false)
    const [record, setRecord] = useState({})
    const [recordFixedPrice, setRecordFixedPrice] = useState({})

    const [pageSize, setPageSize] = useState(8)
    const [playIndex, setPlayIndex] = useState(null)
    const [audioPlay, setAutioPlay] = useState(false)
    const [audioPlayD, setAutioPlayD] = useState(false)

    const toCreateItem = () => {
        console.log(props)
        let userInfo = bus.getLocalStore("userInfo")
        if (!userInfo.status) {
            message.warning(
                "You cannot create items. Please contact the administrator"
            );
            return;
        }

        props.setAdminKey("Create item")

    }

    const playAudio = (src, index, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src = src
        audioRef.play()
        setAutioPlay(true)

        setPlayIndex(index)
    }

    const handleSell = (record) => {
        // setModalVisibleFixedPrice(true)
        // setRecordFixedPrice(record)

        if (!record.image) {
            message.warning("You need to upload item.")
            return
        }
        
        props.setInfo(record)
        props.setAdminKey("Sell item")
    }

    const pauseAudio = (index, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlay(false)

        setPlayIndex(null)
    }

    const playAudioD = (src, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src = src
        audioRef.play()
        setAutioPlayD(true)

    }

    const pauseAudioD = (e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlayD(false)

    }

    const onFinish = () => {


        let data = form.getFieldsValue()
        console.log(333, data, record)

        

        if (!record.image) {
            message.warning("You need to upload item.")
            return
        }

        handleOk(data.price * 1, record.gid)
    }

    const onFinishFixedPrice = async () => {
        let data = form.getFieldsValue()
        data.tokenId = recordFixedPrice.gid
        data.accountAddress = recordFixedPrice.user.address_pubkey

        setLoading(true);

        setModalVisibleFixedPrice(false)
        try {
            let res = await bus.startFixPrice(data)
            if (res) {
                loadData(1)
            }
        } catch {
            setLoading(false);
        }
        setLoading(false);
        form.setFieldsValue({})
    }

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    const loadData = async (page) => {
        !page && (page = currentPage)
        let headers = await bus.getAuthorization();
        let res = await getAction(
            `good/user/items?offset=${(page - 1) * pageSize
            }&limit=${pageSize}`,
            {},
            headers
        );

        if (res.data.code == 0) {
            setDataSource(res.data.data)
        }
    }

    const handleEdit = (record) => {
        props.EditItem(record)
    }

    const handleAuctionEnd = async (record) => {
        setLoading(true);
        // 完成拍卖
        try {
            let res = await bus.handleAuctionEnd(record)
            if (res.code == 0) {
                message.success("The auction has been completed");
                loadData(1);
            } else {
                message.warning(res.message);
            }
        } catch {
            setLoading(false);
        }

        setLoading(false);


    }

    const handleAuctionStart = (record) => {
        setModalVisible(true)
        setRecord(record)
    }

    const handleOk = async (price, tokenId) => {
        setLoading(true);
        setModalVisible(false)
        try {
            let res = await bus.startAuction(price, tokenId)
            console.log(666666666, res)
            if (res.code == 0) {
                message.success("Auction has been successfully initiated");
                loadData(1)
            } else {
                message.warning(res.message);
            }
        } catch (e) {
            setLoading(false);
        }
        form.setFieldsValue({})
        setLoading(false);
    }

    const toBeCreator = ()=>{
        props.setAdminKey("Be creator")
    }

    useEffect(() => {
        loadData()

        let userInfo = bus.getLocalStore("userInfo")

        setUserinfo(userInfo)
    }, [])



    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: 'Item name',
            dataIndex: 'title',
            key: `title`,
        },
        {
            title: 'Item',
            dataIndex: 'image',
            key: 'image',
            render: (text, item, index) => {
                if (bus.getFileType(item.image) == 'img') {

                    return <div className="w-24 h-24 border flex items-center my-good_image2" style={{ background: `url(${item.image}) no-repeat` }}></div>
                }
                if (bus.getFileType(item.image) == 'audio') {
                    return <div className="w-36 h-36 border flex items-center my-good_image2 justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                        {
                            audioPlay && playIndex === index && <PauseOutlined onClick={(e) => pauseAudio(index, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                        }
                        {
                            (playIndex !== index || playIndex === null) && <PlayCircleOutlined onClick={(e) => playAudio(item.image, index, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                        }
                    </div>
                }

                if (bus.getFileType(item.image) == 'video') {
                    return <div className="w-36 h-36 border flex items-center my-good_image2"><video controls muted autoplay="autoplay" src={item.image} /> </div>
                }
            }
        },
        // {
        //     title: 'Versions',
        //     dataIndex: 'versions',
        //     key: `versions`,
        //     render: (text, item,index) => {
        //         return <div>
        //             {
        //                 item.versions && item.versions.map((v,i)=>{
        //                     return <div>
        //                         {i+1} / {item.versions.length}
        //                     </div>
        //                 }) 
        //             }
        //         </div>
        //     }
        // },
        {
            title: 'Collection',
            key: 'collection',
            render: (text, record, index)  => {
                return record.collection && <div>{record.collection.title}</div>
            }
        },
        {
            title: 'Creation time',
            dataIndex: 'created',
            key: 'created',
            render: created => {
                return bus.formatDate(created)
            }
        },
        // {
        //     title: 'Network',
        //     dataIndex: 'network',
        //     key: 'network',
        //     render: (text, item, index) => bus.getNameOfNetwork(item.good.network) 
        // },
        {
            title: 'Item link',
            dataIndex: 'external_link',
            key: 'link',
        },
        {
            title: 'Item condition',
            dataIndex: 'auction',
            key: 'condition',
            render: auction => {
                {
                    if (!auction) {
                        return <Tag color="magenta">No auction</Tag>
                    } else {
                        switch (auction.status) {
                            case 0:
                                return <Tag color="cyan">Reserve not met</Tag>
                            case 1:
                                return <Tag color="geekblue">Sold</Tag>
                            case 2:
                                return <Tag color="volcano">Failed</Tag>
                            case 3:
                                if (auction.second_duration > 0) {
                                    return <Tag color="green">Live Auction</Tag>
                                } else {
                                    return <Tag color="geekblue">Sold</Tag>
                                }
                        }
                    }
                }
            }
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'description',
            render: (text, record, index) => {
                return <div className='w-40'> {text} </div>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                // console.log("###", record)
                return <div>
                    {
                        // 已经开启竞拍以后不可修改信息
                        // (!record.auction) && <div className="bg-blue-400 rounded-sm px-2 inline-block text-white cursor-pointer font-bold mr-2" onClick={() => { handleEdit(record) }}>Edit </div>
                        (!record.auction && !record.versions) && <div className="bg-blue-400 rounded-sm px-2 inline-block text-white cursor-pointer font-bold mr-2" onClick={() => { handleEdit(record) }}>Edit </div>
                    }

                    {
                        (!record.auction && !record.versions) && <div className=" mr-2 bg-blue-400 rounded-sm px-1 inline-block text-white cursor-pointer font-bold" onClick={() => { handleAuctionStart(record) }}>Highest Bid </div>
                    }
                    {
                       (!record.auction && !record.versions) &&  <div className=" mr-2 bg-blue-400 rounded-sm px-1 inline-block text-white cursor-pointer font-bold" onClick={() => { handleSell(record) }}>Set Price </div>
                    }
                </div>
            }
        },
    ];


    return (
        <>
            <audio id="audioRef" />
            <div>
                <div className="flex justify-between py-2" >
                    <span className="font-bold">My Items</span>
                    {
                        userinfo && userinfo.status == 1 && <div className="bg-black rounded-full px-3 py-1 text-white font-bold  cursor-pointer" onClick={() => toCreateItem()}>Create Item</div>
                    }

                    {
                        userinfo && userinfo.status != 1 && <div className="bg-black rounded-full px-3 py-1 text-white font-bold  cursor-pointer"  onClick={() => toBeCreator()}>Apply to be a Creator</div>
                    }

{/* <div className="bg-black rounded-full px-3 py-1 text-white font-bold  cursor-pointer"  onClick={() => toBeCreator()}>Apply to be a Creator</div> */}
                    
                </div>
                <Table dataSource={dataSource.datas} columns={columns} pagination={{
                    // 设置总条数
                    total: dataSource.total,
                    // 显示总条数
                    showTotal: total => `total ${dataSource.total}`,
                    // 改变页码时
                    onChange: ((page, pageSize) => {
                        console.log(666, page)
                        setCurrentPage(page)
                        loadData(page)
                    }),
                    // pageSize 变化的回调
                    onShowSizeChange: (page) => {
                        setPageSize(pageSize)
                        loadData()
                    }
                }}
                    scroll={{ x: 1300 }}
                />
            </div>

            <Modal title="Starting Price" visible={isModalVisible} footer={null} onCancel={() => setModalVisible(false)}>
                <p>
                    {
                        bus.getFileType(record.image) == "img" && <img alt={record.title} src={record.image} className="h-64" />
                    }
                    {
                        bus.getFileType(record.image) == "audio" && <div className="w-64 h-64 border flex items-center my-good_image2 justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                            {
                                audioPlayD && <PauseOutlined onClick={(e) => pauseAudioD(e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                            }
                            {
                                !audioPlayD && <PlayCircleOutlined onClick={(e) => playAudioD(record.image, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                            }
                        </div>
                    }
                    {
                        bus.getFileType(record.image) == "video" && <video controls muted autoplay="autoplay" src={record.image} />
                    }

                </p>
                <p>
                    <h1 className="text-3xl">{record.title}</h1>
                </p>

                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Starting Price"
                        name="price"
                        form={form}
                        rules={[{ required: true, message: 'Please input your price!' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <Input type="number" />
                            <span className="font-bold flex-shrink-0 h-6">&emsp; {bus.getNetUnit(record.contract_address)}</span>
                        </div>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <div className="flex ">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                            <div>
                                <Button type="" onClick={() => setModalVisible(false)}>
                                    Quit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>

                </Form>
            </Modal>

            {
                // Fixed Price
            }
            <Modal title="Fixed Price" visible={isModalVisibleFixedPrice} footer={null} onCancel={() => setModalVisibleFixedPrice(false)}>
                <p>
                    {
                        bus.getFileType(recordFixedPrice.image) == "img" && <img alt={recordFixedPrice.title} src={recordFixedPrice.image} className="h-64" />
                    }
                    {
                        bus.getFileType(recordFixedPrice.image) == "audio" && <div className="w-64 h-64 border flex items-center my-good_image2 justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                            {
                                audioPlayD && <PauseOutlined onClick={(e) => pauseAudioD(e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                            }
                            {
                                !audioPlayD && <PlayCircleOutlined onClick={(e) => playAudioD(recordFixedPrice.image, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                            }
                        </div>
                    }
                    {
                        bus.getFileType(recordFixedPrice.image) == "video" && <video controls muted autoplay="autoplay" src={recordFixedPrice.image} />
                    }

                </p>
                <p>
                    <h1 className="text-3xl">{recordFixedPrice.title}</h1>
                </p>

                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinishFixedPrice}
                >
                    <Form.Item
                        label="Fixed Price"
                        name="price"
                        form={form}
                        rules={[{ required: true, message: 'Please input your price!' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <Input type="number" />
                            <span className="font-bold flex-shrink-0 h-6">&emsp; {bus.getNetUnit(record.contract_address)}</span>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Versions"
                        name="total"
                        form={form}
                        rules={[{ required: true, message: 'Please input your versions!' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <InputNumber />
                            <span className="font-bold flex-shrink-0 h-6">&emsp;</span>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Rate"
                        name="dividend"
                        form={form}
                        rules={[{ required: true, message: 'Please input your rate!' }]}
                    >
                        <div className="flex items-center">
                            &emsp;
                            <InputNumber max={1000} />
                            <span className="font-bold flex-shrink-0 h-6">&emsp;</span>
                        </div>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <div className="flex ">
                            <div className="mr-4">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                            <div>
                                <Button type="" onClick={() => setModalVisibleFixedPrice(false)}>
                                    Quit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>

                </Form>
            </Modal>

            <div className={`fixed top-0 left-0  h-screen w-screen  bg-black z-50 flex justify-center items-center bg-opacity-70 ${loading ? 'show' : 'hidden'}`} >
                <Spin tip="Contract code is executing, do not close the browser" className="mySpin" />
            </div>
        </>
    )
}

export default MyBiding