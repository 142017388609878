import temp_src_exhibits from 'assets/temp/exhibits.png'
import React, { useState, useEffect } from "react";
import { message } from "antd"
import { PlayCircleOutlined, PauseOutlined } from "@ant-design/icons"
import MusicLogo from "assets/Music.png"
import { Link, Redirect, useLocation } from 'react-router-dom'
import bus from 'utils/bus';
function FixedItem(props) {
    const detail = props.info || null

    const [eth, setEth] = useState(0)
    const [audioPlay, setAutioPlay] = useState(false)

    const playAudio = (src, e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.src = src
        audioRef.play()
        setAutioPlay(true)
    }

    // const analysisIPFS = () => {

    // }

    const pauseAudio = (e) => {
        e.stopPropagation();
        e.preventDefault()
        let audioRef = document.getElementById("audioRef")
        audioRef.pause()
        setAutioPlay(false)
    }

    const useSerialNo = () => {
        let temp = detail.versions.filter(v => v.status == 1)
    }

    useEffect(async () => {
        let eth_temp = await bus.eth(detail.good.contract_address)
        setEth(eth_temp)

        // bus.fromWei("1000000000000000")
        // try {
        //     let res = await bus.analysisIPFS(detail.good.ipfs_link)
        //     detail.good.ipfsLink = res.properties.image || res.properties.audio || res.properties.video
        // } catch (e) {
        // }
    }, [])
    return (
        <>
            <audio id="audioRef" />
            {
                detail && <Link to={`/fixed/${detail.good.token}`} >
                    <div className="inline-block  shadow-xl border mb-4 rounded-lg text-black">
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'img' && <div className="w-64 h-64 border flex items-center my-good_image" style={{ background: `url(${detail.good && detail.good.image}) no-repeat` }}></div>
                        }
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'audio' && <div className="w-64 h-64 border flex items-center my-good_image justify-center" style={{ background: `url(${MusicLogo}) no-repeat` }}>
                                {
                                    audioPlay && <PauseOutlined onClick={(e) => pauseAudio(e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                }
                                {
                                    !audioPlay && <PlayCircleOutlined onClick={(e) => playAudio(detail.good.image, e)} style={{ fontSize: '68px', opacity: 0.4 }} />
                                }
                            </div>
                        }
                        {
                            detail.good && bus.getFileType(detail.good.image) == 'video' && <div className="w-64 h-64 border flex justify-center items-center my-good_image"><video controls loop muted autoplay="autoplay" src={detail.good.image} /> </div>
                        }
                        <div className="flex justify-between py-3 px-4">
                            <div className="flex flex-col ">
                                <span className="font-bold text-sm ellipse-hidden ellipse-hidden-min">{detail.good.title}</span>
                                {
                                    detail.user && <span className="text-xs font-bold text-gray-500">@{detail.user.uri_name || bus.formatUid(detail.user.address_key || detail.user.address || detail.user.address_pubkey)}</span>
                                }
                                {
                                    detail.creator && <span className="text-xs font-bold text-gray-500">@{detail.creator.uri_name || bus.formatUid(detail.creator.address_key || detail.creator.address || detail.creator.address_pubkey)}</span>
                                }
                            </div>

                            <div className="flex flex-col text-center">
                                <span className="font-bold text-sm">Edition</span>
                                <span>
                                    { detail.versions.length && detail.versions.filter(v => v.status == 1).length} / {detail.versions.length}
                                </span>
                            </div>
                        </div>
                        <div className="inline-block bg-black rounded-bl-xl rounded-br-xl h-24 w-full  text-sm text-white flex items-center pl-4 my-auction-info ">
                            <div className=" inline-block w-24 h-20 flex-col flex justify-center">
                                <span className="text-gray-200 ">Current Price</span>
                                {
                                    detail.versions && detail.versions.length && <>
                                        {/* ****<span>{bus.fromWei(Math.min(...detail.versions.map(v => v && v.price * 1)) + "")}</span> */}
                                        <span className="font-bold">{bus.fromWei(Math.min(...detail.versions.map(v => v && v.price * 1)))} {bus.getNetUnit(detail.good.contract_address)}</span>
                                        <span className="text-gray-400 "> ${(eth * bus.fromWei(Math.min(...detail.versions.map(v => v && v.price * 1)))).toFixed(2)}</span>
                                    </>
                                }

                            </div>

                        </div>
                    </div>
                </Link>
            }

        </>
    )
}

export default FixedItem