import { Form, Input, Button, Checkbox, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { getAction, postAction } from "utils/http"
import bus from "utils/bus"
function UserInfo(props) {
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };


    const [imageAvator, setImageAvator] = useState("")
    const [imageAvatorUp, setImageAvatorUp] = useState("")

    const [imagePoster, setImagePoster] = useState("")
    const [imagePosterUp, setImagePosterUp] = useState("")

    const [userInfo, setUserInfo] = useState(null)


    const [headers, setHeaders] = useState({})
    const [loading, setLoading] = useState(false)
    const tailLayout = {
        wrapperCol: { offset: 0, span: 8 },
    };

    const toBack = () => {
        props.setAdminKey("Users")
    }

    const onFinish = async (values) => {
        let data = form.getFieldsValue()

        // /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/

        let emailReg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/

        if( !emailReg.test(data.email)  ){
            message.warning("Email format error")
            return 
        }


        let formData = {
            ...data,
            avatar: imageAvatorUp,
            teaser_poster: imagePosterUp
        }

        let res = await bus.updateUserInfo(formData)
        if (res.code == 0) {
            message.success(res.message);
            bus.setUserInfoFn(res.data)
            props.handleUserInfo(res.data)
        } else {
            message.warning(res.message);
        }
    };

    const handleAvatorChange = info => {
        if (info.file.response && info.file.response.code == 0) {
            setImageAvator(info.file.response.data.url)
            setImageAvatorUp(info.file.response.data.uri)
        }
    }

    const handlePosterChange = info => {
        if (info.file.response && info.file.response.code == 0) {
            setImagePoster(info.file.response.data.url)
            setImagePosterUp(info.file.response.data.uri)
        }
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const [form] = Form.useForm();


    useEffect(async () => {
        let userInfo = bus.getLocalStore("userInfo");
        let headers = await bus.getAuthorization();

        if (userInfo && headers) {
            setHeaders(headers)
            setUserInfo(userInfo)
            setImageAvator(userInfo.avatar)
            setImagePoster(userInfo.teaser_poster)
        }
    }, [])

    return (
        <>
            {
                userInfo && <div>
                    <div className="flex justify-between pb-6">
                        <span className="font-bold">User information</span>
                        {/* <div className="font-bold bg-black text-white rounded-full px-3 py-1 cursor-pointer" onClick={() => toBack()}>Back</div> */}
                    </div>
                    <div className="border py-6 px-6">
                        <Form
                            form={form}
                            {...layout}
                            layout="vertical"
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="Avatar"
                                name="avatar"
                            >
                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="api/upload"
                                    headers={headers}
                                    onChange={handleAvatorChange}
                                >
                                    {imageAvator ? <img src={imageAvator} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>

                            <Form.Item
                                label="Profile Page Background"
                                name="teaser_poster"
                            >
                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="api/upload"
                                    headers={headers}
                                    onChange={handlePosterChange}
                                >
                                    {imagePoster ? <img src={imagePoster} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>

                            <Form.Item
                                label="Nickname"
                                name="nicename"
                            >
                                <Input defaultValue={userInfo.nicename} />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                            >
                                <Input defaultValue={userInfo.email} />
                            </Form.Item>

                            <Form.Item
                                label="Twitter"
                                name="twitter"
                            >
                                <Input defaultValue={userInfo.twitter} />
                            </Form.Item>


                            <Form.Item
                                label="Instagram"
                                name="instagram"
                            >
                                <Input defaultValue={userInfo.instagram} />
                            </Form.Item>


                            <Form.Item
                                label="self-description"
                                name="bio"
                            >
                                <Input.TextArea defaultValue={userInfo.bio} />
                            </Form.Item>

                            <Form.Item
                                label="uri-name"
                                name="uri_name"
                            >
                                <div className="flex items-center">
                                    <span className="mr-1">@ </span>
                                    <Input defaultValue={userInfo.uri_name} />
                                </div>
                            </Form.Item>


                            <Form.Item {...tailLayout}>
                                <div className="mr-8 inline-block">
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </div>
                                {/* <div className=" inline-block">
                                    <Button type="" onClick={() => { toBack() }}>
                                        Quit
                                    </Button>
                                </div> */}
                            </Form.Item>
                        </Form>
                    </div>
                </div >
            }
        </>
    )
}

export default UserInfo