import MyRoute from "./Router"
import React, { useEffect, useState } from "react";
import bus from "utils/bus";
function App() {
  const [userInfo, setUserInfo] = useState(null)
  useEffect(async () => {
    let res = await bus.connectWallet()
    if (res) {

    }
    let userInfo = bus.getLocalStore("userInfo")
    setUserInfo(userInfo)
    console.log("App.js", userInfo)
  }, [])
  return (
    <>
      {
        userInfo && <MyRoute setUserInfo={setUserInfo} userInfo={userInfo}></MyRoute>
      }

      {
        !userInfo && <MyRoute userInfo={userInfo}></MyRoute>
      }
    </>
  )
}

export default App;
