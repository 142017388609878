
import logo from 'assets/logo.png'
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom'
import "./admin-header.scss"
import { Input, Drawer, Row, Col, Menu } from 'antd';
import React, { useState, useEffect } from 'react';
import { SearchOutlined, MenuUnfoldOutlined, WalletOutlined } from '@ant-design/icons';
import bus from "utils/bus"
import temp_src_exhibits from 'assets/temp/exhibits.png'
import * as utils from "utils/utils"
function MyHeader(props) {
    const [visible, setVisible] = useState(false);
    const userInfo = props.userInfo;
    const [pathName, setPathName] = useState('home');
    const location = useLocation();
    let history = useHistory()
    useEffect(() => {

        // let userInfo = bus.getLocalStore("userInfo")
        // if (userInfo) {
        //     setUserInfo(userInfo)
        // }

        let pathname = location.pathname.slice(1)
        setPathName(pathname)
    }, [])

    const handleUserInfo = () => {
        console.log(88888)
        props.setAdminKey("User info")
    }

    const redirect = (key) => {
        // history.push(path)
        props.setAdminKey(key)

        setVisible(false)
    }

    function connectWallet() {
        history.push("/admin")
    }

    return (
        <div className="my-header">
            <Row>
                <Col span={2}></Col>
                <Col span={20}>
                    <div className="flex mt-4 justify-between">
                        <div className=" flex">
                            <Link to="/home">
                                <img src={logo} alt="" width="40" height="40" className="flex-shrink-0" />
                            </Link>
                            <div className="my-menu flex items-center ml-10  mr-12">
                                <ul className="flex h-6  items-center text-sm font-bold">
                                    <Link to="/home" >
                                        <li className={`mr-4 py-2 px-6 rounded-full text-black ${pathName == 'home' ? 'my-active-item' : ''}`}>
                                            Home
                                        </li>
                                    </Link>
                                    <Link to="/artwork">
                                        <li className={`mr-4 py-2 px-6 rounded-full text-black  ${pathName == 'artwork' ? 'my-active-item' : ''}`}>
                                            Artwork
                                        </li>
                                    </Link>
                                    <Link to="/creators">
                                        <li className={`mr-4 py-2 px-6 rounded-full text-black  ${pathName == 'creators' ? 'my-active-item' : ''}`}>
                                            Creators
                                        </li>
                                    </Link>
                                    <Link to="/collections">
                                        <li className={`mr-4 py-2 px-6 rounded-full text-black  ${pathName == 'collections' ? 'my-active-item' : ''}`}>
                                            Collections
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                            {/* <Input className="my-input" placeholder="Search" suffix={<SearchOutlined />} ></Input> */}
                        </div>
                        {
                            userInfo &&
                            <div className="my-connect-btn w-36 text-center rounded-full h-10 leading-9 flex items-center cursor-pointer" onClick={() => handleUserInfo()}>
                                <img alt="" src={userInfo.avatar || temp_src_exhibits} className="w-6 h-6 rounded-full ml-2" />
                                <div className="flex flex-col">
                                    <span className="text-sm ml-2 w-20 truncate">{userInfo.nicename || userInfo.nanoUid}</span>
                                    {
                                        userInfo.nicename && <span className="text-sm ml-2">{userInfo.nanoUid}</span>
                                    }
                                </div>
                            </div>

                        }
                        {
                            !userInfo && <div className="my-connect-btn w-36 text-center rounded-full h-9 leading-9" >
                                <div className="my-connect-big-screen cursor-pointer" onClick={connectWallet}>
                                    Wallet Connect
                                </div>
                                <div className="my-connect-mini-screen" onClick={connectWallet}>
                                    <span className="mr-2">
                                        Connect
                                    </span>
                                    <WalletOutlined className="my-wallet-icon" />
                                </div>
                            </div>
                        }
                        <MenuUnfoldOutlined className="text-3xl my-icon-menu" onClick={() => {
                            setVisible(!visible)
                        }} />
                        <Drawer
                            title="Menu"
                            placement="top"
                            closable={true}
                            onClose={() => setVisible(false)}
                            visible={visible}
                        >
                            <div>
                                <Menu>
                                    <Menu.Item key="9" onClick={() => { redirect('My biding') }}>
                                        <span className="text-black">My Biding</span>
                                    </Menu.Item>

                                    <Menu.Item key="10" onClick={() => { redirect('My items') }}>
                                        <span className="text-black">My Items</span>
                                    </Menu.Item>

                                    <Menu.Item key="11" onClick={() => { redirect('My collection') }}>
                                        <span className="text-black">My Collection</span>
                                    </Menu.Item>
                                    {
                                         userInfo.role == 'admin' && <Menu.Item key="12" onClick={() => { redirect('Users') }}>
                                            <span className="text-black">Users</span>
                                        </Menu.Item>
                                    }
                                    {
                                        userInfo.role == 'admin' && <Menu.Item key="13" onClick={() => { redirect('Manually') }}>
                                            <span className="text-black">Manually Open An Auction</span>
                                        </Menu.Item>
                                    }
                                    {
                                        userInfo.role == 'admin' && <Menu.Item key="14" onClick={() => { redirect('Rate') }}>
                                            <span className="text-black">Rate</span>
                                        </Menu.Item>
                                  
                                    }
                                </Menu>
                            </div>
                        </Drawer>
                    </div>
                </Col>
                <Col span={2}></Col>
            </Row>
        </div>
    )
}


export default MyHeader